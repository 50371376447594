import React, { ReactElement, useCallback, useMemo } from 'react';
import { AppBar as RaAppBar, AppBarProps, useGetIdentity, usePermissions, UserMenu } from 'react-admin';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { TenantSelector } from 'dd-cms-client/app/Layout/AppBar/TenantSelector';
import { UserMenuItems } from 'dd-cms-client/app/Layout/AppBar/UserMenuItems';
import { GLOBAL_RESOURCES, ResourceName } from 'dd-cms-client/app/resources';
import { getTenant, Tenant, TENANT_BY_DOMAIN } from 'dd-cms-client/auth/utils/tenant';
import { hasModeratorPermissions } from 'dd-cms-client/auth/utils/userRole';
import { getGlobalState, GlobalStateKey } from 'dd-cms-client/common/globalState';
import { useDevice } from 'dd-cms-client/common/hooks/useDevice';
import { useTotalPendingComments } from 'dd-cms-client/common/hooks/useTotalPendingComments';
import { LanguageSelector } from 'dd-cms-client/i18n/components/LanguageSelector';
import { Component } from './';
import './AppBar.scss';

const AppBar: Component = (props: AppBarProps): ReactElement => {
  const { permissions } = usePermissions();
  const { pathname } = useLocation();
  const { identity } = useGetIdentity();
  const { t } = useTranslation();
  const { isMobile } = useDevice();
  const currentTenant = getTenant();

  const totalPendingComments = useTotalPendingComments();

  const { fields } = getGlobalState(GlobalStateKey.OPEN_FORM_VALUES);

  const text = useMemo(
    () => ({
      comments: t('Comments'),
      deal: t('Deal'),
      news: t('News'),
      promotion: t('Promotion'),
    }),
    [t],
  );

  const otherAccessibleTenants: Array<Tenant> = useMemo(
    () => {
      const tenantDomains: Array<string> = identity?.shops || [];

      return (
        tenantDomains.length > 1
          ? tenantDomains
            .map(domain => TENANT_BY_DOMAIN[domain])
            .filter(
              (tenant: Tenant | undefined) => tenant && tenant !== currentTenant,
            )
          : []
      );
    },
    [currentTenant, identity?.shops],
  );

  const renderAppBarTitle = useCallback(
    () => {
      const pathWithoutFirstSlash = pathname.substring(1);
      const resourceNameFromPath = pathWithoutFirstSlash.split(/\//)[1];
      const itemIdFromPath = parseInt(pathWithoutFirstSlash.split(/\//)[2]);

      if ((resourceNameFromPath === ResourceName.NEWS || resourceNameFromPath === ResourceName.PROMOTION) && itemIdFromPath) {
        return (
          <span>
            {resourceNameFromPath === ResourceName.NEWS ? text.news : text.promotion} {fields?.title}
        </span>
        );
      }
      if (resourceNameFromPath === ResourceName.DEAL && itemIdFromPath) {
        return (
          <span>
            {text.deal} #{fields?.navisionId} {fields?.title} {fields?.subtitle}
          </span>
        );
      }

      return <span id="react-admin-title"/>;
    },
    [fields?.navisionId, fields?.subtitle, fields?.title, pathname, text.deal, text.news, text.promotion],
  );

  return (
    <RaAppBar
      {...props}
      userMenu={
        <UserMenu>
          <UserMenuItems />
        </UserMenu>
      }
    >
      {!GLOBAL_RESOURCES.some(resource => pathname.includes(resource))
        && !(isMobile && pathname.includes(ResourceName.COMMENT))
        && otherAccessibleTenants.length > 0
        && (
          <>
            <TenantSelector
              currentTenant={currentTenant}
              otherTenants={otherAccessibleTenants}
            />
            <span className="AppBar-TenantSlash">/</span>
          </>
        )}

      {!(isMobile && pathname.includes(ResourceName.COMMENT))
        && (
          <div className="AppBar-Title">
            {renderAppBarTitle()}
          </div>
        )}

      {hasModeratorPermissions(permissions?.userRole) && (
        <Link
          className="AppBar-CommentsLink"
          to={{
            pathname: `/${currentTenant}/${ResourceName.COMMENT}`,
            search: encodeURI(`filter=${JSON.stringify({ fields: { status: 'pending' } })}`),
          }}
        >
          {text.comments}
          <span className="List-Chip">
            {totalPendingComments !== undefined
              ? totalPendingComments
              : <CircularProgress
                  style={{ height: '12px', width: '12px' }}
                />
            }
          </span>
        </Link>
      )}

      <LanguageSelector className="AppBar-LanguageSelector" />
    </RaAppBar>
  );
};

export {
  AppBar,
};

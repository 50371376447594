import React, { ReactElement, SyntheticEvent, useCallback, useMemo } from 'react';
import {
  Button,
  ChipField,
  Datagrid,
  FunctionField,
  List,
  RaRecord,
  SelectInput,
  TextField,
  TextInput,
  useDataProvider,
  useNotify,
  useRefresh,
} from 'react-admin';
import { useTranslation } from 'react-i18next';
import { Chip } from '@mui/material';
import { ResourceName } from 'dd-cms-client/app/resources';
import { ListActions } from 'dd-cms-client/common/components/ListActions';
import { useList } from 'dd-cms-client/common/hooks/useList';
import { DEFAULT_ITEMS_PER_PAGE } from 'dd-cms-client/common/utils/list';
import { getConfig } from 'dd-cms-client/config/utils/config';

enum UserStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

enum UserRole {
  ADMINISTRATOR = 'administrator',
  EDITOR = 'editor',
  MODERATOR = 'moderator',
}

const UserList = (): ReactElement => {
  const { t } = useTranslation();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const { getStatusClassNames } = useList(ResourceName.USER);

  const text = useMemo(
    () => ({
      actions: t('Actions'),
      activate: t('Activate'),
      avatarThumbnail: t('Avatar Thumbnail'),
      deactivate: t('Deactivate'),
      email: t('Email'),
      id: t('ID'),
      label: t('Label'),
      permissions: t('Permissions'),
      role: t('Role'),
      search: t('Search'),
      status: t('Status'),
      username: t('Username'),
    }),
    [t],
  );

  const statusOptions = [
    {
      id: UserStatus.ACTIVE,
      name: UserStatus.ACTIVE,
    }, {
      id: UserStatus.INACTIVE,
      name: UserStatus.INACTIVE,
    },
  ];

  const roleOptions = [
    {
      id: UserRole.ADMINISTRATOR,
      name: UserRole.ADMINISTRATOR,
    }, {
      id: UserRole.EDITOR,
      name: UserRole.EDITOR,
    }, {
      id: UserRole.MODERATOR,
      name: UserRole.MODERATOR,
    },
  ];

  const filters = [
    <TextInput label={text.search} key="search" source="fields.username" alwaysOn resettable />,
    <SelectInput label={text.role} key="role" source="fields.role" choices={roleOptions} alwaysOn />,
    <SelectInput label={text.status} key="status" source="fields.status" choices={statusOptions} alwaysOn />,
  ];

  const handleChangeStatus = useCallback(
    (record: RaRecord) => (e: SyntheticEvent) => {
      e.stopPropagation();

      const updateRecord = {
        ...record.fields,
        status: (
          record.fields.status === UserStatus.INACTIVE
            ? UserStatus.ACTIVE
            : UserStatus.INACTIVE
        ),
      };

      dataProvider.update(
        ResourceName.USER,
        {
          data: updateRecord,
          id: record.id,
          previousData: undefined,
        },
      ).then(
        () => {
          notify('ra.notification.updated', { messageArgs: { smart_count: 1 }, type: 'info' });
          refresh();
        },
      ).catch(
        () => {
          notify('ra.notification.data_provider_error');
        },
      );
    },
    [dataProvider, notify, refresh],
  );

  return (
    <List
      actions={<ListActions />}
      className="List"
      filters={filters}
      perPage={DEFAULT_ITEMS_PER_PAGE}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <Datagrid
        bulkActionButtons={false}
        rowClick="edit"
      >
        <TextField
          source="id"
          label={text.id}
        />
        <FunctionField
          label={text.avatarThumbnail}
          render={(record: RaRecord) => {
            if (record?.fields?.avatar) {
              return (
                <img
                  alt={text.avatarThumbnail}
                  className="List-Image"
                  src={`${getConfig('url.cdn')}/${record.fields.avatar}`}
                />
              );
            }

            return null;
          }}
        />
        <TextField
          source="fields.username"
          label={text.username}
          sortBy="username"
        />
        <TextField
          source="fields.label"
          label={text.label}
          sortBy="label"
        />
        <TextField
          source="fields.email"
          label={text.email}
          sortBy="email"
        />
        <FunctionField
          label={text.status}
          render={(record: RaRecord) => (
            <span className={getStatusClassNames(record?.fields?.status === UserStatus.ACTIVE)}>
              {record?.fields?.status}
            </span>
          )}
        />
        <ChipField
          source="fields.role"
          label={text.role}
          sortBy="role"
        />
        <FunctionField
          label={text.permissions}
          render={(record: RaRecord) => {
            if (record?.fields?.shops) {
              return (
                record.fields.shops.map(
                  (shop: string) => (
                    <Chip
                      className="Chip--MaterialUi"
                      label={shop}
                      key={shop}
                    />
                  ),
                )
              );
            }

            return null;
          }}
        />
        <FunctionField
          label={text.actions}
          render={(record: RaRecord) => (
            <Button
              label={(
                record?.fields?.status === UserStatus.INACTIVE
                  ? text.activate
                  : text.deactivate
              )}
              onClick={handleChangeStatus(record)}
              size="small"
            />
          )}
        />
      </Datagrid>
    </List>
  );
};

export {
  UserList,
  UserRole,
  UserStatus,
};

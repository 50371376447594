import React, { ReactElement } from 'react';
import {
  Dialog as MaterialUiDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import classNames from 'classnames';
import { Component, Props } from './types';
import './Dialog.scss';

const Dialog: Component = ({
  actions,
  className,
  content,
  isOpen,
  onClose,
  title,
}: Props): ReactElement => {
  const rootClassName = classNames(
    'Dialog',
    className,
  );

  return (
    <MaterialUiDialog
      open={isOpen}
      onClose={onClose}
      className={rootClassName}
      disableEnforceFocus={true}
    >
      {title && (
        <DialogTitle>
          {title}
        </DialogTitle>
      )}

      {content && (
        <DialogContent>
          {content}
        </DialogContent>
      )}

      {actions && (
        <DialogActions>
          {actions}
        </DialogActions>
      )}
    </MaterialUiDialog>
  );
};

export {
  Dialog,
};

import React, { ReactElement, useCallback, useMemo } from 'react';
import { EditContextProvider, SaveButton, SimpleForm, useEditController, useNotify, useRefresh, useUpdate } from 'react-admin';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { ResourceName } from 'dd-cms-client/app/resources';
import { DataLoader } from 'dd-cms-client/common/components/DataLoader';
import { SchemaFields } from 'dd-cms-client/common/components/SchemaFields';
import { useForm } from 'dd-cms-client/common/hooks/useForm';
import { showErrorNotification } from 'dd-cms-client/common/utils/error';
import { getRecordFieldsReferToSchema, SchemaType } from 'dd-cms-client/common/utils/schema';
import { CommentTextEditor } from '../../CommentTextEditor';
import { Component, Props } from './';

const EditComment: Component = ({
  commentId,
  onDialogClose,
}: Props): ReactElement => {
  const { t } = useTranslation();
  const [update] = useUpdate();
  const refresh = useRefresh();
  const notify = useNotify();
  const editControllerProps = useEditController({ id: commentId });
  const { record } = editControllerProps;

  const text = useMemo(
    () => ({
      cancel: t('Cancel'),
    }),
    [t],
  );

  const {
    data,
    dataLoaderProps,
  } = useForm(
    ResourceName.COMMENT,
    SchemaType.EDIT,
  );

  const handleSubmit = useCallback(
    async (values: any) => {
      const { fields } = values;

      try {
        await update(
          ResourceName.COMMENT,
          { data: { content: fields.content }, id: commentId },
          { returnPromise: true },
        );
        notify('ra.notification.updated', { messageArgs: { smart_count: 1 }, type: 'info' });
      } catch (error: any) {
        showErrorNotification(error, notify);
      }

      onDialogClose();
      refresh();
    },
    [onDialogClose, refresh, update, commentId, notify],
  );

  return (
    <DataLoader {...dataLoaderProps}>
      <EditContextProvider value={{
        ...editControllerProps,
        record: {
          ...record,
          fields: getRecordFieldsReferToSchema(record?.fields, data?.fields),
        },
      }}
      >
        <SimpleForm
          mode="onBlur"
          toolbar={false}
          onSubmit={handleSubmit}
        >
          <SchemaFields
            customFieldComponent={{
              content: CommentTextEditor,
            }}
            formType={SchemaFields.FormType.INSIDE_MODAL}
            resource={ResourceName.COMMENT}
            schema={data}
          />

          <div className="Dialog-ContentActions">
            <SaveButton />

            <Button
              color="primary"
              onClick={onDialogClose}
              variant="outlined"
            >
              {text.cancel}
            </Button>
          </div>
        </SimpleForm>
      </EditContextProvider>
    </DataLoader>
  );
};

export {
  EditComment,
};

import React, { ReactElement, useState } from 'react';
import { usePermissions } from 'react-admin';
import { ExpandLess, ExpandMore, Settings } from '@mui/icons-material';
import classNames from 'classnames';
import { MenuItemLink } from 'dd-cms-client/app/Layout/Menu/MenuItemLink';
import { ResourceName } from 'dd-cms-client/app/resources';
import { hasUserAndTenantPermission } from 'dd-cms-client/auth/utils/permission';
import { getTenant } from 'dd-cms-client/auth/utils/tenant';
import { Component, Props } from './';
import './ExpandableSection.scss';

const ExpandableSection: Component = ({ isSidebarClosed, resources, title }: Props): ReactElement => {
  const [isSectionExpanded, setIsSectionExpanded] = useState(false);
  const tenant = getTenant();

  const { permissions } = usePermissions();

  const sectionContentClassName = classNames(
    'Menu-ExpandableSectionContent',
    {
      'Menu-ExpandableSectionContent--SidebarClosed': isSidebarClosed,
    },
  );

  return (
    <>
      <div
        onClick={() => setIsSectionExpanded(!isSectionExpanded)}
        className="Menu-ExpandableSectionHeader"
      >
        <Settings className="Menu-ExpandableSectionIcon" />
        <div className="Menu-ExpandableSectionTitle">
          {title}
        </div>
        {isSectionExpanded
          ? <ExpandLess className="Menu-ExpandableSectionExpandIcon"/>
          : <ExpandMore className="Menu-ExpandableSectionExpandIcon"/>
        }
      </div>
      {isSectionExpanded && (
        <div className={sectionContentClassName}>
          {
            resources.map(resource => (
              hasUserAndTenantPermission(resource.name as ResourceName, permissions?.userRole, tenant) && (
                <MenuItemLink resource={resource} key={resource.name} />
              )))
          }
        </div>
      )}
    </>
  );
};

export {
  ExpandableSection,
};

import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { Confirm, useNotify } from 'react-admin';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DroppableProvided,
  DropResult,
} from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { SwapVert } from '@mui/icons-material';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { API_ENDPOINTS } from 'dd-cms-client/app/dataProvider';
import { ResourceName } from 'dd-cms-client/app/resources';
import { getTenant } from 'dd-cms-client/auth/utils/tenant';
import { StrictModeDroppable } from 'dd-cms-client/common/components/StrictModeDroppable';
import { GlobalStateKey, useGlobalState } from 'dd-cms-client/common/globalState';
import { useRequest } from 'dd-cms-client/common/hooks/useRequest';
import { showErrorNotification } from 'dd-cms-client/common/utils/error';
import { Header } from 'dd-cms-client/common/utils/request';
import { getConfig } from 'dd-cms-client/config/utils/config';
import { Component, Props } from './index';
import './StickerList.scss';

const StickerList: Component = ({
  stickers,
  onDragEnd,
  handleUpdateSticker,
  dealId,
}: Props): ReactElement => {
  const { t } = useTranslation();
  const notify = useNotify();
  const tenant = getTenant();

  const [openedLanguageTab] = useGlobalState(GlobalStateKey.OPENED_LANGUAGE_TAB);
  const [isDeleteWindowOpen, setIsDeleteWindowOpen] = useState<boolean>(false);
  const [itemDeleted, setItemDeleted] = useState<string>('');

  const text = useMemo(
    () => ({
      actions: t('Actions'),
      areYouSureDeleteLink: t('Are you sure you want to delete link?'),
      delete: t('Delete'),
      edit: t('Edit'),
      image: t('Image'),
      label: t('Label'),
      name: t('Name'),
      url: t('URL'),
    }),
    [t],
  );

  const {
    makeRequest: deleteStickerRequest,
    isLoading: isLoadingDeleteSticker,
    error: errorDeleteSticker,
    isError: isErrorDeleteSticker,
    isSuccess: isSuccessDeleteSticker,
  } = useRequest(
    `${API_ENDPOINTS[ResourceName.DEAL_LINKS]}/${ResourceName.DEAL_STICKER}/${dealId}`,
    'DELETE',
    {
      [Header.LANGUAGE]: openedLanguageTab,
      [Header.TENANT]: tenant,
    },
  );

  useEffect(
    () => {
      if (isSuccessDeleteSticker) {
        notify('ra.notification.deleted', { messageArgs: { smart_count: 1 }, type: 'info' });
      }
      if (isErrorDeleteSticker) {
        showErrorNotification(errorDeleteSticker, notify);
      }
    },

    [errorDeleteSticker, isErrorDeleteSticker, isSuccessDeleteSticker, notify],
  );

  const handleDeleteButtonClick = useCallback(
    (id: string) => () => {
      setItemDeleted(id);
      setIsDeleteWindowOpen(true);
    }, [],
  );

  const handleDeleteStickerConfirm = useCallback(
    () => {
      setIsDeleteWindowOpen(false);
      deleteStickerRequest({ body: { id: itemDeleted } });
    }, [deleteStickerRequest, itemDeleted],
  );

  const handleDeleteWindowClose = useCallback(
    () => {
      setItemDeleted('');
      setIsDeleteWindowOpen(false);
    }, [],
  );

  const handleDragEnd = useCallback(
    (result: DropResult) => {
      if (result.destination) {
        onDragEnd(result);
      }
    },
    [onDragEnd],
  );

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>{text.image}</TableCell>
            <TableCell>{text.name}/{text.label}</TableCell>
            <TableCell>{text.url}</TableCell>
            <TableCell>{text.actions}</TableCell>
          </TableRow>
        </TableHead>

        <DragDropContext onDragEnd={handleDragEnd}>
          <StrictModeDroppable droppableId="droppable">
            {(provided: DroppableProvided) => (
              <TableBody
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {stickers.map(
                  (sticker, index) => (
                    <Draggable
                      key={sticker.id}
                      draggableId={sticker.id}
                      index={index}
                    >
                      {(provided: DraggableProvided) => (
                        <TableRow
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <TableCell className="StickerList-IconCell">
                            <SwapVert />
                          </TableCell>
                          <TableCell className="StickerList-ImageCell">
                            <img
                              className="List-Image"
                              alt={sticker.label}
                              src={`${getConfig('url.cdn')}/${sticker.image}`}
                            />
                          </TableCell>
                          <TableCell className="StickerList-NameLabelCell">
                            <h3 className='StickerList-Name'>{sticker.name}</h3>
                            <p className='StickerList-Label'>{sticker.label}</p>
                          </TableCell>
                          <TableCell className="StickerList-UrlCell">
                            {sticker.url}
                          </TableCell>
                          <TableCell className="StickerList-ActionsCell">
                            <Button
                              color="info"
                              size="small"
                              onClick={handleUpdateSticker(sticker.id)}
                            >
                              {text.edit}
                            </Button>
                            <Button
                              color="error"
                              size="small"
                              onClick={handleDeleteButtonClick(sticker.id)}
                            >
                              {text.delete}
                            </Button>
                          </TableCell>
                        </TableRow>
                      )}
                    </Draggable>
                  ),
                )}
                {provided.placeholder}
              </TableBody>
            )}
          </StrictModeDroppable>
        </DragDropContext>

        <Confirm
          isOpen={isDeleteWindowOpen}
          loading={isLoadingDeleteSticker}
          title={text.delete}
          content={text.areYouSureDeleteLink}
          onConfirm={handleDeleteStickerConfirm}
          onClose={handleDeleteWindowClose}
        />
      </Table>
    </TableContainer>
  );
};

export {
  StickerList,
};

import React, { ReactElement, useCallback } from 'react';
import { SimpleForm, useNotify, useRefresh, useUpdate } from 'react-admin';
import { ResourceName } from 'dd-cms-client/app/resources';
import { DataLoader } from 'dd-cms-client/common/components/DataLoader';
import { FormToolbar } from 'dd-cms-client/common/components/Form/FormToolbar';
import { SchemaFields } from 'dd-cms-client/common/components/SchemaFields';
import { useForm } from 'dd-cms-client/common/hooks/useForm';
import { showErrorNotification } from 'dd-cms-client/common/utils/error';
import { RequestType } from 'dd-cms-client/common/utils/request';
import { SchemaType } from 'dd-cms-client/common/utils/schema';
import { Component, Props } from './';

const Update: Component = ({
  editControllerProps,
  onBackButtonClick,
  stickerId,
  stickers,
}: Props): ReactElement => {
  const [update] = useUpdate();
  const refresh = useRefresh();
  const notify = useNotify();
  const { record } = editControllerProps;

  const {
    data,
    dataLoaderProps,
  } = useForm(
    ResourceName.DEAL_STICKER,
    SchemaType.EDIT,
    RequestType.UPDATE,
  );

  const handleSubmit = useCallback(
    async (values: any) => {
      const { id } = values;
      try {
        await update(
          ResourceName.DEAL_STICKER,
          { data: { ...values.fields, id }, id: record?.id },
          { returnPromise: true },
        );
        notify('ra.notification.updated', { messageArgs: { smart_count: 1 }, type: 'info' });
        onBackButtonClick();
      } catch (error: any) {
        showErrorNotification(error, notify);
      }

      refresh();
    },
    [refresh, update, record?.id, notify, onBackButtonClick],
  );

  const getRecord = useCallback(
    () => {
      const selectedSticker = stickers.find(sticker => sticker.id === stickerId);

      if (selectedSticker) {
        const { id, ...rest } = selectedSticker;
        return (
          {
            fields: {
              ...rest,
            },
            id,
          }
        );
      }
    },
    [stickerId, stickers],
  );

  return (
    <DataLoader {...dataLoaderProps}>
      <SimpleForm
        mode="onBlur"
        record={getRecord()}
        onSubmit={handleSubmit}
        toolbar={
          <FormToolbar
            onBackButtonClick={onBackButtonClick}
            resourceName={ResourceName.DEAL_STICKER}
          />
        }
      >
        <SchemaFields
          formType={SchemaFields.FormType.EDIT}
          resource={ResourceName.DEAL_STICKER}
          schema={data}
        />
      </SimpleForm>
    </DataLoader>
  );
};

export {
  Update,
};

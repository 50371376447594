import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  useEditController,
  useNotify,
} from 'react-admin';
import { DropResult } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import {
  Button,
  CircularProgress,
} from '@mui/material';
import { API_ENDPOINTS } from 'dd-cms-client/app/dataProvider';
import { ResourceName } from 'dd-cms-client/app/resources';
import { getTenant } from 'dd-cms-client/auth/utils/tenant';
import { TranslatableSection } from 'dd-cms-client/common/components/TranslatableSection';
import {
  GlobalStateKey,
  useGlobalState,
} from 'dd-cms-client/common/globalState';
import { useFormSpy } from 'dd-cms-client/common/hooks/useFormSpy';
import { useRequest } from 'dd-cms-client/common/hooks/useRequest';
import { showErrorNotification } from 'dd-cms-client/common/utils/error';
import { Header, RequestType } from 'dd-cms-client/common/utils/request';
import { SelectedComponentForm } from 'dd-cms-client/deal/StickersTab';
import { Create } from 'dd-cms-client/deal/StickersTab/Create';
import { StickerList } from 'dd-cms-client/deal/StickersTab/StickerList';
import { StickersGlobalList } from 'dd-cms-client/deal/StickersTab/StickersGlobalList';
import { Update } from 'dd-cms-client/deal/StickersTab/Update';
import { Component, Sticker } from './';
import './StickersTab.scss';

const StickersTab: Component = (): ReactElement | null => {
  const { t } = useTranslation();
  const notify = useNotify();
  const tenant = getTenant();

  const editControllerProps = useEditController();
  const { record } = editControllerProps;


  const [openedLanguageTab] = useGlobalState(GlobalStateKey.OPENED_LANGUAGE_TAB);
  const { setIsFormDirty } = useFormSpy();

  const [sortedStickers, setSortedStickers] = useState<Array<Sticker>>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [selectedFormComponent, setSelectedFormComponent] = useState<SelectedComponentForm>({
    isVisible: false,
    requestType: null,
    stickerId: null,
  });

  const stickers = useMemo(
    () => record?.fields ? record.fields.stickers : [],
    [record.fields],
  );

  const text = useMemo(
    () => ({
      addFromAssets: t('Add from assets'),
      createNewSticker: t('Create new sticker'),
      noActive: t('There are no active stickers in'),
    }),
    [t],
  );

  const {
    error: errorSortSticker,
    isError: isErrorSortSticker,
    isSuccess: isSuccessSortSticker,
    makeRequest: sortStickerRequest,
    isLoading: isLoadingSortSticker,
  } = useRequest(
    `${API_ENDPOINTS[ResourceName.DEAL_STICKER]}/${ResourceName.DEAL_STICKER}/${record.id}/sort`,
    'PUT',
    {
      [Header.LANGUAGE]: openedLanguageTab,
      [Header.TENANT]: tenant,
    },
  );

  const changeOrderStickers = useCallback(
    (data: { stickers: Array<Sticker> }) => {
      sortStickerRequest({ body: data });
    },
    [sortStickerRequest],
  );

  useEffect(
    () => {
      setSortedStickers(stickers);
      if (isSuccessSortSticker) {
        notify('ra.notification.updated', { messageArgs: { smart_count: 1 }, type: 'info' });
      }
      if (isErrorSortSticker) {
        showErrorNotification(errorSortSticker, notify);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stickers, errorSortSticker, isErrorSortSticker, notify, isSuccessSortSticker],
  );

  const onDragEnd = useCallback(
    (result: DropResult) => {
      const newItems = [...stickers];
      const [removed] = newItems.splice(result.source.index, 1);

      if (result.destination) {
        newItems.splice(result.destination.index, 0, removed);
      }
      setSortedStickers(newItems);

      changeOrderStickers({ stickers: newItems });
    },
    [changeOrderStickers, stickers],
  );

  const handleAddFromAssetsButtonClick = useCallback(
    () => {
      setIsModalOpen(true);
    }, [],
  );

  const handleCreateNewSticker = useCallback(
    () => {
      setSelectedFormComponent({
        isVisible: true,
        requestType: RequestType.CREATE,
        stickerId: null,
      });
    }, [setSelectedFormComponent],
  );

  const handleUpdateSticker = useCallback(
    (id: string) => () => {
      setSelectedFormComponent({
        isVisible: true,
        requestType: RequestType.UPDATE,
        stickerId: id,
      });
    }, [setSelectedFormComponent],
  );

  const handleBackButtonClick = useCallback(
    () => {
      setSelectedFormComponent({
        isVisible: false,
        requestType: null,
        stickerId: null,
      });
      setIsFormDirty(false);
    },
    [setIsFormDirty],
  );

  return (
    <div className="StickersTab-Container">
      <div className="StickersTab-AddCreateButtonsWrapper">
        <Button
          color="primary"
          size="medium"
          variant='contained'
          sx={{ mr: 2 }}
          disabled={isLoadingSortSticker || selectedFormComponent.requestType !== null}
          onClick={handleAddFromAssetsButtonClick}
        >
          {text.addFromAssets}
        </Button>

        <Button
          color="primary"
          size="medium"
          variant='outlined'
          disabled={isLoadingSortSticker}
          onClick={handleCreateNewSticker}
        >
          {text.createNewSticker}
        </Button>
      </div>

      {isLoadingSortSticker && (
        <div>
          <CircularProgress />
        </div>
      )}

      <TranslatableSection
        isTranslatableFrozen={selectedFormComponent.requestType !== null}
        resource={ResourceName.DEAL_STICKER}
        hasToolbar={false}
      >
        {() => (
          <>
            {!selectedFormComponent.isVisible
              && !isLoadingSortSticker
              && sortedStickers.length > 0 && (
                <StickerList
                  dealId={record.id}
                  stickers={sortedStickers}
                  onDragEnd={onDragEnd}
                  handleUpdateSticker={handleUpdateSticker}
                />
            )}
          </>
        )}
      </TranslatableSection>

      {isModalOpen && (
        <StickersGlobalList
          dealId={record.id}
          setIsModalOpen={setIsModalOpen}
          isModalOpen={isModalOpen}
        />
      )}

      {selectedFormComponent.requestType === RequestType.CREATE && (
        <Create
          onBackButton={handleBackButtonClick}
          dealId={record.id}
        />
      )}

      {selectedFormComponent.requestType === RequestType.UPDATE && (
        <Update
          onBackButtonClick={handleBackButtonClick}
          stickerId={selectedFormComponent.stickerId}
          stickers={stickers}
          editControllerProps={editControllerProps}
        />
      )}

      {!sortedStickers.length
        && selectedFormComponent.requestType === null && (
        <span>
          {text.noActive}
          <span className="StickersTab-ActiveLanguageSpan"> {openedLanguageTab}</span>
        </span>
      )}
    </div>
  );
};

export {
  StickersTab,
};

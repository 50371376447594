import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import {
  EditContextProvider,
  SaveButton,
  SimpleForm,
  useCreate,
  useEditController,
  useNotify,
  useRefresh,
} from 'react-admin';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { ResourceName } from 'dd-cms-client/app/resources';
import { DataLoader } from 'dd-cms-client/common/components/DataLoader';
import { SchemaFields } from 'dd-cms-client/common/components/SchemaFields';
import { useForm } from 'dd-cms-client/common/hooks/useForm';
import { SchemaType } from 'dd-cms-client/common/utils/schema';
import { Component, Props } from './';

const BanUser: Component = ({
  commentId,
  ip,
  onDialogClose,
}: Props): ReactElement => {
  const { t } = useTranslation();
  const editControllerProps = useEditController({ id: commentId });
  const [isSaveButtonEnable, setIsSaveButtonEnable] = useState<boolean>(true);

  const text = useMemo(
    () => ({
      bannedSuccessfully: t('User has been banned'),
      cancel: t('Cancel'),
    }),
    [t],
  );

  const {
    data,
    dataLoaderProps,
  } = useForm(
    ResourceName.BAN,
    SchemaType.CREATE,
  );

  const [create] = useCreate();
  const refresh = useRefresh();
  const notify = useNotify();

  const handleSubmit = useCallback(
    async (values: any) => {
      setIsSaveButtonEnable(false);
      try {
        await create(
          ResourceName.BAN,
          { data: { ...values.fields } },
          { returnPromise: true },
        );
        notify(text.bannedSuccessfully, { messageArgs: { smart_count: 1 }, type: 'info' });
        onDialogClose();
        refresh();
      } catch (error: any) {
        setIsSaveButtonEnable(true);
        // TODO: it is temporary solution till backend provide us one response
        if (error.body.error?.detail) {
          notify(error.body.error?.detail, { type: 'warning' });
        }
        notify(error.body.errors?.ip, { type: 'warning' });
      }
    },
    [create, notify, text.bannedSuccessfully, onDialogClose, refresh],
  );

  return (
    <DataLoader {...dataLoaderProps}>
      <EditContextProvider value={{
        ...editControllerProps,
        record: {
          fields: {
            ip: ip,
          },
        },
      }}
      >
        <SimpleForm
          mode="onBlur"
          toolbar={false}
          onSubmit={handleSubmit}
        >
          <SchemaFields
            formType={SchemaFields.FormType.INSIDE_MODAL}
            resource={ResourceName.BAN}
            schema={data}
          />

          <div className="Dialog-ContentActions">
            <SaveButton alwaysEnable={isSaveButtonEnable} />

            <Button
              color="primary"
              onClick={onDialogClose}
              variant="outlined"
            >
              {text.cancel}
            </Button>
          </div>
        </SimpleForm>
      </EditContextProvider>
    </DataLoader>
  );
};

export {
  BanUser,
};

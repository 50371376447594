import { validateHTMLColorHex } from 'validate-color';

enum Colors { //keep in sync with design tokens
  HIGHLIGHT='#eeffee',
  PIN_TO_TOP='#0d8bff',
  UNPUBLISHED='#c60000',
}

const adjustColorBrightness = (
  inputColor: string,
  amount: number,
): string => {
  if (!validateHTMLColorHex(inputColor)) {
    return '';
  }

  inputColor = inputColor.slice(1);

  const inputColorInt = parseInt(inputColor, 16);

  let r = (inputColorInt >> 16) + amount;
  let g = (inputColorInt & 0x0000FF) + amount;
  let b = ((inputColorInt >> 8) & 0x00FF) + amount;

  r > 255 && (r = 255);
  r < 0 && (r = 0);

  g > 255 && (g = 255);
  g < 0 && (g = 0);

  b > 255 && (b = 255);
  b < 0 && (b = 0);

  return `#${(g | (b << 8) | (r << 16)).toString(16).padStart(6, '0')}`;
};

const lighten = (inputColor: string, amount: number): string => {
  return adjustColorBrightness(inputColor, amount);
};

const darken = (inputColor: string, amount: number): string => {
  return adjustColorBrightness(inputColor, -amount);
};

const rgba = (inputColor: string, alpha: number): string => {
  if (!validateHTMLColorHex(inputColor)) {
    return '';
  }

  inputColor = inputColor.slice(1);

  const r = parseInt(inputColor.slice(0, 2), 16);
  const g = parseInt(inputColor.slice(2, 4), 16);
  const b = parseInt(inputColor.slice(4, 6), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export {
  Colors,
  darken,
  lighten,
  rgba,
};

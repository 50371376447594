import { API_ENDPOINTS } from 'dd-cms-client/app/dataProvider';
import { ResourceName } from 'dd-cms-client/app/resources';
import { SchemaResponseField } from 'dd-cms-client/common/components/SchemaFields';

enum SchemaType {
  CREATE = 'create',
  EDIT = 'edit',
}

const getSchemaUrl = (
  type: SchemaType,
  resource: ResourceName,
): string => (
  `${API_ENDPOINTS[resource]}/schema/${resource}?type=${type}`
);

const getRecordFieldsReferToSchema = (
  recordFields?: Record<string, any> | null,
  schemaFields?: Array<SchemaResponseField>,
): Record<string, any> | null => {
  if (!schemaFields) {
    return null;
  }

  return (
    schemaFields.reduce(
      (result, field) => {
        result[field.name] = recordFields?.[field.name] || null;
        return result;
      },
      {},
    )
  );
};

export {
  getRecordFieldsReferToSchema,
  getSchemaUrl,
  SchemaType,
};

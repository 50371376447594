import { getTenant } from 'dd-cms-client/auth/utils/tenant';
import { tokenManager } from 'dd-cms-client/auth/utils/tokenManager';
import { RequestFieldName } from 'dd-cms-client/common/components/SchemaFields/components/FileInput';
import { Header } from 'dd-cms-client/common/utils/request';
import { getConfig } from 'dd-cms-client/config/utils/config';
import i18nextInit from 'dd-cms-client/i18n/i18nextInit';

interface BlobInfo {
  id: () => string;
  name: () => string;
  filename: () => string;
  blob: () => Blob;
  base64: () => string;
  blobUri: () => string;
  uri: () => string | undefined;
}
type ProgressFn = (percent: number) => void;

// example from tinymce documentation: https://www.tiny.cloud/docs/tinymce/latest/image/#example-using-images_upload_handler
const imagesUploadHandler = (blobInfo: BlobInfo, progress: ProgressFn): Promise<string> => (
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    const { t } = i18nextInit;

    const text = {
      httpError: t('HTTP Error:'),
      imageUploadFailed: t('Image upload failed due to a XHR Transport error. Code:'),
      invalidJson: t('Invalid JSON:'),
    };

    const xhr = new XMLHttpRequest();
    xhr.open('POST', `${getConfig('url.api.static')}/upload`);

    const tenant = getTenant();
    const authorizationHeader = await tokenManager.getAuthorizationHeader();

    xhr.setRequestHeader(Header.TENANT, tenant);
    xhr.setRequestHeader(Header.AUTHORIZATION, authorizationHeader);

    xhr.upload.onprogress = (e) => {
      progress(e.loaded / e.total * 100);
    };

    xhr.onload = () => {
      if (xhr.status === 403) {
        reject({ message: `${text.httpError} ${xhr.status}`, remove: true });
        return;
      }

      if (xhr.status < 200 || xhr.status >= 300) {
        reject(`${text.httpError} ${xhr.status}`);
        return;
      }

      const json = JSON.parse(xhr.responseText);

      if (!json || typeof json.name !== 'string') {
        reject(`${text.invalidJson} ${xhr.responseText}`);
        return;
      }

      resolve(`${getConfig('url.files')}/${json.name}`);
    };

    xhr.onerror = () => {
      reject(`${text.imageUploadFailed} ${xhr.status}`);
    };

    const formData = new FormData();
    formData.append(RequestFieldName.FILE, blobInfo.blob());

    xhr.send(formData);
  }));

export {
  imagesUploadHandler,
};

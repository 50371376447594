import { NotFound } from 'react-admin';
import { ResourceName } from 'dd-cms-client/app/resources';
import { hasUserAndTenantPermission } from 'dd-cms-client/auth/utils/permission';
import { Tenant } from 'dd-cms-client/auth/utils/tenant';
import { UserRole } from 'dd-cms-client/auth/utils/userRole';
import { BanCreate } from './BanCreate';
import { BanEdit } from './BanEdit';
import { BanList } from './BanList';

const ban = (userRole: UserRole, tenant: Tenant): Record<string, any> => {
  const hasPermissions = hasUserAndTenantPermission(ResourceName.BAN, userRole, tenant);

  return {
    create: hasPermissions ? BanCreate : NotFound,
    edit: hasPermissions ? BanEdit : NotFound,
    list: hasPermissions ? BanList : NotFound,
  };
};

export {
  ban,
};

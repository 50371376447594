import React, {
  ReactElement,
  useMemo,
} from 'react';
import {
  Datagrid,
  FunctionField,
  List,
  RaRecord,
  TextField,
  TextInput,
} from 'react-admin';
import { useTranslation } from 'react-i18next';
import { ListActions } from 'dd-cms-client/common/components/ListActions';
import { DEFAULT_ITEMS_PER_PAGE } from 'dd-cms-client/common/utils/list';

const BanList = (): ReactElement => {
  const { t } = useTranslation();

  const text = useMemo(
    () => ({
      endDate: t('End date'),
      id: t('ID'),
      ip: t('IP'),
      note: t('Note'),
      search: t('Search'),
      startDate: t('Start date'),
      updatedAt: t('Updated at'),
    }),
    [t],
  );

  const filters = [
    <TextInput
      label={text.search}
      key="search"
      source="fields.query"
      alwaysOn
      resettable
    />,
  ];

  return (
    <List
      actions={<ListActions />}
      className="List"
      filters={filters}
      perPage={DEFAULT_ITEMS_PER_PAGE}
      sort={{
        field: 'id',
        order: 'DESC',
      }}
    >
      <Datagrid
        bulkActionButtons={false}
        rowClick='edit'
      >
        <TextField
          source="id"
          label={text.id}
          sortBy="id"
        />
        <TextField
          source="fields.ip"
          label={text.ip}
          sortBy="ip"
        />
        <FunctionField
          label={text.note}
          render={(record: RaRecord) => (
            <span dangerouslySetInnerHTML={
              { __html: record?.fields?.note }
            }/>
          )}
        />
        <TextField
          source="fields.startDate"
          label={text.startDate}
          sortBy="startDate"
        />
        <TextField
          source="fields.endDate"
          label={text.endDate}
          sortBy="endDate"
        />
        <TextField
          source="fields.updatedAt"
          label={text.updatedAt}
          sortBy="updatedAt"
        />
      </Datagrid>
    </List>
  );
};

export {
  BanList,
};

import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  EditControllerResult,
  required,
  SimpleForm,
  useEditController,
} from 'react-admin';
import { useGetIdentity } from 'react-admin';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { CommentRecord } from 'dd-cms-client/comment/types';
import { usePrevious } from 'dd-cms-client/common/hooks/usePrevious';
import { CommentTextEditor } from '../common/CommentTextEditor';
import { getSanitizedHtml } from '../utils/getSanitizedHtml';
import { SaveReply } from './SaveReply';
import { Component, ReplyState } from './';
import './ReplyComment.scss';

const ReplyComment: Component = (): ReactElement => {
  const { t } = useTranslation();
  const params = useParams();
  const editControllerProps: EditControllerResult<CommentRecord> = useEditController();
  const { record } = editControllerProps;
  const { identity } = useGetIdentity();
  const replyCommentId = parseInt(params['*'] || '') || null;
  const prevReplyCommetId = usePrevious(replyCommentId);
  const wasScrolledDown = useRef<boolean>(false);

  const [reply, setReply] = useState<ReplyState>(null);

  const text = useMemo(
    () => ({
      author: t('Author'),
      message: t('Message'),
      postingAs: t('Posting as'),
      publish: t('Publish'),
      replyInThread: t('Reply in thread'),
      replyingTo: t('Replying to'),
    }),
    [t],
  );

  const getReplyData = useCallback(
    (commentId: number, replyCommentId: number) => {
      if (commentId === replyCommentId) {
        return ({
          replyAuthorName: record?.fields.authorName || '',
          replyContent: record?.fields.content || '',
        });
      }

      const replyRecord = record?.fields.replies.find(reply => reply.id === replyCommentId);

      return ({
        replyAuthorName: replyRecord?.fields.authorName || '',
        replyContent: replyRecord?.fields.content || '',
      });
    },
    [record?.fields.authorName, record?.fields.content, record?.fields.replies],
  );

  useEffect(
    () => {
      if (
        replyCommentId
        && !editControllerProps.isFetching
        && !wasScrolledDown.current
      ) {
        wasScrolledDown.current = true;

        window.scrollTo({
          behavior: 'smooth',
          left: 0,
          top: document.body.scrollHeight,
        });
      }
    },
    [replyCommentId, editControllerProps.isFetching],
  );

  useEffect(
    () => {
      if (replyCommentId && replyCommentId !== prevReplyCommetId) {
        setReply(getReplyData(record?.id || 0, replyCommentId));
      }
    },
    [getReplyData, prevReplyCommetId, record?.id, replyCommentId],
  );

  return (
    <div className="CommentThread-ReplyWrapper">
      <div className="Title CommentThread-ReplyFormTitle">
        {reply ? `${text.replyingTo}:` : text.replyInThread}
      </div>

      {reply && (
        <div className="CommentThread-ReplyBox">
          <div dangerouslySetInnerHTML={{ __html: getSanitizedHtml(reply.replyContent) }} />

          <span className="CommentThread-ReplyBoxAuthor">
            {text.author}: {reply.replyAuthorName}
          </span>
        </div>
      )}

      <SimpleForm
        className="CommentThread-ReplyForm"
        mode="onBlur"
        toolbar={false}
      >
        <CommentTextEditor
          className="CommentThread-TextEditor"
          source="content"
          label={text.message}
          key="replyComment"
          validate={[required()]}
        />

        <div className="CommentThread-ButtonPublishWrapper">
          <SaveReply
            setReply={setReply}
            replyCommentId={replyCommentId}
          />

          <div className="CommentThread-PostAuthor">
            {text.postingAs}&nbsp;
            {identity?.fullName || ''}
          </div>
        </div>
      </SimpleForm>
    </div>
  );
};

export {
  ReplyComment,
};

import { NotFound } from 'react-admin';
import { ResourceName } from 'dd-cms-client/app/resources';
import { hasUserAndTenantPermission } from 'dd-cms-client/auth/utils/permission';
import { Tenant } from 'dd-cms-client/auth/utils/tenant';
import { UserRole } from 'dd-cms-client/auth/utils/userRole';
import { StickersCreate } from './StickersCreate';
import { StickersEdit } from './StickersEdit';
import { StickersList } from './StickersList';

const sticker = (userRole: UserRole, tenant: Tenant): Record<string, any> => {
  const hasPermissions = hasUserAndTenantPermission(ResourceName.STICKER, userRole, tenant);

  return {
    create: hasPermissions ? StickersCreate : NotFound,
    edit: hasPermissions ? StickersEdit : NotFound,
    list: hasPermissions ? StickersList : NotFound,
  };
};

export {
  sticker,
};

import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { ActiveLanguages } from 'dd-cms-client/common/components/ActiveLanguages';
import { getConfig } from 'dd-cms-client/config/utils/config';
import { Component, Props } from './types';
import './DealField.scss';

const DealField: Component = ({
  className,
  deal,
  language,
  tenant,
}: Props): ReactElement => {
  const rootClassNames = classNames(
    'Comment-DealField',
    className,
  );

  const { categoryName, mainImage, title } = deal;

  return (
    <div className={rootClassNames}>
      {mainImage && (
        <img
          className="Comment-DealFieldImage"
          src={`${getConfig('url.cdn')}/${mainImage}`}
        />
      )}

      <div>
        <div>{tenant} / {categoryName}</div>

        <div className="Comment-DealFieldTitleLanguageWrapper">
          <ActiveLanguages languages={[language]}/>
          {title}
        </div>
      </div>
    </div>
  );
};

export {
  DealField,
};

import React, { ChangeEvent, ReactElement, useCallback, useMemo } from 'react';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DroppableProvided,
  DropResult,
} from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { SwapVert } from '@mui/icons-material';
import {
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { StrictModeDroppable } from 'dd-cms-client/common/components/StrictModeDroppable';
import { GlobalStateKey, useGlobalState } from 'dd-cms-client/common/globalState';
import { getConfig } from 'dd-cms-client/config/utils/config';
import { MediaType } from 'dd-cms-client/deal/MediaTab';
import { Component, Props } from './';
import './MediaList.scss';

const MediaList: Component = ({
  items,
  onCaptionChange,
  onDeleteButtonClick,
  onDragEnd,
}: Props): ReactElement | null => {
  const { t } = useTranslation();
  const [isFormFieldUploading] = useGlobalState(GlobalStateKey.IS_FORM_FIELD_UPLOADING);

  const text = useMemo(
    () => ({
      action: t('Action'),
      cancel: t('Cancel'),
      caption: t('Caption'),
      delete: t('Delete'),
      save: t('Save'),
      thumbnail: t('Thumbnail'),
      type: t('Type'),
    }),
    [t],
  );

  const getVideoImage = useCallback(
    (videoId: string) => (
      `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`
    ),
    [],
  );

  const handleDragEnd = useCallback(
    (result: DropResult) => {
      if (result.destination) {
        onDragEnd(result);
      }
    },
    [onDragEnd],
  );

  const handleCaptionChange = useCallback(
    (index: number) => (event: ChangeEvent<HTMLInputElement>) => {
      const inputValue: string = event.target.value;
      onCaptionChange(index, inputValue);
    },
    [onCaptionChange],
  );

  const handleDeleteButtonClick = useCallback(
    (index: number) => () => {
      onDeleteButtonClick(index);
    },
    [onDeleteButtonClick],
  );

  if (items.length === 0) {
    return null;
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>{text.thumbnail}</TableCell>
            <TableCell>{text.caption}</TableCell>
            <TableCell>{text.type}</TableCell>
            <TableCell>{text.action}</TableCell>
          </TableRow>
        </TableHead>
        <DragDropContext onDragEnd={handleDragEnd}>
          <StrictModeDroppable droppableId="droppable">
            {(provided: DroppableProvided) => (
              <TableBody
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {isFormFieldUploading && (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}

                {!isFormFieldUploading && items.map(
                  (item, index) => (
                    <Draggable key={item.source} draggableId={item.source} index={index}>
                      {(provided: DraggableProvided) => (
                        <TableRow
                          key={item.source}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <TableCell className="MediaList-IconCell">
                            <SwapVert />
                          </TableCell>

                          <TableCell className="MediaList-ImgCell">
                            <img
                              alt="Media"
                              className="MediaList-Image"
                              src={
                                item.type === MediaType.PHOTO
                                  ? `${getConfig('url.cdn')}/100x0/${item.source}`
                                  : getVideoImage(item.source)
                              }
                            />
                          </TableCell>

                          <TableCell className="MediaList-InputCell">
                            {item.type === MediaType.PHOTO && (
                              <TextField
                                defaultValue={item.caption}
                                onChange={handleCaptionChange(index)}
                              />
                            )}
                          </TableCell>

                          <TableCell className="MediaList-TypeCell">
                            {item.type}
                          </TableCell>

                          <TableCell className="MediaList-ActionCell">
                            <Button
                              color="error"
                              size="small"
                              onClick={handleDeleteButtonClick(index)}
                            >
                              {text.delete}
                            </Button>
                          </TableCell>
                        </TableRow>
                      )}
                    </Draggable>
                  ),
                )}
                {provided.placeholder}
              </TableBody>
            )}
          </StrictModeDroppable>
        </DragDropContext>
      </Table>
    </TableContainer>
  );
};

export {
  MediaList,
};

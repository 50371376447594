import  React, { ReactElement } from 'react';
import { AutocompleteArrayInput, AutocompleteInput, ReferenceArrayInput, ReferenceInput, SelectInput } from 'react-admin';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Component, Props } from './types';

const DEFAULT_OPTIONS = [{
  label: '',
  value: '',
}];

const Select: Component = ({
  className,
  isMultiple = false,
  isRequired = false,
  options,
  commonInputProps,
  isDisabled,
}: Props): ReactElement => {
  const { t } = useTranslation();
  const { key, source, ...restInputProps } = commonInputProps;
  const disabledClassName = classNames(
    {
      'Field--Disabled': isDisabled,
    },
  );

  const renderSelect = () => {
    if (isMultiple) {
      if (Array.isArray(options)) {
        return (
          <AutocompleteArrayInput
            {...restInputProps}
            className={disabledClassName}
            choices={options}
            optionText="label"
            optionValue="value"
            source={source}
          />
        );
      }

      return (
        <ReferenceArrayInput
          source={source}
          reference={options.resource}
        >
          <AutocompleteArrayInput
            {...restInputProps}
            className={disabledClassName}
            filterToQuery={(searchText: string) => ({ [options.searchBy]: searchText })}
            optionText={options.searchBy}
            optionValue="id"
          />
        </ReferenceArrayInput>
      );
    }

    if (Array.isArray(options)) {
      return (
        <SelectInput
          {...restInputProps}
          choices={options}
          defaultValue={(
            isRequired
              ? options?.[0].value
              : undefined
          )}
          optionText={(choice) => t(choice?.label)}
          optionValue="value"
          source={source}
          InputProps={{ disabled: isDisabled }}
        />
      );
    }

    return (
      <ReferenceInput
        source={source}
        reference={options.resource}
      >
        <AutocompleteInput
          {...restInputProps}
          className={disabledClassName}
          filterToQuery={(searchText: string) => ({ [options.searchBy]: searchText })}
          optionText={options.searchBy}
          optionValue="id"
        />
      </ReferenceInput>
    );
  };

  return (
    <div className={className} key={key}>
      {renderSelect()}
    </div>
  );
};

export {
  DEFAULT_OPTIONS,
  Select,
};

import React, { ReactElement, useMemo } from 'react';
import { Datagrid, FunctionField, List, RaRecord, TextField } from 'react-admin';
import { useTranslation } from 'react-i18next';
import { ResourceName } from 'dd-cms-client/app/resources';
import { ActiveLanguages } from 'dd-cms-client/common/components/ActiveLanguages';
import { useList } from 'dd-cms-client/common/hooks/useList';
import { DEFAULT_ITEMS_PER_PAGE } from 'dd-cms-client/common/utils/list';

enum CategoryStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

const CategoryList = (): ReactElement => {
  const { t } = useTranslation();
  const { getStatusClassNames } = useList(ResourceName.CATEGORY);

  const text = useMemo(
    () => ({
      active: t('Active'),
      inactive: t('Inactive'),
      name: t('Name'),
      order: t('Order'),
      status: t('Status'),
    }),
    [t],
  );

  return (
    <List
      perPage={DEFAULT_ITEMS_PER_PAGE}
      sort={{ field: 'order', order: 'ASC' }}
    >
      <Datagrid
        rowClick="edit"
        bulkActionButtons={false}
      >
        <FunctionField
          label={text.name}
          render={(record: RaRecord) => (
            <div>
              <div className="List-Title">
                {record?.fields?.name}
              </div>
              <ActiveLanguages languages={record?.activeLanguages} />
            </div>
          )}
          sortBy='name'
        />
        <FunctionField
          label={text.status}
          render={(record: RaRecord) => (
            <span className={getStatusClassNames(record?.fields?.active === CategoryStatus.ACTIVE)}>
              {record?.fields?.active}
            </span>
          )}
        />
        <TextField
          source="fields.order"
          label={text.order}
          sortBy='order'
        />
      </Datagrid>
    </List>
  );
};

export {
  CategoryList,
  CategoryStatus,
};

import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { useFormSpy } from 'dd-cms-client/common/hooks/useFormSpy';
import { Component, Props } from './';

const BackButton: Component = ({
  children,
  onBackClick,
  ...props
}: Props) => {
  const navigate = useNavigate();
  const { reset } = useFormContext();
  const { setIsFormDirty } = useFormSpy();

  const handleClick = useCallback(
    () => {
      reset(); // reset dirty fields (thus isFormDirty = false) and warning window does not appear
      setIsFormDirty(false);

      if (onBackClick) {
        onBackClick();
      } else {
        navigate(-1);
      }
    },
    [navigate, onBackClick, reset, setIsFormDirty],
  );

  return (
    <Button {...props} onClick={handleClick}>
      {children}
    </Button>
  );
};

export {
  BackButton,
};

import React, { ReactElement } from 'react';
import { Admin as RaAdmin, Resource } from 'react-admin';
import { useTranslation } from 'react-i18next';
import { Description, Discount, Note, Palette, ShoppingBasket, SupervisedUserCircle } from '@mui/icons-material';
import { LoginPage } from 'dd-cms-client/app/Admin/LoginPage/LoginPage';
import { createAuthProvider } from 'dd-cms-client/app/authProvider';
import { createDataProvider } from 'dd-cms-client/app/dataProvider';
import { ErrorPage } from 'dd-cms-client/app/ErrorPage';
import { createI18nProvider } from 'dd-cms-client/app/i18nProvider';
import { Layout } from 'dd-cms-client/app/Layout';
import { resourceLabel, ResourceName } from 'dd-cms-client/app/resources';
import { app } from 'dd-cms-client/apps';
import { getTenant } from 'dd-cms-client/auth/utils/tenant';
import { ban } from 'dd-cms-client/ban';
import { category } from 'dd-cms-client/category';
import { comment } from 'dd-cms-client/comment';
import { deal } from 'dd-cms-client/deal';
import { news } from 'dd-cms-client/news';
import { promotion } from 'dd-cms-client/promotion';
import { staticPage } from 'dd-cms-client/staticPage';
import { sticker } from 'dd-cms-client/stickers';
import { theme } from 'dd-cms-client/theme';
import { user } from 'dd-cms-client/user';
import { userProfile } from 'dd-cms-client/userprofile';
import { Component, Props } from './';

const Admin: Component = ({ basename }: Props): ReactElement => {
  const { i18n: { language } } = useTranslation();

  return (
    <RaAdmin
      authProvider={createAuthProvider()}
      basename={`/${basename}`}
      catchAll={ErrorPage}
      dataProvider={createDataProvider()}
      i18nProvider={createI18nProvider(language)}
      layout={Layout}
      requireAuth
      loginPage={LoginPage}
    >
      {(permissions) => {
        if (!permissions) {
          return null;
        }
        const { userRole, userTenants } = permissions;
        const tenant = getTenant();

        return userTenants.includes(tenant) && [
          <Resource
            {...deal(userRole, tenant)}
            icon={ShoppingBasket}
            key={ResourceName.DEAL}
            name={ResourceName.DEAL}
            options={{
              label: resourceLabel.deal,
            }}
          />,
          <Resource
            {...news(userRole, tenant)}
            icon={Note}
            key={ResourceName.NEWS}
            name={ResourceName.NEWS}
          />,
          <Resource
            {...staticPage(userRole, tenant)}
            icon={Description}
            key={ResourceName.STATIC_PAGE}
            name={ResourceName.STATIC_PAGE}
            options={{
              label: resourceLabel.pages,
            }}
          />,
          <Resource
            {...promotion(userRole, tenant)}
            icon={Discount}
            key={ResourceName.PROMOTION}
            name={ResourceName.PROMOTION}
            options={{
              label: resourceLabel.promotions,
            }}
          />,
          <Resource
            {...theme(userRole, tenant)}
            icon={Palette}
            key={ResourceName.THEME}
            name={ResourceName.THEME}
            options={{
              label: resourceLabel.themes,
            }}
          />,
          <Resource
            {...app(userRole, tenant)}
            key={ResourceName.APP}
            name={ResourceName.APP}
          />,
          <Resource
            {...ban(userRole, tenant)}
            key={ResourceName.BAN}
            name={ResourceName.BAN}
          />,
          <Resource
            {...sticker(userRole, tenant)}
            key={ResourceName.STICKER}
            name={ResourceName.STICKER}
          />,
          <Resource
            {...category(userRole, tenant)}
            key={ResourceName.CATEGORY}
            name={ResourceName.CATEGORY}
          />,
          <Resource
            {...user(userRole, tenant)}
            icon={SupervisedUserCircle}
            key={ResourceName.USER}
            name={ResourceName.USER}
          />,
          <Resource
            {...userProfile(userRole, tenant)}
            key={ResourceName.USER_PROFILE}
            name={ResourceName.USER_PROFILE}
          />,
          <Resource
            {...comment(userRole, tenant)}
            key={ResourceName.COMMENT}
            name={ResourceName.COMMENT}
          />,
        ];
      }}
    </RaAdmin>
  );
};

export {
  Admin,
};

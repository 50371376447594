import { useCallback, useState } from 'react';
import { useRefresh } from 'react-admin';
import axios, { AxiosHeaderValue, Method } from 'axios';
import { tokenManager } from 'dd-cms-client/auth/utils/tokenManager';
import { Header } from 'dd-cms-client/common/utils/request';

interface MakeRequestOptions {
  body?: any;
  method?: Method;
  extraHeaders?: { [key: string]: AxiosHeaderValue };
}

interface UseRequestType {
  data: any;
  error: any;
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  makeRequest: (options?: MakeRequestOptions) => void;
}

const useRequest = (
  url: string,
  method: Method = 'get',
  headers = {},
): UseRequestType => {
  const refresh = useRefresh();
  const [request, setRequest] = useState({
    data: null,
    error: null,
    isError: false,
    isLoading: false,
    isSuccess: false,
  });

  const makeAxiosRequest = useCallback(
    ({ body, headers, method }: {
      headers: { [key: string]: AxiosHeaderValue };
      body?: any;
      method: Method;
    }) => (
      axios({
        data: body,
        headers,
        method,
        url,
      }).then(
        response => {
          setRequest({
            data: response.data,
            error: null,
            isError: false,
            isLoading: false,
            isSuccess: true,
          });
          refresh();
        },
      ).catch(error => {
        console.log(error); // eslint-disable-line no-console

        setRequest({
          data: null,
          error,
          isError: true,
          isLoading: false,
          isSuccess: false,
        });
      })
    ),
    [refresh, url],
  );

  const makeRequest = useCallback(
    async (options: MakeRequestOptions | undefined) => {
      setRequest({
        data: request.data,
        error: null,
        isError: false,
        isLoading: true,
        isSuccess: false,
      });

      const authorizationHeader = await tokenManager.getAuthorizationHeader();
      headers[Header.AUTHORIZATION] = authorizationHeader;

      return await makeAxiosRequest({
        body: options?.body,
        headers: { ...headers, ...(options?.extraHeaders || {}) } as {[key: string]: AxiosHeaderValue },
        method: options?.method || method,
      });
    },
    [headers, makeAxiosRequest, method, request.data],
  );

  return {
    ...request,
    makeRequest,
  };
};

export {
  useRequest,
};

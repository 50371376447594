import { useEffect } from 'react';
import { useGetList } from 'react-admin';
import { ResourceName } from 'dd-cms-client/app/resources';
import { GlobalStateKey, useGlobalState } from 'dd-cms-client/common/globalState';
import { Status } from 'dd-cms-client/common/hooks/useList';

const useTotalPendingComments = (): number | undefined => {
  const [totalPendingComments, setTotalPendingComments] = useGlobalState(GlobalStateKey.TOTAL_PENDING_COMMENTS);

  const { total } = useGetList(
    ResourceName.COMMENT,
    {
      filter: { 'fields.status': Status.PENDING },
      pagination: { page: 1, perPage: 1 },
    },
    {
      refetchInterval: 60000,
    },
  );

  useEffect(
    () => {
      if (total !== undefined) {
        setTotalPendingComments(total);
      }
    },
    [setTotalPendingComments, total],
  );

  return totalPendingComments;
};

export {
  useTotalPendingComments,
};

import React, { ReactElement, useCallback, useMemo } from 'react';
import { Button, PasswordInput } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { generate } from 'generate-password-browser';
import { Component, Props } from './types';
import './Password.scss';

const Password: Component = ({
  commonInputProps,
  name,
}: Props): ReactElement => {
  const { setValue } = useFormContext();
  const { t } = useTranslation();

  const text = useMemo(
    () => ({
      generatePassword: t('Generate password'),
    }),
    [t],
  );

  const handleGeneratePasswordClick = useCallback(
    () => {
      setValue(
        `fields.${name}`,
        generate({
          length: 16,
          numbers: true,
          strict: true,
          symbols: true,
        }),
      );
    },
    [setValue, name],
  );

  return (
    <div className="Password">
      <PasswordInput
        {...commonInputProps}
      />

      <Button
        className="Password-Button"
        color="primary"
        label={text.generatePassword}
        onClick={handleGeneratePasswordClick}
        variant="outlined"
      />
    </div>
  );
};

export {
  Password,
};

import React, { ReactElement, useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Tooltip } from '@mui/material';
import { getTenant } from 'dd-cms-client/auth/utils/tenant';
import { FormToolbar } from 'dd-cms-client/common/components/Form/FormToolbar';
import { useFormSpy } from 'dd-cms-client/common/hooks/useFormSpy';
import { Status } from 'dd-cms-client/common/hooks/useList';
import { getConfig } from 'dd-cms-client/config/utils/config';
import { Component, Props } from './';

const EditFormToolbar: Component = (props: Props): ReactElement => {
  const { getValues } = useFormContext();
  const { isFormDirty } = useFormSpy();
  const { t } = useTranslation();
  const values = getValues();
  const tenant = getTenant();
  const isDisabled = isFormDirty || values?.fields?.status !== Status.PUBLISHED;

  const handlePreviewClick = useCallback(
    () => (
      window.open(
        `${getConfig(`url.app.${tenant}`)}/${getConfig('mainLanguage')}?previewToken=${values?.fields.previewToken}`,
        '_blank',
      )
    ),
    [tenant, values?.fields?.previewToken],
  );

  const text = useMemo(
    () => ({
      previewTheme: t('Preview Theme'),
      tooltipInfo: t('Theme must be published and saved'),
    }),
    [t],
  );

  return (
    <FormToolbar className="FormToolbar" {...props}>
      <Tooltip title={isDisabled && text.tooltipInfo}>
        <span>
          <Button
            color="primary"
            disabled={isDisabled}
            onClick={handlePreviewClick}
            variant="outlined"
          >
            {text.previewTheme}
          </Button>
        </span>
      </Tooltip>
    </FormToolbar>
  );
};

export {
  EditFormToolbar,
};

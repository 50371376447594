import React, { ReactElement, useCallback, useMemo } from 'react';
import { Edit, EditControllerResult, useCreatePath, useEditController } from 'react-admin';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import { ResourceName } from 'dd-cms-client/app/resources';
import { ActionsField } from 'dd-cms-client/comment/common/ActionsField';
import { State } from 'dd-cms-client/comment/common/StateIcon';
import { StateIcon } from 'dd-cms-client/comment/common/StateIcon';
import { TitleAndMessageField } from 'dd-cms-client/comment/common/TitleAndMessageField';
import { Status, useList } from 'dd-cms-client/common/hooks/useList';
import { DealField } from './common/DealField';
import { ReplyComment } from './ReplyComment';
import { CommentRecord } from './types';
import './CommentThread.scss';

const CommentThread = (): ReactElement | null => {
  const { t } = useTranslation();
  const editControllerProps: EditControllerResult<CommentRecord> = useEditController();
  const createPath = useCreatePath();
  const { getStatusClassNames } = useList(ResourceName.COMMENT);
  const { record } = editControllerProps;

  const text = useMemo(
    () => ({
      commentFeed: t('Comment Feed'),
      thread: t('Thread'),
    }),
    [t],
  );

  const renderCommonCells = useCallback(
    (
      postedDate: string,
      state: State,
      status: Status,
      commentId: number,
      parentId: number | null,
      ip: string | null,
    ) => (
      <>
        <TableCell>
          {postedDate}
        </TableCell>

        <TableCell>
          <span className={
            getStatusClassNames(
              status === Status.PUBLISHED,
              status === Status.PENDING,
            )
          }>
            {status}
          </span>
        </TableCell>

        <TableCell className="CommentThread-ActionsCell">
          <ActionsField
            ip={ip}
            commentId={commentId}
            parentId={parentId}
            state={state}
            status={status}
          />
        </TableCell>
      </>
    ),
    [getStatusClassNames],
  );

  if (!record?.fields) {
    return null;
  }

  const {
    authorName,
    content,
    parentId,
    deal,
    ip,
    language,
    tenant,
    state,
    title,
    postedDate,
    status,
    replies,
  } = record.fields;

  return (
    <Edit>
      <Box p={2}>
        <Link
          className="CommentThread-CommentFeedLink"
          to={createPath({ resource: ResourceName.COMMENT, type: 'list' })}
        >
          {text.commentFeed}
        </Link>

        <div className="Title">
          {text.thread}
        </div>

        <DealField
          className="CommentThread-DealField"
          deal={deal}
          language={language}
          tenant={tenant}
        />

        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell colSpan={3}>
                  <div className="CommentThread-StateTitleWrapper">
                    <StateIcon
                      className="CommentThread-StateIcon"
                      state={state}
                    />

                    <TitleAndMessageField
                      authorName={authorName}
                      className="CommentThread-TitleAndMessage"
                      content={content}
                      parentId={parentId}
                      title={title}
                    />
                  </div>
                </TableCell>

                {renderCommonCells(postedDate, state, status, record.id, parentId, ip)}
              </TableRow>

              {replies.map(
                (reply) => {
                  const {
                    authorName,
                    content,
                    parentId,
                    state,
                    title,
                    postedDate,
                    status,
                  } = reply.fields;

                  return (
                    <TableRow
                      className="CommentThread-ReplyRow"
                      key={reply.id}
                    >
                      <TableCell />

                      <TableCell className="CommentThread-ReplyStateCell">
                        <StateIcon state={state}/>
                      </TableCell>

                      <TableCell>
                        <TitleAndMessageField
                          authorName={authorName}
                          className="CommentThread-TitleAndMessage"
                          content={content}
                          parentId={parentId}
                          title={title}
                        />
                      </TableCell>

                      {renderCommonCells(postedDate, state, status, reply.id, parentId, ip)}
                    </TableRow>
                  );
                },
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <ReplyComment/>
      </Box>
    </Edit>
  );
};

export {
  CommentThread,
};

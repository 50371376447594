import React, { ChangeEvent, ReactElement, useCallback, useMemo, useState } from 'react';
import { useFormSpy } from 'dd-cms-client/common/hooks/useFormSpy';
import { TabPanels } from './TabPanels';
import { Tabs } from './Tabs';
import { Component, Props } from './types';

const TabsSection: Component = ({
  tabs,
  tabPanels,
}: Props): ReactElement => {
  const [activeTab, setActiveTab] = useState(tabs[0].label);

  const resource = useMemo(
    () => (
      tabs.find(tab => tab.label === activeTab)?.resource
    ),
    [activeTab, tabs],
  );

  const tabLabels = useMemo(
    () => tabs.map(tab => tab.label),
    [tabs],
  );

  const { isFormDirty, onFormDirtyConfirm, setIsFormDirty } = useFormSpy(resource);

  const handleChange = useCallback(
    async (e: ChangeEvent<any>, newActiveTab: string): Promise<any> => {
      if (!isFormDirty || await onFormDirtyConfirm()) {
        setActiveTab(newActiveTab);
        setIsFormDirty(false);
      }
    },
    [onFormDirtyConfirm, isFormDirty, setIsFormDirty],
  );

  return (
    <>
      <Tabs
        activeTab={activeTab}
        onChange={handleChange}
        tabLabels={tabLabels}
      />
      <TabPanels
        activeTab={activeTab}
        tabLabels={tabLabels}
        tabPanels={tabPanels}
      />
    </>
  );
};

export {
  TabsSection,
};

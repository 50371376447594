import i18nextInit from 'dd-cms-client/i18n/i18nextInit';

const validateFiles = (
  files: Array<File>,
  validation: { maxHeight?: number; maxWidth?: number; maxSize?: number; formats?: Array<string> },
  isImage: boolean,
): Promise<Array<File>> => (
  new Promise((resolve, reject) => {
    const { t } = i18nextInit;

    const text = {
      invalidDimensions: (fileName: string) => (
        t('{fileName}: Invalid dimensions', { fileName: fileName })
      ),
      maximumFileUpload: (fileName: string, maxSize: string) => (
        t('{fileName}: Maximum file upload size is {maxSize}', { fileName: fileName, maxSize: maxSize })
      ),
      somethingWentWrong: t('Something went wrong, please try again.'),
    };

    const validFiles: Array<File> = [];
    let invalidFilesCount = 0;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      if (file) {
        if (validation.maxSize && file.size > (validation.maxSize * 1024)) {
          alert(text.maximumFileUpload(file.name, `${validation.maxSize}KB`));
          invalidFilesCount++;
          continue;
        }

        const reader = new FileReader();
        try {
          reader.readAsDataURL(file);
        } catch (e) {
          console.error(e);// eslint-disable-line no-console
          return;
        }

        reader.onerror = (): void => {
          alert(text.somethingWentWrong);
          reject();
        };

        if (isImage) {
          reader.onload = (entry): void => {
            const image = new Image();
            image.src = entry?.target?.result?.toString() || '';

            image.onerror = (): void => {
              alert(text.somethingWentWrong);
              reject();
            };

            image.onload = (): any => {
              if (
                validation.maxWidth && validation.maxWidth < image.width
                || validation.maxHeight && validation.maxHeight < image.height
              ) {
                invalidFilesCount++;
                alert(text.invalidDimensions(file.name));
              } else {
                validFiles.push(file);
              }

              if (
                validFiles.length > 0
                && validFiles.length === files.length - invalidFilesCount
              ) {
                resolve(validFiles);
              }
            };
          };
        } else {
          reader.onload = (): void => {
            const fileType = file.type.split('/').pop();
            if (validation.formats) {
              validation.formats.map(format => {
                if (format === fileType) {
                  validFiles.push(file);
                }
              });
              if (
                validFiles.length > 0
                && validFiles.length === files.length - invalidFilesCount
              ) {
                resolve(validFiles);
              } else {
                reject();
                alert(text.somethingWentWrong);
              }
            }
          };
        }
      }
    }
  })
);

export {
  validateFiles,
};

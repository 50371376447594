import React, { ReactElement, useState } from 'react';
import { useEditController } from 'react-admin';
import { ResourceName } from 'dd-cms-client/app/resources';
import { TranslatableSection } from 'dd-cms-client/common/components/TranslatableSection';
import { LinkAddForm } from 'dd-cms-client/deal/LinksTab/LinkAddForm';
import { LinksList } from 'dd-cms-client/deal/LinksTab/LinksList';
import { Component } from 'dd-cms-client/deal/LinksTab/types';

const LinksTab: Component = (): ReactElement => {
  const editControllerProps = useEditController();
  const { record } = editControllerProps;

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  return (
    <>
      <TranslatableSection
        hasToolbar={false}
        key="links"
        resource={ResourceName.DEAL_LINKS}
      >
        {() => (
          <>
            <LinkAddForm isModalOpen={isModalOpen} dealId={record.id}/>
            <LinksList setIsModalOpen={setIsModalOpen}/>
          </>
        )}
      </TranslatableSection>
    </>
  );
};

export {
  LinksTab,
};

import React, { ReactElement, useMemo } from 'react';
import { Create, SimpleForm } from 'react-admin';
import { useTranslation } from 'react-i18next';
import { ResourceName } from 'dd-cms-client/app/resources';
import { hasNewsletter } from 'dd-cms-client/auth/utils/permission';
import { getTenant } from 'dd-cms-client/auth/utils/tenant';
import { CategorySelector } from 'dd-cms-client/common/components/CategorySelector';
import { DataLoader } from 'dd-cms-client/common/components/DataLoader';
import { FormToolbar } from 'dd-cms-client/common/components/Form/FormToolbar';
import { SchemaFields } from 'dd-cms-client/common/components/SchemaFields';
import { useForm } from 'dd-cms-client/common/hooks/useForm';
import { RequestType } from 'dd-cms-client/common/utils/request';
import { SchemaType } from 'dd-cms-client/common/utils/schema';
import { NewsletterCategorySelector } from 'dd-cms-client/deal/NewsletterCategory';

const DealCreate = (): ReactElement => {
  const { t } = useTranslation();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const text = useMemo(
    () => ({
      //NOTE: Manually added translation strings to enable translation for values provided by backend
      comparison1: t('Competitors'),
      comparison2: t('Self comparison'),
      comparison3: t('Introductory'),
    }),
    [t],
  );

  const {
    data,
    dataLoaderProps,
    onSubmit,
  } = useForm(
    ResourceName.DEAL,
    SchemaType.CREATE,
    RequestType.CREATE,
  );

  return (
    <DataLoader {...dataLoaderProps}>
      <Create>
        <SimpleForm
          mode="onBlur"
          toolbar={<FormToolbar />}
          defaultValues={{
            fields: {
              keyFeatures: Array(4).fill({ keyFeature: '' }),
            },
          }}
          onSubmit={onSubmit}
        >
          <SchemaFields
            customFieldComponent={{
              categoryId: CategorySelector,
              newsletterCategory: hasNewsletter(getTenant()) ? NewsletterCategorySelector : null, //TODO handle on backend
            }}
            formType={SchemaFields.FormType.ADD}
            resource={ResourceName.DEAL}
            schema={data}
          />
        </SimpleForm>
      </Create>
    </DataLoader>
  );
};

export {
  DealCreate,
};

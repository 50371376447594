import { Variants } from 'dd-cms-client/deal/VariantTab';
import { getSelectedVariantGroup } from 'dd-cms-client/deal/VariantTab/utils/getSelectedVariantGroup';
import i18nextInit from 'dd-cms-client/i18n/i18nextInit';
import { MAIN_LANGUAGE } from 'dd-cms-client/i18n/utils/language';
import { LanguageCode } from 'dd-cms-client/i18n/utils/languageCode';

const { t } = i18nextInit;
const text = {
  groupLabel: t('Group label:'),
  groupLabelOptional: t('Group label (optional):'),
};

const getVariantGroupName = (variantsGroupId: string, variants: Variants, activeLanguages: Array<LanguageCode>): string => {
  const selectedVariantGroup = getSelectedVariantGroup(variants, variantsGroupId);

  if (selectedVariantGroup?.name) {
    let groupName = `${text.groupLabel} <strong>(${MAIN_LANGUAGE.toUpperCase()}) ${selectedVariantGroup.name}</strong>`;

    activeLanguages.filter(
      language => language !== MAIN_LANGUAGE,
    ).map(
      language => {
        if (selectedVariantGroup.languages[language].name) {
          groupName += `; (${language.toUpperCase()}) ${selectedVariantGroup.languages[language].name}`;
        }
      },
    );

    return groupName;
  }

  return text.groupLabelOptional;
};

export {
  getVariantGroupName,
  text,
};

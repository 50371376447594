import React, { ReactElement } from 'react';
import { Logout, useUserMenu } from 'react-admin';
import { Edit } from '@mui/icons-material';
import { MenuItemLink } from 'dd-cms-client/app/Layout/Menu/MenuItemLink';
import { ResourceName } from 'dd-cms-client/app/resources';
import { Component } from './';

const UserMenuItems: Component = (): ReactElement => {
  const { onClose } = useUserMenu();

  const userMenu = [
    {
      icon: Edit,
      name: ResourceName.USER_PROFILE,
    },
  ];

  return (
    <>
      {userMenu.map(item => (
        <MenuItemLink
          resource={item}
          key={item.name}
          onClick={onClose}
        />
      ))}
      <Logout />
    </>
  );
};

export {
  UserMenuItems,
};

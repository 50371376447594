import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Save } from '@mui/icons-material';
import { Button } from '@mui/material';
import { BackButton } from 'dd-cms-client/common/components/BackButton';
import { FormToolbarWrapper } from 'dd-cms-client/common/components/Form/FromToolbarWrapper';
import { GlobalStateKey, useGlobalState } from 'dd-cms-client/common/globalState';
import { Component, Props } from './';

const SimpleFormToolbar: Component = ({
  isSubmitDisabled,
  onSubmit,
}: Props): ReactElement => {
  const { t } = useTranslation();
  const [isFormFieldUploading] = useGlobalState(GlobalStateKey.IS_FORM_FIELD_UPLOADING);

  const text = useMemo(
    () => ({
      cancel: t('Cancel'),
      save: t('Save'),
    }),
    [t],
  );

  return (
    <FormToolbarWrapper>
      <Button
        onClick={onSubmit}
        variant="contained"
        startIcon={<Save />}
        disabled={isSubmitDisabled || isFormFieldUploading}
      >
        {text.save}
      </Button>
      <BackButton
        color="primary"
        variant="outlined"
      >
        {text.cancel}
      </BackButton>
    </FormToolbarWrapper>
  );
};

export {
  SimpleFormToolbar,
};

import React, { ReactElement, SyntheticEvent, useCallback, useMemo } from 'react';
import {
  Button,
  ChipField,
  Datagrid,
  DateField,
  FunctionField,
  List,
  RaRecord,
  TextField,
  TextInput,
} from 'react-admin';
import { useTranslation } from 'react-i18next';
import { ResourceName } from 'dd-cms-client/app/resources';
import { getTenant } from 'dd-cms-client/auth/utils/tenant';
import { ActiveLanguages } from 'dd-cms-client/common/components/ActiveLanguages';
import { DuplicateButton } from 'dd-cms-client/common/components/DuplicateButton';
import { ListActions } from 'dd-cms-client/common/components/ListActions';
import { Status, useList } from 'dd-cms-client/common/hooks/useList';
import { DEFAULT_ITEMS_PER_PAGE } from 'dd-cms-client/common/utils/list';
import { getConfig } from 'dd-cms-client/config/utils/config';

const StaticPageList = (): ReactElement => {
  const { t } = useTranslation();
  const { getStatusClassNames } = useList(ResourceName.STATIC_PAGE);
  const tenant = getTenant();

  const text = useMemo(
    () => ({
      actions: t('Actions'),
      duplicate: t('Duplicate'),
      id: t('ID'),
      position: t('Position'),
      preview: t('Preview'),
      search: t('Search'),
      status: t('Status'),
      title: t('Title'),
      updatedAt: t('Updated at'),
    }),
    [t],
  );

  const filters = [
    <TextInput
      label={text.search}
      key="search"
      source="fields.title"
      alwaysOn
      resettable
    />,
  ];

  const handlePreview = useCallback(
    (record: RaRecord) => (e: SyntheticEvent) => {
      e.stopPropagation();

      const slug = `${getConfig(`url.app.${tenant}`)}/${getConfig('mainLanguage')}/site/${record?.fields?.slug}`;
      window.open(slug, '_blank');
    },
    [tenant],
  );

  return (
    <List
      actions={<ListActions />}
      className="List"
      filters={filters}
      perPage={DEFAULT_ITEMS_PER_PAGE}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <Datagrid
        bulkActionButtons={false}
        rowClick="edit"
      >
        <TextField
          source="id"
          label={text.id}
        />
        <FunctionField
          label={text.title}
          render={(record: RaRecord) => (
            <div>
              <div className="List-Title">
                {record?.fields?.title}
              </div>
              <ActiveLanguages languages={record?.activeLanguages}/>
            </div>
          )}
        />
        <ChipField
          label={text.position}
          source="fields.type"
          sortBy="type"
        />
        <FunctionField
          label={text.status}
          render={(record: RaRecord) => (
            <span className={getStatusClassNames(record?.fields?.status === Status.PUBLISHED)}>
              {record?.fields?.status}
            </span>
          )}
        />
        <DateField
          showTime
          source="fields.updatedAt"
          label={text.updatedAt}
          sortBy="updatedAt"
        />
        <FunctionField
          label={text.actions}
          render={(record: RaRecord) => (
            <>
              <DuplicateButton
                record={record}
                resourceName={ResourceName.STATIC_PAGE}
              />

              <Button
                label={text.preview}
                onClick={handlePreview(record)}
                size="small"
              />
            </>
          )}
        />
      </Datagrid>
    </List>
  );
};

export {
  StaticPageList,
};

import React, { ReactElement, useCallback } from 'react';
import { useSetLocale } from 'react-admin';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { DropDownMenu } from 'dd-cms-client/common/components/DropDownMenu';
import { getAdminUILanguages } from 'dd-cms-client/i18n/utils/language';
import { LanguageCode } from 'dd-cms-client/i18n/utils/languageCode';
import { Component, Props } from './';
import './LanguageSelector.scss';

const LanguageSelector: Component = ({
  className,
}: Props): ReactElement => {
  const rootClassName = classNames(
    'LanguageSelector',
    className,
  );
  const { i18n } = useTranslation();
  const setLocale = useSetLocale();

  const handleClick = useCallback(
    (code: LanguageCode) => () => {
      i18n.changeLanguage(code);
      setLocale(code);
    },
    [i18n, setLocale],
  );

  return (
    <DropDownMenu
      className={rootClassName}
      items={
        getAdminUILanguages()
          .filter(language => language.code !== i18n.language)
          .map(
            ({ code, label }) => ({
              name: label,
              onClick: handleClick(code),
            }),
          )
      }
      triggerButtonName={i18n.language}
    />
  );
};

export {
  LanguageSelector,
};

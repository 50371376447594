import React, { ReactElement } from 'react';
import { Edit, SimpleForm } from 'react-admin';
import { ResourceName } from 'dd-cms-client/app/resources';
import { DataLoader } from 'dd-cms-client/common/components/DataLoader';
import { FormToolbar } from 'dd-cms-client/common/components/Form/FormToolbar';
import { SchemaFields } from 'dd-cms-client/common/components/SchemaFields';
import { useForm } from 'dd-cms-client/common/hooks/useForm';
import { useWarnWhenUnsavedChanges } from 'dd-cms-client/common/hooks/useWarnWhenUnsavedChanges';
import { RequestType } from 'dd-cms-client/common/utils/request';
import { SchemaType } from 'dd-cms-client/common/utils/schema';
import { CategoriesSelector } from './CategoriesSelector';
import { DealsSelector } from './DealsSelector';

const UserEdit = (): ReactElement => {
  const {
    data,
    dataLoaderProps,
    onSubmit,
  } = useForm(
    ResourceName.USER,
    SchemaType.EDIT,
    RequestType.UPDATE,
  );

  const FormFields = (): ReactElement => {
    useWarnWhenUnsavedChanges();

    return (
      <SchemaFields
        customFieldComponent={{
          dealCategories: CategoriesSelector,
          dealIds: DealsSelector,
        }}
        formType={SchemaFields.FormType.EDIT}
        resource={ResourceName.USER}
        schema={data}
      />
    );
  };

  return (
    <DataLoader {...dataLoaderProps}>
      <Edit>
        <SimpleForm
          mode="onBlur"
          onSubmit={onSubmit}
          toolbar={<FormToolbar />}
        >
          <FormFields />
        </SimpleForm>
      </Edit>
    </DataLoader>
  );
};

export {
  UserEdit,
};

import React, { ReactElement, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormSpy } from 'dd-cms-client/common/hooks/useFormSpy';
import { RequestType } from 'dd-cms-client/common/utils/request';
import { Create } from './Create';
import { Update } from './Update';
import { Component, Props } from './';

const Variant: Component = ({
  editControllerProps,
  setSelectedVariant,
  selectedVariant,
  variants,
}: Props): ReactElement => {
  const { t } = useTranslation();
  const { record } = editControllerProps;
  const { setIsFormDirty } = useFormSpy();

  const handleBackButtonClick = useCallback(
    () => {
      setSelectedVariant({
        isVisible: false,
        requestType: null,
        variantId: null,
      });
      setIsFormDirty(false);
    },
    [setIsFormDirty, setSelectedVariant],
  );

  const text = useMemo(
    () => ({
      createVariant: t('Create variant'),
      editVariant: t('Edit variant'),
    }),
    [t],
  );

  return (
    <>
      <div className="Title">
        {
          selectedVariant.requestType === RequestType.CREATE
            ? text.createVariant
            : text.editVariant
        }
      </div>

      {selectedVariant.requestType === RequestType.CREATE
        ? (
          <Create
            onBackButtonClick={handleBackButtonClick}
            dealId={record.id}
          />
        )
        : (
          <Update
            editControllerProps={editControllerProps}
            onBackButtonClick={handleBackButtonClick}
            variantId={selectedVariant.variantId}
            variants={variants}
          />
        )}
    </>
  );
};

export {
  Variant,
};

import React, { MouseEvent } from 'react';
import { Component, Props } from './';
import './List.scss';

const List: Component = ({
  links,
  listRef,
  onMenuClose,
}: Props) => {
  const handleClick = (onClick: (e: MouseEvent<HTMLButtonElement>) => void) => (
    e: MouseEvent<HTMLButtonElement>,
  ): void => {
    onClick(e);
    onMenuClose();
  };

  return (
    <ul
      className="ContextMenu-List"
      ref={listRef}
    >
      {links.map(
        ({ color, onClick, label, isDisabled }) => (
          <li key={label}>
            <button
              className="ContextMenu-ListButton"
              onClick={handleClick(onClick)}
              disabled={isDisabled}
              style={color ? { color } : undefined}
            >
              {label}
            </button>
          </li>
        ),
      )}
    </ul>
  );
};

export {
  List,
};

import React, { ReactElement, useCallback, useEffect, useMemo } from 'react';
import { Button, useNotify } from 'react-admin';
import { useTranslation } from 'react-i18next';
import { API_ENDPOINTS } from 'dd-cms-client/app/dataProvider';
import { ResourceName } from 'dd-cms-client/app/resources';
import { getTenant } from 'dd-cms-client/auth/utils/tenant';
import { useRequest } from 'dd-cms-client/common/hooks/useRequest';
import { showErrorNotification } from 'dd-cms-client/common/utils/error';
import { Header } from 'dd-cms-client/common/utils/request';
import { Component, Props } from './';
import './NewsletterTab.scss';

const NewsletterTab: Component = ({ dealId }: Props): ReactElement => {
  const { t } = useTranslation();
  const notify = useNotify();
  const tenant = getTenant();

  const { error, isError, isSuccess, makeRequest, isLoading } = useRequest(
    `${API_ENDPOINTS[ResourceName.DEAL]}/${[ResourceName.DEAL]}/${dealId}/create_newsletter`,
    'post',
    {
      [Header.TENANT]: tenant,
    },
  );

  const text = useMemo(
    () => ({
      createNewsletter: t('Create Newsletter'),
    }),
    [t],
  );

  const handleCreateNewsletter = useCallback(
    () => {
      makeRequest();
    },
    [makeRequest],
  );

  useEffect(
    () => {
      if (isSuccess) {
        notify('ra.notification.created', { messageArgs: { smart_count: 1 }, type: 'info' });
      }

      if (isError) {
        showErrorNotification(error?.response?.data || error, notify);
      }
    },
    [error, isError, isSuccess, notify],
  );

  return (
      <Button
        className="NewsletterTab-Button"
        disabled={isLoading}
        label={text.createNewsletter}
        onClick={handleCreateNewsletter}
        variant="contained"
        size="medium"
      />
  );
};

export {
  NewsletterTab,
};

import React, { ChangeEvent, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useNotify } from 'react-admin';
import { useTranslation } from 'react-i18next';
import { Button, TextField } from '@mui/material';
import { API_ENDPOINTS } from 'dd-cms-client/app/dataProvider';
import { ResourceName } from 'dd-cms-client/app/resources';
import { getTenant } from 'dd-cms-client/auth/utils/tenant';
import { FileInput } from 'dd-cms-client/common/components/SchemaFields/components/FileInput';
import { getGlobalState, GlobalStateKey } from 'dd-cms-client/common/globalState';
import { useRequest } from 'dd-cms-client/common/hooks/useRequest';
import { showErrorNotification } from 'dd-cms-client/common/utils/error';
import { isValidUrl } from 'dd-cms-client/common/utils/isValidUrl';
import { Header } from 'dd-cms-client/common/utils/request';
import { getConfig } from 'dd-cms-client/config/utils/config';
import { Component, Props } from './';
import './LinkAddForm.scss';

enum InputName {
  TITLE = 'title',
  URL = 'url',
}

const LinkAddForm: Component = ({
  dealId,
  isModalOpen,
}: Props): ReactElement => {
  const { t } = useTranslation();

  const notify = useNotify();
  const tenant = getTenant();

  const openedLanguageTab = getGlobalState(GlobalStateKey.OPENED_LANGUAGE_TAB);

  const [valuesInput, setValuesInput] = useState<{
    title: string;
    url: string;
    isDisableUrlInput: boolean;
  }>({
    isDisableUrlInput: false,
    title: '',
    url: '',
  });

  const {
    makeRequest: addLinkRequest,
    isLoading: isLoadingAddLink,
    error: errorAddLink,
    isError: isErrorAddLink,
    isSuccess: isSuccessAddLink,
  } = useRequest(
    `${API_ENDPOINTS[ResourceName.DEAL_LINKS]}/${ResourceName.DEAL_LINKS}/${dealId}`,
    'post',
    {
      [Header.LANGUAGE]: openedLanguageTab,
      [Header.TENANT]: tenant,
    },
  );

  useEffect(
    () => {
      if (isSuccessAddLink) {
        notify('ra.notification.created', { messageArgs: { smart_count: 1 }, type: 'info' });
      }
      if (isErrorAddLink) {
        showErrorNotification(errorAddLink, notify);
      }
    },
    [errorAddLink, isErrorAddLink, isSuccessAddLink, notify],
  );

  const text = useMemo(
    () => ({
      add: t('Add'),
      title: t('Title'),
      upload: t('Upload file'),
    }),
    [t],
  );

  const handleAddItemButton = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (valuesInput.title !== '') {
        isValidUrl(valuesInput.url) && addLinkRequest({ body: valuesInput });
        e.preventDefault();
      }
    }, [addLinkRequest, valuesInput],
  );

  const handleResponseFileNames = useCallback(
    (fileNames: Array<string>) => {
      if (fileNames.length > 0) {
        setValuesInput((prevState) => ({
          ...prevState,
          isDisableUrlInput: true,
          url: `${getConfig('url.files')}/${fileNames[0]}`,
        }));
      } else {
        setValuesInput((prevState) => ({
          ...prevState,
          isDisableUrlInput: false,
          url: '',
        }));
      }
    }, [],
  );

  const handleInputChange = useCallback(
    (e: ChangeEvent<any>) => {
      setValuesInput((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
    },
    [setValuesInput],
  );

  return (
    <div className="LinkAddForm-FormContainer">
      <div className="LinkAddForm-Form">
        <TextField
          name={InputName.TITLE}
          onChange={handleInputChange}
          label={text.title}
          value={valuesInput.title}
          disabled={isLoadingAddLink}
          required={true}
        />
        <TextField
          label="URL"
          name={InputName.URL}
          onChange={handleInputChange}
          disabled={valuesInput.isDisableUrlInput || isLoadingAddLink}
          required={true}
          value={valuesInput.url}
        />
        <div className="LinkAddForm-AddButtonWrapper">
          <Button
            className='LinkAddForm-AddButton'
            type='submit'
            variant="contained"
            disabled={isLoadingAddLink}
            onClick={handleAddItemButton}
          >
            {text.add}
          </Button>
          </div>
        </div>

        <div className='LinkAddForm-FileInput'>
          <FileInput
            isDisabled={isModalOpen}
            label={text.upload}
            isMultiple={false}
            validation={{
              formats: [
                'pdf',
                'PDF',
              ],
              maxSize: 2048,
            }}
            getResponseFileNames={handleResponseFileNames}
          />
        </div>
      </div>
  );
};

export {
  InputName,
  LinkAddForm,
};

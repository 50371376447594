import React, { MouseEvent, ReactElement, useCallback, useMemo, useState } from 'react';
import { useNotify, useRefresh, useResourceContext, useUpdate } from 'react-admin';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { ResourceName } from 'dd-cms-client/app/resources';
import { getTenant } from 'dd-cms-client/auth/utils/tenant';
import { BanUser } from 'dd-cms-client/comment/common/ActionsField/BanUser';
import { State } from 'dd-cms-client/comment/common/StateIcon';
import { ContextMenu, Link } from 'dd-cms-client/common/components/ContextMenu';
import { Dialog } from 'dd-cms-client/common/components/Dialog';
import { Status } from 'dd-cms-client/common/hooks/useList';
import { Colors } from 'dd-cms-client/common/utils/color/color';
import { showErrorNotification } from 'dd-cms-client/common/utils/error';
import { EditComment } from './EditComment';
import { Component, Props } from './types';
import './ActionsField.scss';

enum PUBLISH_TYPE {
  'PUBLISH'= 'publish',
  'REJECT'= 'reject',
  'UNPUBLISH'= 'unpublish',
}

const ActionsField: Component = ({
  commentId,
  ip,
  parentId,
  state,
  status,
}: Props): ReactElement => {
  let contextMenuLinks: Array<Link> = [];
  let buttonActionsData: Array<{
    label: string;
    onClick: (e: MouseEvent<HTMLButtonElement>) => void;
  }> = [];
  const [update] = useUpdate();
  const refresh = useRefresh();
  const notify = useNotify();
  const { t } = useTranslation();
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isBanDialogOpen, setIsBanDialogOpen] = useState(false);
  const navigate = useNavigate();
  const resource = useResourceContext();

  const text = useMemo(
    () => ({
      ban: t('Ban'),
      censor: t('Censor'),
      edit: t('Edit'),
      pinToTop: t('Pin to top'),
      publish: t('Publish'),
      reject: t('Reject'),
      reply: t('Reply'),
      replyPublish: t('Reply & Publish...'),
      status: t('Status'),
      uncensor: t('Uncensor'),
      unpin: t('Unpin'),
      unpublish: t('Unpublish'),
    }),
    [t],
  );

  const getPublishAction = useCallback(
    (type: PUBLISH_TYPE) => ({
      color: type === PUBLISH_TYPE.PUBLISH ? undefined : Colors.UNPUBLISHED,
      label: text[type],
      onClick: async (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();

        try {
          await update(
            ResourceName.COMMENT,
            { data: { status: type === PUBLISH_TYPE.PUBLISH ? Status.PUBLISHED : Status.UNPUBLISHED }, id: `${commentId}/status` },
            { returnPromise: true },
          );
          notify('ra.notification.updated', { messageArgs: { smart_count: 1 }, type: 'info' });
        } catch (error: any) {
          showErrorNotification(error, notify);
        }
        refresh();
      },
    }),
    [commentId, notify, refresh, text, update],
  );

  const getCensorOrUncensorAction = useCallback(
    () => ({
      label: state === State.CENSORED ? text.uncensor : text.censor,
      onClick: async (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();

        try {
          await update(
            ResourceName.COMMENT,
            {
              data: { state: state === State.CENSORED ? State.EMPTY : State.CENSORED },
              id: `${commentId}/state`,
            },
            { returnPromise: true },
          );
          notify('ra.notification.updated', { messageArgs: { smart_count: 1 }, type: 'info' });
        } catch (error: any) {
          showErrorNotification(error, notify);
        }
        refresh();
      },
    }),
    [commentId, notify, refresh, state, text.censor, text.uncensor, update],
  );

  const getPinOrUnpinAction = useCallback(
    () => ({
      color: Colors.PIN_TO_TOP,
      label: state === State.PINNED ? text.unpin : text.pinToTop,
      onClick: async (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();

        try {
          await update(
            ResourceName.COMMENT,
            {
              data: { state: state === State.PINNED ? State.EMPTY : State.PINNED },
              id: `${commentId}/state`,
            },
            { returnPromise: true },
          );
          notify('ra.notification.updated', { messageArgs: { smart_count: 1 }, type: 'info' });
        } catch (error: any) {
          showErrorNotification(error, notify);
        }
        refresh();
      },
    }),
    [commentId, notify, refresh, state, text.pinToTop, text.unpin, update],
  );

  const getReplyAction = useCallback(
    (isPublish: boolean) => ({
      label: isPublish ? text.replyPublish : text.reply,
      onClick: () => {
        navigate(`/${getTenant()}/${resource}/${parentId || commentId}/${commentId}`);
        window.scrollTo({
          behavior: 'smooth',
          left: 0,
          top: document.body.scrollHeight,
        });
      },
    }),
    [commentId, navigate, parentId, resource, text.reply, text.replyPublish],
  );

  const handleDialogClose = useCallback(
    () => {
      if (isBanDialogOpen) {
        setIsBanDialogOpen(false);
      } else if (isEditDialogOpen) {
        setIsEditDialogOpen(false);
      }
    },
    [isBanDialogOpen, isEditDialogOpen],
  );

  const getEditAction = useCallback(
    () => ({
      label: text.edit,
      onClick: (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setIsEditDialogOpen(true);
      },
    }),
    [text.edit],
  );

  const getBanAction = useCallback(
    () => {
      return {
        isDisabled: ip === null,
        label: text.ban,
        onClick: (e: MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();
          setIsBanDialogOpen(true);
        },
      };
    },
    [ip, text.ban],
  );

  switch (status) {
    case Status.PENDING:
      buttonActionsData = [getPublishAction(PUBLISH_TYPE.PUBLISH), getReplyAction(true), getPublishAction(PUBLISH_TYPE.REJECT)];
      contextMenuLinks = [getEditAction(), getCensorOrUncensorAction(), getBanAction()];
      break;

    case Status.PUBLISHED:
      buttonActionsData = [getReplyAction(false), getEditAction(), getPublishAction(PUBLISH_TYPE.UNPUBLISH)];
      contextMenuLinks = [getPinOrUnpinAction(), getCensorOrUncensorAction(), getBanAction()];
      break;

    case Status.UNPUBLISHED:
      buttonActionsData = [getPublishAction(PUBLISH_TYPE.PUBLISH), getReplyAction(false)];
      contextMenuLinks = [getEditAction(), getBanAction()];
      break;
  }

  return (
    <div className="Comment-ActionsField">
      <div className="Comment-ActionsFieldButtonsWrapper">
        {buttonActionsData.map(
          actionData => (
            <Button
              className="Comment-ActionsFieldButton"
              key={actionData.label}
              onClick={actionData.onClick}
              size="small"
            >
              {actionData.label}
            </Button>
          ),
        )}
      </div>

      <ContextMenu
        links={contextMenuLinks}
      />

      <Dialog
        className="Comment-ActionsFieldDialog"
        isOpen={isEditDialogOpen || isBanDialogOpen}
        onClose={handleDialogClose}
        title={isBanDialogOpen ? text.ban : text.edit}
        content={
        <>
          {isBanDialogOpen && (
            <BanUser
              commentId={commentId}
              ip={ip}
              onDialogClose={handleDialogClose}
            />
          )}
          {isEditDialogOpen && (
            <EditComment
              commentId={commentId}
              onDialogClose={handleDialogClose}
            />
          )}
        </>
        }
      />
    </div>
  );
};

export {
  ActionsField,
};

import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useCreate, useEditController, useNotify, useRefresh, useUpdate } from 'react-admin';
import { DropResult } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { ResourceName } from 'dd-cms-client/app/resources';
import { SimpleFormToolbar } from 'dd-cms-client/common/components/Form/SimpleFormToolbar';
import { TranslatableSection } from 'dd-cms-client/common/components/TranslatableSection';
import { useFormSpy } from 'dd-cms-client/common/hooks/useFormSpy';
import { showErrorNotification } from 'dd-cms-client/common/utils/error';
import { RecommendationList } from './RecommendationList';
import { SkuInput } from './SkuInput';
import { Component, Item } from './';
import './RecommendationTab.scss';

const RecommendationTab: Component = (): ReactElement => {
  const { t } = useTranslation();
  const [update, { isLoading: isUpdateLoading }] = useUpdate();
  const [create, { isLoading: isCreateLoading }] = useCreate();
  const notify = useNotify();
  const refresh = useRefresh();
  const { isFormDirty, setIsFormDirty, setOpenFormValues } = useFormSpy();

  const editControllerProps = useEditController();
  const { record } = editControllerProps;

  const recommendationRecords = useMemo(
    () => (
      record?.fields.recommendations || []
    ),
    [record?.fields.recommendations],
  );

  const [items, setItems] = useState<Array<Item>>(recommendationRecords);

  const text = useMemo(
    () => ({
      pleaseSaveFirst: t('Please, save first and then add another sku.'),
    }),
    [t],
  );

  const onSubmit = useCallback(
    async () => {
      try {
        await update(
          ResourceName.DEAL_RECOMMENDED_PRODUCT,
          { data: { recommendations: items }, id: record?.id },
          { returnPromise: true },
        );
        notify('ra.notification.updated', { messageArgs: { smart_count: 1 }, type: 'info' });
        setIsFormDirty(false);
      } catch (error: any) {
        showErrorNotification(error, notify);
      }

      refresh();
    },
    [refresh, update, items, record?.id, notify, setIsFormDirty],
  );

  const onDeleteButtonClick = useCallback(
    (index: number) => {
      const newItems = items.filter((item, itemIndex) => index !== itemIndex);
      setItems(newItems);
      setIsFormDirty(true);
    },
    [items, setIsFormDirty],
  );

  const onDragEnd = useCallback(
    (result: DropResult) => {
      const newItems = [...items];
      const [removed] = newItems.splice(result.source.index, 1);

      if (result.destination) {
        newItems.splice(result.destination.index, 0, removed);
      }
      setItems(newItems);
      setIsFormDirty(true);
    },
    [items, setIsFormDirty],
  );

  const addSku = useCallback(
    async (sku: string) => {
      if (sku) {
        try {
          await create(
            ResourceName.DEAL_RECOMMENDED_PRODUCT,
            { data: { id: record?.id, sku } },
            { returnPromise: true },
          );
          notify('ra.notification.created', { messageArgs: { smart_count: 1 }, type: 'info' });
        } catch (error: any) {
          showErrorNotification(error, notify);
        }

        refresh();
      }
    },
    [create, notify, record?.id, refresh],
  );

  useEffect(
    () => {
      setItems(recommendationRecords);
    },
    [recommendationRecords],
  );

  useEffect(
    () => {
      setOpenFormValues({ fields: { recommendations: items }, id: record?.id });
    },
    [items, record?.id, setOpenFormValues],
  );

  return (
    <>
      <TranslatableSection
        resource={ResourceName.DEAL_RECOMMENDED_PRODUCT}
        ownToolbar={
          <SimpleFormToolbar
            isSubmitDisabled={isUpdateLoading || isCreateLoading || !isFormDirty}
            onSubmit={onSubmit}
          />
        }
      >
        {() => (
          <>
            <SkuInput
              addSku={addSku}
              isDisabled={isFormDirty}
              isLoadingSkuItem={isCreateLoading}
            />

            {isFormDirty && (
              <div className="RecommendationTab-PleaseSaveFirstText">
                {text.pleaseSaveFirst}
              </div>
            )}

            <RecommendationList
              isLoadingSkuItem={isCreateLoading}
              items={items}
              onDeleteButtonClick={onDeleteButtonClick}
              onDragEnd={onDragEnd}
            />
          </>
        )}
      </TranslatableSection>
    </>
  );
};

export {
  RecommendationTab,
};

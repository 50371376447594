import React, { useEffect, useMemo, useState } from 'react';
import { AutocompleteInput, required, useNotify } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { API_ENDPOINTS } from 'dd-cms-client/app/dataProvider';
import { ResourceName } from 'dd-cms-client/app/resources';
import { getTenant } from 'dd-cms-client/auth/utils/tenant';
import { CategoryStatus } from 'dd-cms-client/category/CategoryList';
import { useRequest } from 'dd-cms-client/common/hooks/useRequest';
import { showErrorNotification } from 'dd-cms-client/common/utils/error';
import { Header } from 'dd-cms-client/common/utils/request';
import { Component, Props } from './types';

const NewsletterCategorySelector: Component = ({
  source,
  isDisabled,
  ...restInputProps
}: Props): React.ReactElement | null => {
  const { getValues, setValue } = useFormContext();
  const notify = useNotify();
  const tenant = getTenant();
  const [lowestCategoryId, setLowestCategoryId] = useState<number | null>(null);
  const [availableCategory, setAvailableCategory] = useState<Array<{
    id: number;
    name: string;
  }>>([]);
  const { t } = useTranslation();

  const categoryId = getValues().fields?.categoryId;

  const disabledClassName = classNames(
    {
      'Field--Disabled': isDisabled,
    },
  );

  const text = useMemo(
    () => ({
      newsletterCategory: t('Newsletter category'),
    }),
    [t],
  );

  const {
    makeRequest: getAllCategories,
    error,
    isError,
    isSuccess,
    data,
  } = useRequest(
    `${API_ENDPOINTS[ResourceName.CATEGORY]}/category`,
    'get',
    {
      [Header.TENANT]: tenant,
    },
  );

  useEffect(
    () => {
      availableCategory.length === 0 && getAllCategories();

      return () => setAvailableCategory([]);
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(
    () => {
      if (isSuccess && data.length) {
        const lowestCategory = data.reduce(
          (lowestItem: { id: number }, item: { id: number }) => lowestItem.id < item.id
            ? lowestItem
            : item,
        );

        setAvailableCategory(
          data.map(
            (category: { id: number; fields: { active: CategoryStatus; name: string } }) => {
              if (category.id !== lowestCategory.id && category.fields.active === CategoryStatus.ACTIVE) {
                return {
                  id: category.id,
                  name: category.fields.name,
                };
              }
            },
          ),
        );

        setLowestCategoryId(lowestCategory.id);

        if (categoryId !== lowestCategory.id) {
          setValue(source, null);
        }
      }

      if (isError) {
        showErrorNotification(error, notify);
      }
    },
    [categoryId, data, error, getValues, isError, isSuccess, notify, setValue, source],
  );

  if (categoryId !== lowestCategoryId) {
    return null;
  }

  return (
    <>
      <AutocompleteInput
        {...restInputProps}
        className={disabledClassName}
        label={text.newsletterCategory}
        source={source}
        choices={availableCategory}
        validate={required()}
      />
    </>
  );
};

export {
  NewsletterCategorySelector,
};

import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ICU from 'i18next-icu';
import de from './translations/de.json';
import en from './translations/en.json';
import { LanguageCode } from './utils/languageCode';

i18n
  .use(ICU)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection: {
      caches: ['cookie'],
      order: ['cookie'],
    },
    fallbackLng: LanguageCode.EN,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      [LanguageCode.DE]: {
        translation: de,
      },
      [LanguageCode.EN]: {
        translation: en,
      },
    },
  });

export default i18n;

import { useState } from 'react';

const useOnInitialRender = (action: () => any): void => {
  const [firstRender, setFirstRender] = useState(true);

  if (firstRender) {
    setFirstRender(false);
    action();
  }
};

export { useOnInitialRender };

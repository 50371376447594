import { useFetch } from 'usehooks-ts';
import { getTenant } from 'dd-cms-client/auth/utils/tenant';
import { getConfig } from 'dd-cms-client/config/utils/config';

const THEME_MANIFEST_FILE_NAME = 'theme-manifest.json';

interface ThemeManifestFetch {
  data?: ThemeManifest;
  error?: Error;
}

const useThemeManifest = (): ThemeManifestFetch => {
  const tenant = getTenant();

  const { data, error } = useFetch<ThemeManifest>(
    `${getConfig(`url.app.${tenant}`)}/${THEME_MANIFEST_FILE_NAME}`,
  );

  return {
    data,
    error,
  };
};

export type {
  ThemeManifestFetch,
};

export {
  useThemeManifest,
};

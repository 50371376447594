import { NotFound } from 'react-admin';
import { ResourceName } from 'dd-cms-client/app/resources';
import { hasUserAndTenantPermission } from 'dd-cms-client/auth/utils/permission';
import { Tenant } from 'dd-cms-client/auth/utils/tenant';
import { UserRole } from 'dd-cms-client/auth/utils/userRole';
import { UserProfile } from './UserProfile';

const userProfile = (userRole: UserRole, tenant: Tenant): Record<string, any> => {
  const hasPermissions = hasUserAndTenantPermission(ResourceName.USER_PROFILE, userRole, tenant);

  return {
    list: hasPermissions ? UserProfile : NotFound,
  };
};

export {
  userProfile,
};

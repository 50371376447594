import React, { ReactElement, useCallback } from 'react';
import { useNotify, useRefresh, useUpdate } from 'react-admin';
import { ResourceName } from 'dd-cms-client/app/resources';
import { DataLoader } from 'dd-cms-client/common/components/DataLoader';
import { FormToolbar } from 'dd-cms-client/common/components/Form/FormToolbar';
import { SchemaFields } from 'dd-cms-client/common/components/SchemaFields';
import { TranslatableSection } from 'dd-cms-client/common/components/TranslatableSection';
import { GlobalStateKey, useGlobalState } from 'dd-cms-client/common/globalState';
import { useForm } from 'dd-cms-client/common/hooks/useForm';
import { showErrorNotification } from 'dd-cms-client/common/utils/error';
import { SchemaType } from 'dd-cms-client/common/utils/schema';
import { getSelectedVariant } from 'dd-cms-client/deal/VariantTab/utils/getSelectedVariant';
import { MAIN_LANGUAGE } from 'dd-cms-client/i18n/utils/language';
import { Component, Props } from './';

const Update: Component = ({
  editControllerProps,
  onBackButtonClick,
  variantId,
  variants,
}: Props): ReactElement => {
  const [update] = useUpdate();
  const refresh = useRefresh();
  const notify = useNotify();
  const [openedLanguageTab] = useGlobalState(GlobalStateKey.OPENED_LANGUAGE_TAB);
  const { record } = editControllerProps;

  const {
    data,
    dataLoaderProps,
  } = useForm(
    ResourceName.DEAL_VARIANT,
    SchemaType.EDIT,
  );

  const handleSubmit = useCallback(
    async (values: any) => {
      const { fields, id } = values;

      try {
        await update(
          ResourceName.DEAL_VARIANT,
          { data: { ...fields, id }, id: record?.id },
          { returnPromise: true },
        );
        notify('ra.notification.updated', { messageArgs: { smart_count: 1 }, type: 'info' });
        onBackButtonClick();
      } catch (error: any) {
        showErrorNotification(error, notify);
      }

      refresh();
    },
    [onBackButtonClick, refresh, update, record?.id, notify],
  );

  const getRecord = useCallback(
    () => {
      const selectedVariant = getSelectedVariant(variants, variantId);

      if (selectedVariant) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { languages, id, ...rest } = selectedVariant;

        const name = openedLanguageTab !== MAIN_LANGUAGE
          ? selectedVariant.languages[openedLanguageTab]?.name
          : selectedVariant.name;

        return (
          {
            fields: {
              ...rest,
              name,
            },
            id,
          }
        );
      }
    },
    [openedLanguageTab, variantId, variants],
  );

  return (
    <DataLoader {...dataLoaderProps}>
      <TranslatableSection
        resource={ResourceName.DEAL_VARIANT}
        modifiedEditControllerProps={{
          ...editControllerProps,
          record: getRecord(),
        }}
        onSubmit={handleSubmit}
        ownToolbar={
          <FormToolbar
            onBackButtonClick={onBackButtonClick}
            resourceName={ResourceName.DEAL_VARIANT}
          />
        }
      >
        {activeLanguage => (
          <SchemaFields
            activeLanguage={activeLanguage}
            customWarningRequestUrlId={record?.id}
            formType={SchemaFields.FormType.EDIT}
            resource={ResourceName.DEAL_VARIANT}
            schema={data}
          />
        )}
      </TranslatableSection>
    </DataLoader>
  );
};

export {
  Update,
};

import React, { MouseEvent, ReactElement, useEffect, useMemo, useState } from 'react';
import {
  AutocompleteArrayInput,
  CheckboxGroupInput,
  Datagrid,
  DateTimeInput,
  FunctionField,
  List,
  SelectInput,
  TextField,
  TextInput,
  usePermissions,
} from 'react-admin';
import { useTranslation } from 'react-i18next';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { Button } from '@mui/material';
import classNames from 'classnames';
import { ResourceName } from 'dd-cms-client/app/resources';
import { Tenant } from 'dd-cms-client/auth/utils/tenant';
import { CommentListMobile } from 'dd-cms-client/comment/mobile/CommentListMobile/CommentListMobile';
import { Dialog } from 'dd-cms-client/common/components/Dialog';
import { ListActions } from 'dd-cms-client/common/components/ListActions';
import { Select } from 'dd-cms-client/common/components/SchemaFields/components/Select';
import { useDevice } from 'dd-cms-client/common/hooks/useDevice';
import { Status, useList } from 'dd-cms-client/common/hooks/useList';
import { DEFAULT_ITEMS_PER_PAGE } from 'dd-cms-client/common/utils/list';
import { LanguageCode } from 'dd-cms-client/i18n/utils/languageCode';
import { ActionsField } from './common/ActionsField';
import { DealField } from './common/DealField';
import { SelectDeal } from './common/SelectDeal';
import { State, StateIcon } from './common/StateIcon';
import { TitleAndMessageField } from './common/TitleAndMessageField';
import { CreateComment } from './CreateComment';
import { CommentRecord } from './types';
import './CommentList.scss';

const CommentList = (): ReactElement => {
  const { t } = useTranslation();
  const { permissions } = usePermissions();
  const [isPostNewCommentOpen, setIsPostNewCommentOpen] = useState(false);
  const { getStatusClassNames } = useList(ResourceName.COMMENT);
  const { isMobile } = useDevice();
  const [areFiltersOpen, setAreFiltersOpen] = useState<boolean>(true);

  const text = useMemo(
    () => ({
      actions: t('Actions'),
      datePosted: t('Date posted'),
      datePostedFrom: t('Date posted from'),
      datePostedTo: t('Date posted to'),
      dealCategory: t('Deal category'),
      dealLanguage: t('Deal / Language'),
      filterSearch: t('Filters & Search'),
      language: t('Language'),
      postNewComment: t('Post new comment'),
      searchByAuthor: t('Search by author'),
      searchByMessage: t('Search by message'),
      searchByTitle: t('Search by title'),
      state: t('State'),
      status: t('Status'),
      tenants: t('Tenants'),
      titleAndMessage: t('Title and message'),
    }),
    [t],
  );

  const statusOptions = [
    {
      id: Status.PUBLISHED,
      name: Status.PUBLISHED,
    }, {
      id: Status.PENDING,
      name: Status.PENDING,
    }, {
      id: Status.UNPUBLISHED,
      name: Status.UNPUBLISHED,
    },
  ];

  const stateOptions = [
    {
      id: State.PINNED,
      name: State.PINNED,
    }, {
      id: State.CENSORED,
      name: State.CENSORED,
    },
  ];

  const languageOptions = [
    {
      id: LanguageCode.DE,
      name: LanguageCode.DE,
    }, {
      id: LanguageCode.FR,
      name: LanguageCode.FR,
    },
  ];

  const tenantOptions = permissions?.userTenants.map(
    (tenant: Tenant) => ({
      id: tenant,
      name: tenant,
    }),
  ) || [];

  const filters = [
    <TextInput className="CommentList-FilterField" label={text.searchByTitle} key="searchByTitle" source="fields.title" alwaysOn resettable />,
    <TextInput className="CommentList-FilterField" label={text.searchByMessage} key="searchByMessage" source="fields.content" alwaysOn resettable />,
    <TextInput className="CommentList-FilterField" label={text.searchByAuthor} key="searchByAuthor" source="fields.author" alwaysOn resettable />,
    <Select
      alwaysOn
      className="List-Select CommentList-FilterField"
      options={{ resource: ResourceName.ALL_CATEGORIES, searchBy: 'fields.name' }}
      key="categories"
      commonInputProps={{
        key: 'selected-category',
        label: text.dealCategory,
        source: 'fields.categoryId',
        validate: [],
      }}
    />,
    <SelectDeal
      alwaysOn
      className="CommentList-SelectDeal CommentList-FilterField"
      hasFilters={false}
      key="dealId"
      label='Deal'
      source="fields.dealId"
      validate={[]}
    />,
    <DateTimeInput className="CommentList-FilterField" label={text.datePostedFrom} key="startDate" source="fields.postedFrom" alwaysOn />,
    <DateTimeInput className="CommentList-FilterField" label={text.datePostedTo} key="endDate" source="fields.postedTo" alwaysOn />,
    <SelectInput className="CommentList-FilterField" label={text.status} key="status" source="fields.status" choices={statusOptions} alwaysOn />,
    <SelectInput className="CommentList-FilterField" label={text.state} key="state" source="fields.state" choices={stateOptions} alwaysOn />,
    <AutocompleteArrayInput className="CommentList-FilterField" label={text.language} key="language" source="fields.locales" choices={languageOptions} alwaysOn />,
    <CheckboxGroupInput className="CommentList-FilterField" label={text.tenants} choices={tenantOptions} key="tenants" source="fields.tenants" alwaysOn={tenantOptions.length > 1} />,
  ];

  const listClassName = classNames(
    'List',
    {
      'List--ActionHidden': !areFiltersOpen,
    },
  );

  const handlePostNewCommentClick = () => setIsPostNewCommentOpen(true);
  const handlePostNewCommentDialogClose = () => setIsPostNewCommentOpen(false);
  const handleOpenFilters = () => setAreFiltersOpen(!areFiltersOpen);

  useEffect(
    () => {
      !isMobile && setAreFiltersOpen(true);
    },
    [isMobile],
  );

  return (
    <div className="CommentList-Wrapper">
      <div className="CommentList-PostNewWrapper">
        <Button
          className="CommentList-PostNewButton"
          onClick={handlePostNewCommentClick}
          variant="contained"
        >
          + {text.postNewComment}
        </Button>
      </div>

      {isMobile && (
        <div
          className="CommentList-FilterSearch"
          onClick={handleOpenFilters}
        >
          <span>
            {text.filterSearch}
          </span>
          {areFiltersOpen ? <ArrowDropUp /> : <ArrowDropDown />}
        </div>
      )}

      <List
        className={listClassName}
        actions={areFiltersOpen && <ListActions hasCreateButton={false} />}
        filters={areFiltersOpen ? filters : []}
        perPage={DEFAULT_ITEMS_PER_PAGE}
        sort={{ field: 'postedDate', order: 'DESC' }}
      >

        {isMobile ? (
          <CommentListMobile />
        ) : (
          <Datagrid
            bulkActionButtons={false}
            className="CommentList-Datagrid"
            rowClick={
              (id, resource, record) => (
                record.fields.parentId?.toString() || `${id}`
              )
            }
          >
            <FunctionField
              render={(record?: CommentRecord) => (
                <StateIcon state={record?.fields.state}/>
              )}
            />

            <FunctionField
              label={text.titleAndMessage}
              render={(record?: CommentRecord) => {
                if (record) {
                  const { fields: { parentId, title, content, authorName } } = record;

                  return (
                    <TitleAndMessageField
                      authorName={authorName}
                      content={content}
                      parentId={parentId}
                      title={title}
                    />
                  );
                }
              }}
            />

            <FunctionField
              label={text.dealLanguage}
              render={(record?: CommentRecord) => {
                if (record) {
                  const { fields: { language, tenant, deal } } = record;

                  return (
                    <DealField
                      deal={deal}
                      language={language}
                      tenant={tenant}
                    />
                  );
                }
              }}
            />

            <TextField
              source="fields.postedDate"
              label={text.datePosted}
              sortBy="postedDate"
            />

            <FunctionField
              label={text.status}
              render={(record?: CommentRecord) => (
                <span className={
                  getStatusClassNames(
                    record?.fields.status === Status.PUBLISHED,
                    record?.fields.status === Status.PENDING,
                  )
                }>
                {record?.fields.status}
              </span>
              )}
            />

            <FunctionField
              cellClassName="CommentList-ActionsCell"
              label={text.actions}
              onClick={(e: MouseEvent<HTMLElement>) => e.stopPropagation()}
              render={(record?: CommentRecord) => {
                if (record) {
                  const { fields: { state, status, parentId, ip }, id } = record;

                  return (
                    <ActionsField
                      commentId={id}
                      ip={ip}
                      parentId={parentId}
                      state={state}
                      status={status}
                    />
                  );
                }
              }}
            />
          </Datagrid>
        )}
      </List>

      <Dialog
        className="CommentList-Dialog"
        isOpen={isPostNewCommentOpen}
        onClose={handlePostNewCommentDialogClose}
        title={text.postNewComment}
        content={
          <CreateComment onDialogClose={handlePostNewCommentDialogClose} />
        }
      />
    </div>
  );
};

export {
  CommentList,
};

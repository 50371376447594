import { getConfig } from 'dd-cms-client/config/utils/config';
import { LanguageCode } from './languageCode';

interface Language {
  code: LanguageCode;
  label: string;
}

const MAIN_LANGUAGE: LanguageCode = getConfig('mainLanguage');

const isLanguageAvailable = (language: string): boolean => getAvailableLanguages().some(
  (availableLanguage) => availableLanguage.code === language,
);

const getAvailableLanguages = (): Array<Language> => getConfig('languages.available', []);
const getAdminUILanguages = (): Array<Language> => getConfig('adminUILanguages.available', []);

const getValidLanguages = (languages?: Array<LanguageCode>): Array<LanguageCode> => (
  languages?.filter(
    (language: string) => isLanguageAvailable(language),
  )
  || []
);

export {
  getAdminUILanguages,
  getAvailableLanguages,
  getValidLanguages,
  isLanguageAvailable,
  MAIN_LANGUAGE,
};

import React, { ReactElement } from 'react';
import { useInput, ValidationError } from 'react-admin';
import { Editor } from '@tinymce/tinymce-react';
import classNames from 'classnames';
import { Component, Props } from './types';
import { imagesUploadHandler } from './utils/imagesUploadHandler';
import './RichTextEditor.scss';

const RichTextEditor: Component = ({
  className,
  customInit,
  isDisabled,
  label,
  source,
  validate,
}: Props): ReactElement => {
  const {
    field: { value, onChange, onBlur },
    fieldState: { error },
    isRequired,
  } = useInput({ source, validate });

  const rootClassNames = classNames(
    'RichTextEditor',
    {
      'RichTextEditor-Error': !!error,
    },
    className,
  );

  return (
    <div className={rootClassNames}>
      <div className="RichTextEditor-Label">
        {label}
        {isRequired && (
          <>
            &nbsp;*
          </>
        )}
      </div>

      <Editor
        tinymceScriptSrc="/tinymce/tinymce.min.js"
        onEditorChange={(newValue) => {
          onChange(newValue);
        }}
        onBlur={onBlur}
        value={value}
        disabled={isDisabled}
        init={{
          apply_source_formatting: true,
          content_style: 'body { font-family:Roboto,Helvetica,Arial,sans-serif; font-size:16px }',
          custom_elements: 'style,script',
          entity_encoding: 'raw',
          extended_valid_elements: 'style,script[src|async|defer|type|charset]',
          height: 600,
          images_upload_handler: imagesUploadHandler,
          link_default_target: '_blank',
          menubar: true,
          plugins: [
            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
            'anchor', 'searchreplace', 'visualblocks', 'codeeditor', 'fullscreen',
            'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount',
          ],
          toolbar: 'undo redo | blocks | '
            + 'bold italic forecolor | alignleft aligncenter '
            + 'alignright alignjustify | codeeditor | bullist numlist outdent indent | '
            + 'removeformat | help',
          ...customInit,
        }}
      />

      {!!error && (
        <div className="RichTextEditor-ErrorMessage">
          <ValidationError error={error.message || ''} />
        </div>
      )}
    </div>
  );
};

export {
  RichTextEditor,
};

import React, { ReactElement, useCallback, useEffect, useMemo } from 'react';
import { Button, useEditController, useNotify } from 'react-admin';
import { useTranslation } from 'react-i18next';
import { API_ENDPOINTS } from 'dd-cms-client/app/dataProvider';
import { ResourceName } from 'dd-cms-client/app/resources';
import { getTenant } from 'dd-cms-client/auth/utils/tenant';
import { SchemaFields } from 'dd-cms-client/common/components/SchemaFields';
import { TranslatableSection } from 'dd-cms-client/common/components/TranslatableSection';
import { useForm } from 'dd-cms-client/common/hooks/useForm';
import { useRequest } from 'dd-cms-client/common/hooks/useRequest';
import { showErrorNotification } from 'dd-cms-client/common/utils/error';
import { Header } from 'dd-cms-client/common/utils/request';
import { getRecordFieldsReferToSchema, SchemaType } from 'dd-cms-client/common/utils/schema';
import { Component, Props } from './';
import './TagsTab.scss';

const TagsTab: Component = ({ dealId }: Props): ReactElement => {
  const editControllerProps = useEditController();
  const { record } = editControllerProps;
  const { t } = useTranslation();
  const notify = useNotify();
  const tenant = getTenant();

  const {
    data: tagData,
  } = useForm(
    ResourceName.DEAL_TAG,
    SchemaType.EDIT,
  );

  const text = useMemo(
    () => ({
      autoGenerateOgImages: t('Auto-generate og images'),
    }),
    [t],
  );

  const { error, isError, isSuccess, makeRequest, isLoading } = useRequest(
    `${API_ENDPOINTS[ResourceName.DEAL_TAG]}/${ResourceName.DEAL_TAG}/${dealId}/create_og_images`,
    'post',
    {
      [Header.TENANT]: tenant,
    },
  );

  const handleGenerateOgImages = useCallback(
    () => makeRequest(),
    [makeRequest],
  );

  useEffect(
    () => {
      if (isSuccess) {
        notify('ra.notification.created', { messageArgs: { smart_count: 1 }, type: 'info' });
      }

      if (isError) {
        showErrorNotification(error, notify);
      }
    },
    [error, isError, isSuccess, notify],
  );

  return (
    <>
      <TranslatableSection
        key="tags"
        modifiedEditControllerProps={{
          ...editControllerProps,
          record: {
            ...record,
            fields: getRecordFieldsReferToSchema(record?.fields?.tags, tagData?.fields),
          },
        }}
        resource={ResourceName.DEAL_TAG}
      >
        {activeLanguage => (
          <>
            <SchemaFields
              activeLanguage={activeLanguage}
              formType={SchemaFields.FormType.EDIT}
              resource={ResourceName.DEAL_TAG}
              schema={tagData}
            />

            <Button
              className="TagsTab-CreateOgButton"
              disabled={isLoading}
              label={text.autoGenerateOgImages}
              onClick={handleGenerateOgImages}
              size="medium"
              variant="outlined"
            />
          </>
        )}
      </TranslatableSection>
    </>
  );
};

export {
  TagsTab,
};

import { NotFound } from 'react-admin';
import { ResourceName } from 'dd-cms-client/app/resources';
import { hasUserAndTenantPermission } from 'dd-cms-client/auth/utils/permission';
import { Tenant } from 'dd-cms-client/auth/utils/tenant';
import { UserRole } from 'dd-cms-client/auth/utils/userRole';
import { CategoryCreate } from './CategoryCreate';
import { CategoryEdit } from './CategoryEdit';
import { CategoryList } from './CategoryList';

const category = (userRole: UserRole, tenant: Tenant): Record<string, any> => {
  const hasPermissions = hasUserAndTenantPermission(ResourceName.CATEGORY, userRole, tenant);

  return {
    create: hasPermissions ? CategoryCreate : NotFound,
    edit: hasPermissions ? CategoryEdit : NotFound,
    list: hasPermissions ? CategoryList : NotFound,
  };
};

export {
  category,
};

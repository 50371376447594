import React, { ReactElement, useMemo } from 'react';
import { Edit, useEditController } from 'react-admin';
import { useTranslation } from 'react-i18next';
import { ResourceName } from 'dd-cms-client/app/resources';
import { getTenant } from 'dd-cms-client/auth/utils/tenant';
import { DataLoader } from 'dd-cms-client/common/components/DataLoader';
import { SchemaFields } from 'dd-cms-client/common/components/SchemaFields';
import { TabsSection } from 'dd-cms-client/common/components/TabsSection';
import { TranslatableSection } from 'dd-cms-client/common/components/TranslatableSection';
import { TranslationTab } from 'dd-cms-client/common/components/TranslationTab';
import { getGlobalState, GlobalStateKey } from 'dd-cms-client/common/globalState';
import { useForm } from 'dd-cms-client/common/hooks/useForm';
import { SchemaType } from 'dd-cms-client/common/utils/schema';
import { getConfig } from 'dd-cms-client/config/utils/config';

const StaticPageEdit = (): ReactElement => {
  const { t } = useTranslation();
  const {
    data,
    dataLoaderProps,
  } = useForm(
    ResourceName.STATIC_PAGE,
    SchemaType.EDIT,
  );
  const tenant = getTenant();
  const openedLanguageTab = getGlobalState(GlobalStateKey.OPENED_LANGUAGE_TAB);

  const text = useMemo(
    () => ({
      mainContent: t('Main content'),
      translations: t('Translations'),
    }),
    [t],
  );

  const editControllerProps = useEditController();
  const { record } = editControllerProps;

  const modifiedRecord = useMemo(
    () => {
      if (record?.fields && data?.fields) {
        return ({
          ...record,
          fields: {
            ...record.fields,
            slug: `${getConfig(`url.app.${tenant}`)}/${openedLanguageTab}/site/${record.fields.slug}`,
          },
        });
      }
    },
    [data?.fields, openedLanguageTab, record, tenant],
  );

  return (
    <DataLoader {...dataLoaderProps}>
      <Edit>
        <TabsSection
          tabs={[
            { label: text.mainContent },
            { label: text.translations },
          ]}
          tabPanels={[
            <TranslatableSection
              key='main'
              modifiedEditControllerProps={{
                ...editControllerProps,
                record: modifiedRecord,
              }}
              resource={ResourceName.STATIC_PAGE}
            >
              {activeLanguage => (
                <SchemaFields
                  activeLanguage={activeLanguage}
                  formType={SchemaFields.FormType.EDIT}
                  resource={ResourceName.STATIC_PAGE}
                  schema={data}
                />
              )}
            </TranslatableSection>,
            <TranslationTab
              key="translation"
              resource={ResourceName.STATIC_PAGE}
            />,
          ]}
        />
      </Edit>
    </DataLoader>
  );
};

export {
  StaticPageEdit,
};

import React, { ReactElement, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { LangChip } from 'dd-cms-client/common/components/LangChip';
import { LanguageCode } from 'dd-cms-client/i18n/utils/languageCode';
import { ActionType } from '../TranslationTab';
import { Component, Props } from './types';
import './LanguageList.scss';

const LanguageList: Component = ({
  actionType,
  languages,
  onTranslation,
}: Props): ReactElement => {
  const { t } = useTranslation();
  const text = useMemo(
    () => ({
      activeTranslations: t('Active translations:'),
      addTranslate: t('add & translate'),
      availableLanguages: t('Available languages:'),
      noActiveTranslations: t('No active translations.'),
      noAvailableTranslations: t('No available translations.'),
      remove: t('remove'),
    }),
    [t],
  );

  const handleButtonClick = useCallback(
    (language: LanguageCode) => () => {
      onTranslation(actionType, language);
    },
    [actionType, onTranslation],
  );

  return (
    <>
      <h3>
        {actionType === ActionType.REMOVE
          ? text.activeTranslations
          : text.availableLanguages
        }
      </h3>
      {languages.length === 0 && (
        actionType === ActionType.REMOVE
          ? text.noActiveTranslations
          : text.noAvailableTranslations
      )}

      {Array.isArray(languages) && (
        languages.map(
          language => (
            <div
              className="LanguageList-Row"
              key={language}
            >
              <LangChip country={language} />
              <Button
                variant={
                  actionType === ActionType.REMOVE
                    ? 'outlined'
                    : 'contained'
                }
                color="primary"
                onClick={handleButtonClick(language)}
              >
                {actionType === ActionType.REMOVE
                  ? text.remove
                  : text.addTranslate
                }
              </Button>
            </div>
          ),
        )
      )}
    </>
  );
};

export {
  LanguageList,
};

import * as React from 'react';
import { ReactElement, useMemo } from 'react';
import { Title, usePermissions } from 'react-admin';
import { useTranslation } from 'react-i18next';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Component } from 'dd-cms-client/app/Layout/AppBar';
import { getTenant } from 'dd-cms-client/auth/utils/tenant';

const ErrorPage: Component = (): ReactElement => {
  const tenant = getTenant();
  const { permissions } = usePermissions();
  const { t } = useTranslation();

  const text = useMemo(
    () => ({
      accessDenied: t('Access Denied'),
      noAccess: t('You don\'t have access to selected tenant'),
      notFound: t('Not Found'),
      pageNotFound: t('Page not found'),
    }),
    [t],
  );

  let title = text.notFound;
  let content = `404 ${text.pageNotFound}`;

  if (!permissions?.userTenants.includes(tenant)) {
    title = text.accessDenied;
    content =  `403 ${text.noAccess}`;
  }
  return (
    <Card>
      <Title title={title} />
      <CardContent>
        <h1>{content}</h1>
      </CardContent>
    </Card>
  );
};

export {
  ErrorPage,
};

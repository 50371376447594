import i18nextInit from 'dd-cms-client/i18n/i18nextInit';

const isValidUrl = (urlName: string): boolean => {
  const { t } = i18nextInit;

  const text = {
    invalidUrl: t('Invalid url address, please try again.'),
  };

  let url;

  try {
    url = new URL(urlName);

    if (url.protocol === 'http:' || url.protocol === 'https:') {
      return true;
    }
    alert(text.invalidUrl);

    return false;
  } catch (error: any) {
    alert(text.invalidUrl);

    return false;
  }
};

export {
  isValidUrl,
};

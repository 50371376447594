import React, { ReactElement, useMemo } from 'react';
import { useEditController } from 'react-admin';
import { ResourceName } from 'dd-cms-client/app/resources';
import { TranslatableSection } from 'dd-cms-client/common/components/TranslatableSection';
import { AppsList } from 'dd-cms-client/deal/AppsTab/AppsList';
import { Component } from './';

const AppsTab: Component = (): ReactElement => {
  const editControllerProps = useEditController();
  const { record } = editControllerProps;

  const apps = useMemo(
    () => record?.fields ? record.fields.apps : [],
    [record.fields],
  );

  return (
    <>
      <TranslatableSection
        hasToolbar={false}
        key="apps"
        resource={ResourceName.DEAL_APP}
      >
        {() => (
          <AppsList
            dealId={record.id}
            apps={apps}
          />
        )}
      </TranslatableSection>
    </>
  );
};

export {
  AppsTab,
};

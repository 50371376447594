import React, { ReactElement } from 'react';
import { Layout as RaLayout, LayoutProps } from 'react-admin';
import classNames from 'classnames';
import { getTenant } from 'dd-cms-client/auth/utils/tenant';
import { AppBar } from './AppBar';
import { Menu } from './Menu';
import { Component } from './';
import './Layout.scss';

const Layout: Component = (props: LayoutProps): ReactElement => {
  const tenant = getTenant();

  const rootClassName = classNames(
    `Layout--${tenant}`,
  );

  return (
    <RaLayout
      {...props}
      className={rootClassName}
      appBar={AppBar}
      menu={Menu}
    />
  );
};

export {
  Layout,
};

import React, { SyntheticEvent, useCallback } from 'react';
import { RaRecord, useCreateController } from 'react-admin';
import classNames from 'classnames';
import { isAfter, isBefore, parseISO } from 'date-fns';
import { ResourceName } from 'dd-cms-client/app/resources';
import { Colors } from 'dd-cms-client/common/utils/color/color';

enum Status {
  PUBLISHED = 'published',
  UNPUBLISHED = 'unpublished',
  PENDING = 'pending',
}

interface UseListType {
  handleDuplicate: (record: RaRecord) => (e: SyntheticEvent) => void;
  getActiveRowBackground: (startDate?: string | null, endDate?: string | null, isActive?: boolean | null) => Record<string, any>;
  getStatusClassNames: (isActive: boolean, isPending?: boolean) => string;
}

const useList = (resourceName: ResourceName): UseListType => {
  const createControllerProps = useCreateController({
    resource: resourceName,
  });

  const handleDuplicate = useCallback(
    (record: RaRecord) => (e: SyntheticEvent) => {
      e.stopPropagation();

      const copy = {
        ...record.fields,
        status: Status.UNPUBLISHED,
        title: `${record.fields.title} copy`,
      };

      createControllerProps.save?.(copy);
    },
    [createControllerProps],
  );

  const getActiveRowBackground = useCallback(
    (startDate?: string | null, endDate?: string | null, isActive?: boolean | null): Record<string, any> => {
      const currentDateTime = new Date();
      const start = startDate ? parseISO(startDate) : null;
      const end = endDate ? parseISO(endDate) : null;

      const isRowActive = (!start || isBefore(start, currentDateTime))
        && (!end || isAfter(end, currentDateTime))
        && isActive;

      const styles: React.CSSProperties = {};

      if (isRowActive) {
        styles.backgroundColor = Colors.HIGHLIGHT;
      }

      return styles;
    },
    [],
  );

  const getStatusClassNames = useCallback(
    (isActive: boolean, isPending = false): string => (
      classNames(
        'List-Chip',
        'List-Chip--Status', {
          'List-Chip--StatusInactive': !isActive,
          'List-Chip--StatusPending': isPending,
        },
      )
    ),
    [],
  );

  return {
    getActiveRowBackground,
    getStatusClassNames,
    handleDuplicate,
  };
};

export {
  Status,
  useList,
};

import { darken, lighten, rgba } from 'dd-cms-client/common/utils/color/color';

const PROCESS_FUNC = {
  darken,
  lighten,
  rgba,
};

const processValue = (
  value: string,
  process?: ThemeManifestValueConfigProcess,
): string => {
  if (process?.func && PROCESS_FUNC[process.func]) {
    // @ts-ignore
    return PROCESS_FUNC[process.func](value, ...process.args);
  }

  return value;
};

export {
  processValue,
};

import '../../i18n/i18nextInit';
import React, { ReactElement } from 'react';
import { useBeforeunload } from 'react-beforeunload';
import { Helmet } from 'react-helmet-async';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Admin } from 'dd-cms-client/app/Admin';
import { DEFAULT_TENANT, getTenantUrl, Tenant } from 'dd-cms-client/auth/utils/tenant';
import { getGlobalState, GlobalStateKey } from 'dd-cms-client/common/globalState';
import { getConfig } from 'dd-cms-client/config/utils/config';
import { Component } from './';
import './common.scss';
import './App.scss';

const BASE_PATH = '/:tenant/*';

const App: Component = (): ReactElement => {
  useBeforeunload(e => {
    const isFormDirty = getGlobalState(GlobalStateKey.IS_FORM_DIRTY);

    if (isFormDirty) {
      e.preventDefault();
    }
  });

  return (
    <>
      <Helmet>
        <title>{`CMS ${getConfig('env')}`}</title>
        <meta name="app-version" content={`${getConfig('version')}`} />
      </Helmet>
      <Routes>
        {Object.values(Tenant).map(tenant => (
          <Route
            element={<Admin basename={tenant} />}
            key={tenant}
            path={`/${tenant}/*`}
          />
        ))}
        <Route
          element={<Navigate to={getTenantUrl(DEFAULT_TENANT)} replace />}
          path="*"
        />
      </Routes>
    </>
  );
};

export {
  App,
  BASE_PATH,
};

import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { Confirm, FunctionField, useNotify } from 'react-admin';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DroppableProvided,
  DropResult,
} from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { SwapVert } from '@mui/icons-material';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { API_ENDPOINTS } from 'dd-cms-client/app/dataProvider';
import { ResourceName } from 'dd-cms-client/app/resources';
import { getTenant } from 'dd-cms-client/auth/utils/tenant';
import { StrictModeDroppable } from 'dd-cms-client/common/components/StrictModeDroppable';
import { Status, useList } from 'dd-cms-client/common/hooks/useList';
import { useRequest } from 'dd-cms-client/common/hooks/useRequest';
import { showErrorNotification } from 'dd-cms-client/common/utils/error';
import { Header, RequestType } from 'dd-cms-client/common/utils/request';
import { getConfig } from 'dd-cms-client/config/utils/config';
import { Variant, VariantsGroup } from 'dd-cms-client/deal/VariantTab';
import { getVariantGroupName } from 'dd-cms-client/deal/VariantTab/utils/getVariantGroupName';
import { MAIN_LANGUAGE } from 'dd-cms-client/i18n/utils/language';
import { LanguageCode } from 'dd-cms-client/i18n/utils/languageCode';
import { Component, Props } from './';
import './VariantTable.scss';

const VariantTable: Component = ({
  activeLanguages,
  changeOrder,
  dealId,
  variants,
  setGroupDialog,
  setSelectedVariant,
}: Props): ReactElement => {
  const { t } = useTranslation();
  const notify = useNotify();
  const tenant = getTenant();
  const { getStatusClassNames } = useList(ResourceName.DEAL_VARIANT);

  const [deleteWindow, setDeleteWindow] = useState<{
    groupId: null | string;
    isOpen: boolean;
    variantId: null | string;
  }>({
    groupId: null,
    isOpen: false,
    variantId: null,
  });

  const {
    makeRequest: deleteGroupRequest,
    isLoading: isLoadingDeleteGroup,
    error: errorDeleteGroup,
    isError: isErrorDeleteGroup,
    isSuccess: isSuccessDeleteGroup,
  } = useRequest(
    `${API_ENDPOINTS[ResourceName.DEAL_VARIANT_GROUP]}/${ResourceName.DEAL_VARIANT_GROUP}/${dealId}`,
    'delete',
    {
      [Header.TENANT]: tenant,
    },
  );

  const {
    makeRequest: deleteVariantRequest,
    isLoading: isLoadingDeleteVariant,
    error: errorDeleteVariant,
    isError: isErrorDeleteVariant,
    isSuccess: isSuccessDeleteVariant,
  } = useRequest(
    `${API_ENDPOINTS[ResourceName.DEAL_VARIANT]}/${ResourceName.DEAL_VARIANT}/${dealId}`,
    'delete',
    {
      [Header.TENANT]: tenant,
    },
  );

  const text = useMemo(
    () => ({
      actions: t('Actions'),
      areYouSureDeleteGroup: t('Are you sure you want to delete this group?'),
      areYouSureDeleteVariant: t('Are you sure you want to delete this variant?'),
      dealPrice: t('Deal Price'),
      delete: t('Delete'),
      deleteGroup: t('Delete group'),
      deleteVariant: t('Delete variant'),
      edit: t('Edit'),
      groupLabel: t('Group label:'),
      groupLabelOptional: t('Group label (optional):'),
      image: t('Image'),
      name: t('Name'),
      navisionId: t('Navision ID'),
      originalPrice: t('Original Price'),
      rename: t('Rename'),
      status: t('Status'),
      stock: t('Stock'),
    }),
    [t],
  );

  const handleDeleteWindowClose = () => {
    setDeleteWindow({
      groupId: null,
      isOpen: false,
      variantId: null,
    });
  };

  const handleDeleteGroupButtonClick = (variantsGroupId: string) => () => {
    setDeleteWindow({
      groupId: variantsGroupId,
      isOpen: true,
      variantId: null,
    });
  };

  const handleDeleteGroupWindowConfirm = useCallback(
    () => {
      deleteGroupRequest({ body: { id: deleteWindow.groupId } });
      handleDeleteWindowClose();
    },
    [deleteWindow.groupId, deleteGroupRequest],
  );

  const handleDeleteVariantButtonClick = (variantId: string) => () => {
    setDeleteWindow({
      groupId: null,
      isOpen: true,
      variantId: variantId,
    });
  };

  const handleDeleteVariantWindowConfirm = useCallback(
    () => {
      deleteVariantRequest({ body: { id: deleteWindow.variantId } });
      handleDeleteWindowClose();
    },
    [deleteVariantRequest, deleteWindow.variantId],
  );

  const handleRenameGroupButtonClick = (variantsGroupId: string) => () => {
    setGroupDialog({
      groupId: variantsGroupId,
      isOpen: true,
      requestType: RequestType.UPDATE,
    });
  };

  const handleUpdateVariantButtonClick = (variantId: string) => () => {
    setSelectedVariant({
      isVisible: true,
      requestType: RequestType.UPDATE,
      variantId,
    });
  };

  const removeFromList = useCallback(
    (list: Array<Variant>, index: number): [Variant, Array<Variant>] => {
      const result = [...list];
      const [removed] = result.splice(index, 1);
      return [removed, result];
    },
    [],
  );

  const addToList = useCallback(
    (list: Array<Variant>, index: number, element: Variant) => {
      const result = [...list];
      result.splice(index, 0, element);
      return result;
    },
    [],
  );

  const handleDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) {
        return;
      }

      const variantsCopy: Array<VariantsGroup> = JSON.parse(JSON.stringify(variants));
      const [sourceVariantKey] = result.source.droppableId.split('_');

      const [removedSourceVariantItem, newSourceVariantItems] = removeFromList(
        variantsCopy[sourceVariantKey].items,
        result.source.index,
      );

      variantsCopy[sourceVariantKey].items = newSourceVariantItems;

      const [destinationVariantKey] = result.destination.droppableId.split('_');

      const destinationVariantItems = variantsCopy[destinationVariantKey].items;

      variantsCopy[destinationVariantKey].items = addToList(
        destinationVariantItems,
        result.destination.index,
        removedSourceVariantItem,
      );

      changeOrder(variantsCopy);
    },
    [addToList, changeOrder, removeFromList, variants],
  );

  const getVariantName = useCallback(
    (
      variantName: string,
      languages: Record<LanguageCode, Record<'name', string>>,
    ): string => {
      let name = `<strong>(${MAIN_LANGUAGE.toUpperCase()}) ${variantName}</strong>`;

      if (languages && !Array.isArray(languages)) {
        activeLanguages
          .filter(language => language !== MAIN_LANGUAGE)
          .map(
            language => {
              if (languages[language].name) {
                name += `<br />(${language.toUpperCase()}) ${languages[language].name}`;
              }
            },
          );
      }

      return name;
    },
    [activeLanguages],
  );

  useEffect(
    () => {
      if (isSuccessDeleteGroup || isSuccessDeleteVariant) {
        notify('ra.notification.deleted', { messageArgs: { smart_count: 1 }, type: 'info' });
      }

      if (isErrorDeleteGroup || isErrorDeleteVariant) {
        showErrorNotification(errorDeleteGroup || errorDeleteVariant, notify);
      }
    },
    [errorDeleteGroup, errorDeleteVariant, isErrorDeleteGroup, isErrorDeleteVariant, isSuccessDeleteGroup, isSuccessDeleteVariant, notify],
  );

  return (
    <>
      <TableContainer>
        <Table>
          <DragDropContext onDragEnd={handleDragEnd}>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>{text.image}</TableCell>
                <TableCell>{text.name}</TableCell>
                <TableCell>{text.navisionId}</TableCell>
                <TableCell>{text.stock}</TableCell>
                <TableCell>{text.dealPrice}</TableCell>
                <TableCell>{text.originalPrice}</TableCell>
                <TableCell>{text.status}</TableCell>
                <TableCell>{text.actions}</TableCell>
              </TableRow>
            </TableHead>
            {variants.map(
              (variantsGroup: VariantsGroup, index) => (
                <StrictModeDroppable
                  droppableId={`${index}_${variantsGroup.id}`}
                  key={variantsGroup.id}
                >
                  {(provided: DroppableProvided) => (
                    <TableBody
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      <TableRow className="VariantList-GroupNameRow">
                        <TableCell
                          colSpan={8}
                          dangerouslySetInnerHTML={
                            { __html: getVariantGroupName(variantsGroup.id, variants, activeLanguages) }
                          }
                        />
                        <TableCell>
                          <>
                            <Button
                              size="small"
                              onClick={handleRenameGroupButtonClick(variantsGroup.id)}
                            >
                              {text.rename}
                            </Button>

                            {(
                              variants.length > 1
                              || (variants.length === 1 && variants[0].items.length === 0)
                            ) && (
                              <Button
                                color="error"
                                size="small"
                                onClick={handleDeleteGroupButtonClick(variantsGroup.id)}
                              >
                                {text.delete}
                              </Button>
                            )}
                          </>
                        </TableCell>
                      </TableRow>

                      {variantsGroup.items.map(
                        (variant, index) => (
                          <Draggable
                            key={variant.id}
                            draggableId={variant.id}
                            index={index}
                          >
                            {(provided: DraggableProvided) => (
                              <TableRow
                                className="VariantTable-VariantRow"
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <TableCell className="VariantTable-IconCell">
                                  <SwapVert />
                                </TableCell>
                                <TableCell className="VariantTable-ImageCell">
                                  {variant.image && (
                                    <img
                                      alt={text.image}
                                      className="VariantList-Image"
                                      src={`${getConfig('url.cdn')}/${variant.image}`}
                                    />
                                  )}
                                </TableCell>
                                <TableCell
                                  className="VariantTable-NameCell"
                                  dangerouslySetInnerHTML={
                                    { __html: getVariantName(variant.name, variant.languages) }
                                  }
                                />
                                <TableCell className="VariantTable-IdCell">
                                  {variant.navisionId}
                                </TableCell>
                                <TableCell className="VariantTable-AvailableStock">
                                  {variant.availableStock}
                                </TableCell>
                                <TableCell className="VariantTable-SpecialPrice">
                                  {variant.specialPrice}
                                </TableCell>
                                <TableCell className="VariantTable-OriginalPrice">
                                  {variant.originalPrice}
                                </TableCell>
                                <TableCell className="VariantTable-Status">
                                  <FunctionField
                                    label={text.status}
                                    render={() => (
                                      <span className={getStatusClassNames(variant.status === Status.PUBLISHED)}>
                                        {variant.status}
                                      </span>
                                    )}
                                  />
                                </TableCell>
                                <TableCell className="VariantTable-Actions">
                                  <>
                                    <Button
                                      size="small"
                                      onClick={handleUpdateVariantButtonClick(variant.id)}
                                    >
                                      {text.edit}
                                    </Button>

                                    <Button
                                      color="error"
                                      size="small"
                                      onClick={handleDeleteVariantButtonClick(variant.id)}
                                    >
                                      {text.delete}
                                    </Button>
                                  </>
                                </TableCell>
                              </TableRow>
                            )}
                          </Draggable>
                        ),
                      )}
                      {provided.placeholder}
                    </TableBody>
                  )}
                </StrictModeDroppable>
              ),
            )}
          </DragDropContext>
        </Table>
      </TableContainer>

      <Confirm
        isOpen={deleteWindow.isOpen}
        loading={isLoadingDeleteGroup || isLoadingDeleteVariant}
        title={deleteWindow.groupId ? text.deleteGroup : text.deleteVariant}
        content={deleteWindow.groupId ? text.areYouSureDeleteGroup : text.areYouSureDeleteVariant}
        onConfirm={deleteWindow.groupId ? handleDeleteGroupWindowConfirm : handleDeleteVariantWindowConfirm}
        onClose={handleDeleteWindowClose}
      />
    </>
  );
};

export {
  VariantTable,
};

import { ResourceName } from 'dd-cms-client/app/resources';

enum UserRole {
  ADMINISTRATOR = 'administrator',
  EDITOR = 'editor',
  MODERATOR = 'moderator',
}

const hasAdministratorPermissions = (userRole: UserRole): boolean => (
  userRole === UserRole.ADMINISTRATOR
);

const hasEditorPermissions = (userRole: UserRole): boolean => (
  hasAdministratorPermissions(userRole) || userRole === UserRole.EDITOR
);

const hasModeratorPermissions = (userRole: UserRole): boolean => (
  hasEditorPermissions(userRole) || userRole === UserRole.MODERATOR
);

const USER_PERMISSIONS = {
  [ResourceName.APP]: hasAdministratorPermissions,
  [ResourceName.BAN]: hasModeratorPermissions,
  [ResourceName.CATEGORY]: hasAdministratorPermissions,
  [ResourceName.COMMENT]: hasModeratorPermissions,
  [ResourceName.DEAL]: hasEditorPermissions,
  [ResourceName.NEWS]: hasAdministratorPermissions,
  [ResourceName.THEME]: hasAdministratorPermissions,
  [ResourceName.USER]: hasAdministratorPermissions,
  [ResourceName.USER_PROFILE]: hasModeratorPermissions,
  [ResourceName.PROMOTION]: hasAdministratorPermissions,
  [ResourceName.STICKER]: hasAdministratorPermissions,
  [ResourceName.STATIC_PAGE]: hasEditorPermissions,
};

export {
  hasAdministratorPermissions,
  hasEditorPermissions,
  hasModeratorPermissions,
  USER_PERMISSIONS,
  UserRole,
};

import React, { ChangeEvent, ReactElement, useCallback, useMemo, useState } from 'react';
import { SaveButton, useNotify, useRefresh, useUpdate } from 'react-admin';
import { useTranslation } from 'react-i18next';
import { Button, TextField } from '@mui/material';
import { ResourceName } from 'dd-cms-client/app/resources';
import { Dialog } from 'dd-cms-client/common/components/Dialog';
import { FileInput } from 'dd-cms-client/common/components/SchemaFields/components/FileInput';
import { showErrorNotification } from 'dd-cms-client/common/utils/error';
import { isValidUrl } from 'dd-cms-client/common/utils/isValidUrl';
import { getConfig } from 'dd-cms-client/config/utils/config';
import { InputName } from 'dd-cms-client/deal/LinksTab/LinkAddForm';
import { Component, Props } from './index';
import './LinkEditDialog.scss';

const LinkEditDialog: Component = ({
  linkDialog,
  setLinkDialog,
  setIsModalOpen,
  dealId,
}: Props): ReactElement => {
  const { t } = useTranslation();

  const [update, { isLoading }] = useUpdate();
  const refresh = useRefresh();
  const notify = useNotify();

  const [isDisabledUrlInput, setIsDisabledUrlInput] = useState<boolean>(false);

  const text = useMemo(
    () => ({
      cancel: t('Cancel'),
      edit: t('Edit'),
      save: t('Save'),
      title: t('Title'),
      upload: t('Upload file'),
    }),
    [t],
  );

  const handleSubmit = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      try {
        if (linkDialog.title !== '') {
          if (isValidUrl(linkDialog.url)) {
            await update(
              ResourceName.DEAL_LINKS,
              { data: { id: linkDialog.id, title: linkDialog.title, url: linkDialog.url }, id: dealId },
              { returnPromise: true },
            );
            notify('ra.notification.updated', { messageArgs: { smart_count: 1 }, type: 'info' });
          }
          e.preventDefault();
        }
      } catch (error: any) {
        showErrorNotification(error, notify);
      }
      refresh();
    },
    [refresh, linkDialog.title, linkDialog.url, linkDialog.id, update, dealId, notify],
  );

  const handleDialogClose = useCallback(
    () => {
      setLinkDialog((prevState) => ({
        ...prevState,
        isOpen: false,
      }));
      setIsModalOpen(false);
    },
    [setIsModalOpen, setLinkDialog],
  );

  const handleInputChange = useCallback(
    (e: ChangeEvent<any>) => {
      setLinkDialog((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
    },
    [setLinkDialog],
  );

  const handleResponseFileNames = useCallback(
    (fileNames: Array<string>) => {
      if (fileNames.length > 0) {
        setIsDisabledUrlInput(true);
        setLinkDialog((prevState) => ({
          ...prevState,
          url: `${getConfig('url.files')}/${fileNames[0]}`,
        }));
      } else {
        setIsDisabledUrlInput(false);
        setLinkDialog((prevState) => ({
          ...prevState,
          url: '',
        }));
      }
    }, [setLinkDialog],
  );

  return (
    <Dialog
      isOpen={linkDialog.isOpen}
      onClose={handleDialogClose}
      title={text.edit}
      content={
      <div className='LinkEditDialog-Container'>
        <form className="LinkEditDialog-Form">
          <TextField
            label={text.title}
            name={InputName.TITLE}
            value={linkDialog.title}
            onChange={handleInputChange}
            required={true}
          />
          <TextField
            label="URL"
            name={InputName.URL}
            value={linkDialog.url}
            onChange={handleInputChange}
            disabled={isDisabledUrlInput || isLoading}
            required={true}
          />

          <div className="LinkEditDialog-FormButtonsContainer">
            <SaveButton
              alwaysEnable={linkDialog.isOpen}
              type='submit'
              onClick={handleSubmit}
            />
            <Button
              color="primary"
              onClick={handleDialogClose}
              variant="outlined"
            >
              {text.cancel}
            </Button>
          </div>
        </form>

        <div>
          <FileInput
            isDisabled={false}
            label={text.upload}
            isMultiple={false}
            validation={{
              formats: [
                'pdf',
                'PDF',
              ],
              maxSize: 2048,
            }}
            getResponseFileNames={handleResponseFileNames}
          />
        </div>
      </div>
      }
    />
  );
};

export {
  LinkEditDialog,
};

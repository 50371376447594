import React, { ReactElement, useEffect } from 'react';
import {
  ArrayInput,
  BooleanInput,
  DateTimeInput,
  NumberInput,
  RadioButtonGroupInput,
  SimpleFormIterator,
  TextInput,
} from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { ColorInput } from 'dd-cms-client/common/components/Form/ColorInput';
import { dateTimeFormatter } from 'dd-cms-client/common/components/SchemaFields/utils/dateTimeFormatter';
import { useFormSpy } from 'dd-cms-client/common/hooks/useFormSpy';
import { MAIN_LANGUAGE } from 'dd-cms-client/i18n/utils/language';
import { FileInput } from './components/FileInput';
import { Password } from './components/Password';
import { RichTextEditor } from './components/RichTextEditor';
import { DEFAULT_OPTIONS, Select } from './components/Select';
import { Url } from './components/Url';
import { CommonInputProps, Component, Option, Props } from './types';
import { dateTimeParser } from './utils/dateTimeParser';
import { getValidationRules } from './utils/getValidationRules';
import './SchemaFields.scss';

enum FormFieldType {
  ARRAY_INPUT = 'arrayInput',
  BOOL = 'bool',
  COLOR = 'color',
  CUSTOM = 'custom',
  DATE_TIME = 'dateTime',
  EMAIL = 'email',
  FLOAT = 'float',
  IMAGE = 'image',
  INT = 'int',
  LABEL = 'label',
  LABEL_DATE_TIME = 'labelDateTime',
  PASSWORD = 'password',
  RADIO = 'radio',
  RTE = 'rte',
  SELECT = 'select',
  TEXT = 'text',
  URL = 'url',
}

enum FormType {
  ADD = 'add',
  EDIT = 'edit',
  INSIDE_MODAL = 'inside_modal',
}

const SchemaFields: Component = ({
  activeLanguage,
  customFieldComponent,
  customWarningRequestUrlId,
  formType,
  resource,
  schema,
}: Props): ReactElement | null => {
  useWatch(); // used to get current fields values  - required for global openFormValues
  const { t } = useTranslation();
  const { isFormDirty, setIsFormDirty, setOpenFormValues } = useFormSpy();
  const { getValues, formState: { isDirty, dirtyFields } } = useFormContext();

  const values = getValues();
  const areFieldsDirty = isDirty && Object.keys(dirtyFields).length > 0;

  useEffect(
    () => {
      if (formType === FormType.EDIT) {
        setOpenFormValues({ ...values, customWarningRequestUrlId });

        if (areFieldsDirty !== isFormDirty) {
          setIsFormDirty(areFieldsDirty);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      areFieldsDirty,
    ],
  );

  useEffect(
    () => () => {
      if (formType === FormType.ADD || formType === FormType.INSIDE_MODAL) {
        setIsFormDirty(false);
      }
    },
    [setIsFormDirty, formType],
  );

  // necessary after pass from create to edit view - values are returned without property 'fields'
  if (!values.fields && formType === FormType.EDIT) {
    return null;
  }

  if (!schema) {
    return null;
  }

  const schemaFields = schema.fields.map(
    field => {
      const isFieldDisabled = (
        field.disabled
          || (
            !field.disabled
            && !field.translatable
            && !!activeLanguage
            && activeLanguage !== MAIN_LANGUAGE
          )
      );

      //after update ra `disabled` prop doesn't work correctly (https://marmelab.com/react-admin/Inputs.html#disabled), and not every ra component support `InputProps`
      //therefore `disabledClassName` is necessary
      const disabledClassName = classNames(
        {
          'Field--Disabled': isFieldDisabled,
        },
      );

      const commonProps: CommonInputProps = {
        key: `${activeLanguage}-${field.name}`,
        label: t(field.label),
        source: (field.name === 'id') ? 'id' : `fields.${field.name}`,
        validate: getValidationRules(field.validation),
      };
      const CustomFieldComponent = customFieldComponent?.[field.name];

      switch (field.type) {
        case FormFieldType.TEXT:
          return (
            <TextInput
              {...commonProps}
              className={
                field.multiline
                  ? 'TextInput--Multiline'
                  : undefined
              }
              InputProps={{ disabled: isFieldDisabled }}
              multiline={field.multiline}
              type={field.type}
            />
          );

        case FormFieldType.EMAIL:
          return (
            <TextInput
              {...commonProps}
              InputProps={{ disabled: isFieldDisabled }}
              type="email"
              validate={
                getValidationRules({ ...field.validation, email: true })
              }
            />
          );

        case FormFieldType.PASSWORD:
          return (
            <Password
              commonInputProps={commonProps}
              key={commonProps.key}
              name={field.name}
            />
          );

        case FormFieldType.COLOR:
          return (
            <ColorInput
              {...commonProps}
              isRequired={!!field.validation?.required}
            />
          );

        case FormFieldType.RTE:
          return (
            <RichTextEditor
              {...commonProps}
              isDisabled={isFieldDisabled}
            />
          );

        case FormFieldType.URL:
          return (
            <Url
              commonInputProps={{
                ...commonProps,
                validate: (
                  isFieldDisabled
                    ? []
                    : getValidationRules({ ...field.validation, url: true })
                ),
              }}
              isDisabled={isFieldDisabled}
              key={commonProps.key}
            />
          );

        case FormFieldType.INT:
          return (
            <NumberInput
              {...commonProps}
              InputProps={{ disabled: isFieldDisabled }}
              // @ts-ignore
              onWheel={e => e.target.blur()}
              parse={(value: string) => {
                const parsedValue = Number.parseInt(value);

                if (Number.isNaN(parsedValue)) {
                  return null;
                }
                return parsedValue;
              } }
            />
          );

        case FormFieldType.FLOAT:
          return (
            <NumberInput
              {...commonProps}
              InputProps={{ disabled: isFieldDisabled }}
              // @ts-ignore
              onWheel={e => e.target.blur()}
            />
          );

        case FormFieldType.DATE_TIME:
          return (
            <DateTimeInput
              {...commonProps}
              InputProps={{ disabled: isFieldDisabled }}
              format={dateTimeFormatter}
              parse={dateTimeParser}
            />
          );

        case FormFieldType.SELECT:
          return (
            <Select
              key={commonProps.key}
              isMultiple={field.multiple}
              isRequired={field?.validation?.required}
              options={field.options || DEFAULT_OPTIONS}
              commonInputProps={commonProps}
              isDisabled={isFieldDisabled}
            />
          );

        case FormFieldType.RADIO:
          return (
            <RadioButtonGroupInput
              {...commonProps}
              className={disabledClassName}
              defaultValue={(
                field?.validation?.required
                  ? field?.options?.[0].value
                  : null
              )}
              choices={field.options as Array<Option>}
              optionText="label"
              optionValue="value"
            />
          );

        case FormFieldType.IMAGE:
          return (
            <FileInput
              isDisabled={isFieldDisabled}
              isImage={true}
              isMultiple={field.multiple}
              key={`${activeLanguage}-${field.name}`}
              label={field.label}
              name={field.name}
              validation={field.validation}
              resource={resource}
            />
          );

        case FormFieldType.ARRAY_INPUT:
          return (
            <ArrayInput
              {...commonProps}
              className={disabledClassName}
            >
              <SimpleFormIterator>
                <TextInput
                  label={field.children?.label}
                  source={field.children?.name || ''}
                  validate={getValidationRules(field.validation)}
                />
              </SimpleFormIterator>
            </ArrayInput>
          );

        case FormFieldType.CUSTOM:
          return CustomFieldComponent && (
            <CustomFieldComponent
              {...commonProps}
              isDisabled={isFieldDisabled}
            />
          );

        case FormFieldType.LABEL:
          return (
            <TextInput
              {...commonProps}
              InputProps={{ disabled: true }}
            />
          );

        case FormFieldType.LABEL_DATE_TIME:
          return (
            <DateTimeInput
              {...commonProps}
              format={dateTimeFormatter}
              InputProps={{ disabled: true }}
            />
          );

        case FormFieldType.BOOL:
          return (
            <BooleanInput
              {...commonProps}
              className={disabledClassName}
              validate={[]}
            />
          );
      }
    },
  );

  return (
    <div className="SchemaFields">
      {schemaFields}
    </div>
  );
};

SchemaFields.FormType = FormType;

export {
  FormFieldType,
  FormType,
  SchemaFields,
};

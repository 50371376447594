import React, { ReactElement } from 'react';
import { Create, SimpleForm } from 'react-admin';
import { ResourceName } from 'dd-cms-client/app/resources';
import { DataLoader } from 'dd-cms-client/common/components/DataLoader';
import { FormToolbar } from 'dd-cms-client/common/components/Form/FormToolbar';
import { SchemaFields } from 'dd-cms-client/common/components/SchemaFields';
import { useForm } from 'dd-cms-client/common/hooks/useForm';
import { RequestType } from 'dd-cms-client/common/utils/request';
import { SchemaType } from 'dd-cms-client/common/utils/schema';
import { useThemeManifest } from './hooks/useThemeManifest';
import { ManifestFieldGroup } from './ManifestFieldGroup';
import { mapThemeManifestToForm } from './utils/mapThemeManifestToForm';

const ThemeCreate = (): ReactElement => {
  const {
    data: themeManifest,
    error: themeManifestError,
  } = useThemeManifest();
  const { values } = mapThemeManifestToForm(themeManifest);
  const {
    data,
    dataLoaderProps,
    onSubmit,
  } = useForm(
    ResourceName.THEME,
    SchemaType.CREATE,
    RequestType.CREATE,
  );

  return (
    <DataLoader
      error={dataLoaderProps.error || themeManifestError}
      isError={dataLoaderProps.isError || !!themeManifestError}
      isLoading={dataLoaderProps.isLoading || !themeManifest}
      isSuccess={dataLoaderProps.isSuccess && !!themeManifest}
    >
      <Create>
        <SimpleForm
          mode="onBlur"
          defaultValues={{
            fields: {
              manifest: values,
            },
          }}
          onSubmit={onSubmit}
          toolbar={<FormToolbar />}
        >
          <SchemaFields
            customFieldComponent={{
              manifest: ManifestFieldGroup,
            }}
            schema={data}
            formType={SchemaFields.FormType.ADD}
            resource={ResourceName.THEME}
          />
        </SimpleForm>
      </Create>
    </DataLoader>
  );
};

export {
  ThemeCreate,
};

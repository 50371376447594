import React, { ReactElement, useCallback, useMemo } from 'react';
import { SaveButton, useNotify, useRefresh, useUpdate } from 'react-admin';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { ResourceName } from 'dd-cms-client/app/resources';
import { DataLoader } from 'dd-cms-client/common/components/DataLoader';
import { SchemaFields } from 'dd-cms-client/common/components/SchemaFields';
import { TranslatableSection } from 'dd-cms-client/common/components/TranslatableSection';
import { GlobalStateKey, useGlobalState } from 'dd-cms-client/common/globalState';
import { useForm } from 'dd-cms-client/common/hooks/useForm';
import { showErrorNotification } from 'dd-cms-client/common/utils/error';
import { SchemaType } from 'dd-cms-client/common/utils/schema';
import { getSelectedVariantGroup } from 'dd-cms-client/deal/VariantTab/utils/getSelectedVariantGroup';
import { MAIN_LANGUAGE } from 'dd-cms-client/i18n/utils/language';
import { Component, Props } from './';

const UpdateGroup: Component = ({
  editControllerProps,
  onDialogClose,
  variants,
  variantGroupId,
}: Props): ReactElement => {
  const { t } = useTranslation();
  const [update] = useUpdate();
  const refresh = useRefresh();
  const notify = useNotify();
  const [openedLanguageTab] = useGlobalState(GlobalStateKey.OPENED_LANGUAGE_TAB);
  const { record } = editControllerProps;

  const text = useMemo(
    () => ({
      cancel: t('Cancel'),
      create: t('Create'),
      createGroupLabel: t('Create new group label'),
      somethingWentWrong: t('Something went wrong, please try again.'),
    }),
    [t],
  );

  const {
    data,
    dataLoaderProps,
  } = useForm(
    ResourceName.DEAL_VARIANT_GROUP,
    SchemaType.EDIT,
  );

  const handleSubmit = useCallback(
    async (values: any) => {
      const { fields, id } = values;

      try {
        await update(
          ResourceName.DEAL_VARIANT_GROUP,
          { data: { ...fields, id }, id: record?.id },
          { returnPromise: true },
        );
        notify('ra.notification.updated', { messageArgs: { smart_count: 1 }, type: 'info' });
      } catch (error: any) {
        showErrorNotification(error, notify);
      }

      onDialogClose();
      refresh();
    },
    [onDialogClose, refresh, update, record?.id, notify],
  );

  const getRecord = useCallback(
    () => {
      const selectedVariantGroup = getSelectedVariantGroup(variants, variantGroupId);
      const name = openedLanguageTab !== MAIN_LANGUAGE
        ? selectedVariantGroup?.languages[openedLanguageTab].name
        : selectedVariantGroup?.name;

      return (
        {
          fields: {
            name,
          },
          id: selectedVariantGroup?.id,
        }
      );
    },
    [openedLanguageTab, variantGroupId, variants],
  );

  return (
    <DataLoader {...dataLoaderProps}>
      <TranslatableSection
        resource={ResourceName.DEAL_VARIANT_GROUP}
        modifiedEditControllerProps={{
          ...editControllerProps,
          record: getRecord(),
        }}
        hasToolbar={false}
        onSubmit={handleSubmit}
      >
        {activeLanguage => (
          <>
            <SchemaFields
              activeLanguage={activeLanguage}
              formType={SchemaFields.FormType.INSIDE_MODAL}
              resource={ResourceName.DEAL_VARIANT_GROUP}
              schema={data}
            />
            <div className="Dialog-ContentActions">
              <SaveButton />

              <Button
                color="primary"
                onClick={onDialogClose}
                variant="outlined"
              >
                {text.cancel}
              </Button>
            </div>
          </>
        )}
      </TranslatableSection>
    </DataLoader>
  );
};

export {
  UpdateGroup,
};

import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';
import germanMessages from 'ra-language-german';
import { LanguageCode } from 'dd-cms-client/i18n/utils/languageCode';

interface I18nProvider {
  changeLocale: (locale: string) => Promise<void>;
  getLocale: () => string;
  translate: TranslateFunction;
}

const createI18nProvider = (
  language: string,
): I18nProvider => {
  const messages = {
    de: germanMessages,
    en: englishMessages,
  };

  return polyglotI18nProvider(
    lang => (
      messages[lang] || messages[LanguageCode.EN]
    ),
    language,
    { allowMissing: true },
  );
};

export {
  createI18nProvider,
};

import { useCallback, useEffect } from 'react';
import {
  useCreate,
  useNotify,
  useRedirect,
  useRefresh,
  useUpdate,
} from 'react-admin';
import { ResourceName } from 'dd-cms-client/app/resources';
import { getTenant } from 'dd-cms-client/auth/utils/tenant';
import { Props as DataLoaderProps } from 'dd-cms-client/common/components/DataLoader';
import { SchemaResponse } from 'dd-cms-client/common/components/SchemaFields';
import { useRequest } from 'dd-cms-client/common/hooks/useRequest';
import { showErrorNotification } from 'dd-cms-client/common/utils/error';
import { Header, RequestType } from 'dd-cms-client/common/utils/request';
import { getSchemaUrl, SchemaType } from 'dd-cms-client/common/utils/schema';

interface UseFormType {
  data: SchemaResponse | null;
  dataLoaderProps: Omit<DataLoaderProps, 'children'>;
  onSubmit: (values: any) => Promise<any>;
}

const useForm = (
  resourceName: ResourceName,
  schemaType: SchemaType,
  requestType?: RequestType,
  shouldMakeRequest = true,
): UseFormType => {
  const redirectTo = useRedirect();
  const notify = useNotify();
  const refresh = useRefresh();
  const tenant = getTenant();
  const [create] = useCreate();
  const [update] = useUpdate();

  const { data, makeRequest, ...dataLoaderProps } = useRequest(
    getSchemaUrl(schemaType, resourceName),
    'get',
    {
      [Header.TENANT]: tenant,
    },
  );

  const onSubmit = useCallback(
    async (values: any) => {
      if (!requestType) {
        return;
      }

      try {
        switch (requestType) {
          case RequestType.CREATE: {
            const response = await create(resourceName, { data: values.fields }, { returnPromise: true });
            notify('ra.notification.created', { messageArgs: { smart_count: 1 }, type: 'info' });
            redirectTo('edit', resourceName, response.id);
            break;
          }
          case RequestType.UPDATE: {
            const { fields, id } = values;
            await update(resourceName, { data: { ...fields, id }, id }, { returnPromise: true });
            notify('ra.notification.updated', { messageArgs: { smart_count: 1 }, type: 'info' });
            refresh();
            break;
          }
        }
      } catch (error: any) {
        showErrorNotification(error, notify);

        if (error?.body?.errors) {
          return ({
            fields: error.body.errors,
          });
        }
      }
    },
    [create, notify, redirectTo, refresh, requestType, resourceName, update],
  );

  useEffect(
    () => {
      shouldMakeRequest ? makeRequest() : undefined;
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  return {
    data,
    dataLoaderProps,
    onSubmit,
  };
};

export {
  useForm,
};

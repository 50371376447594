import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { Confirm, useEditController, useNotify, useRefresh, useUpdate } from 'react-admin';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DroppableProvided,
  DropResult,
} from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { SwapVert } from '@mui/icons-material';
import {
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { API_ENDPOINTS } from 'dd-cms-client/app/dataProvider';
import { ResourceName } from 'dd-cms-client/app/resources';
import { getTenant } from 'dd-cms-client/auth/utils/tenant';
import { StrictModeDroppable } from 'dd-cms-client/common/components/StrictModeDroppable';
import { getGlobalState, GlobalStateKey } from 'dd-cms-client/common/globalState';
import { useRequest } from 'dd-cms-client/common/hooks/useRequest';
import { showErrorNotification } from 'dd-cms-client/common/utils/error';
import { Header } from 'dd-cms-client/common/utils/request';
import { LinkDialogState, LinkItem, Links } from 'dd-cms-client/deal/LinksTab';
import { LinkEditDialog } from 'dd-cms-client/deal/LinksTab/LinksList/LinkEditDialog';
import { Component, Props } from './';
import './LinksList.scss';

const LinksList: Component = ({ setIsModalOpen }: Props): ReactElement | null => {
  const { t } = useTranslation();
  const notify = useNotify();
  const tenant = getTenant();
  const editControllerProps = useEditController();
  const refresh = useRefresh();
  const [update, { isLoading: isChangeOrderLoading }] = useUpdate();
  const { record } = editControllerProps;

  const linksRecords = useMemo(
    () => (
      record?.fields.links || []
    ),
    [record?.fields.links],
  );

  const [links, setLinks] = useState<Links>(linksRecords);

  const [isDeleteWindowOpen, setIsDeleteWindowOpen] = useState<boolean>(false);
  const [itemDeleted, setItemDeleted] = useState<string>('');
  const [editedItemDialog, setEditedItemDialog] = useState<LinkDialogState>({
    id: '',
    isOpen: false,
    title: '',
    url: '',
  });

  const openedLanguageTab = getGlobalState(GlobalStateKey.OPENED_LANGUAGE_TAB);

  const {
    makeRequest: deleteLinkRequest,
    isLoading: isLoadingDeleteLink,
    error: errorDeleteLink,
    isError: isErrorDeleteLink,
    isSuccess: isSuccessDeleteLink,
  } = useRequest(
    `${API_ENDPOINTS[ResourceName.DEAL_LINKS]}/${ResourceName.DEAL_LINKS}/${record.id}`,
    'delete',
    {
      [Header.LANGUAGE]: openedLanguageTab,
      [Header.TENANT]: tenant,
    },
  );

  useEffect(
    () => {
      setLinks(linksRecords);
      if (isSuccessDeleteLink) {
        notify('ra.notification.deleted', { messageArgs: { smart_count: 1 }, type: 'info' });
      }
      if (isErrorDeleteLink) {
        showErrorNotification(errorDeleteLink, notify);
      }
    },
    [linksRecords, errorDeleteLink, isErrorDeleteLink, isSuccessDeleteLink, notify],
  );

  const text = useMemo(
    () => ({
      areYouSureDeleteLink: t('Are you sure you want to delete link?'),
      cancel: t('Cancel'),
      delete: t('Delete'),
      edit: t('Edit'),
      title: t('Title'),
    }),
    [t],
  );

  const handleDeleteButtonClick = useCallback(
    (id: string) => () => {
      id && setItemDeleted(id);
      setIsDeleteWindowOpen(true);
    }, [],
  );

  const handleDeleteWindowClose = useCallback(
    () => {
      setItemDeleted('');
      setIsDeleteWindowOpen(false);
    }, [],
  );

  const handleDeleteLinkConfirm = useCallback(
    () => {
      setIsDeleteWindowOpen(false);
      deleteLinkRequest({ body: { id: itemDeleted } });
    }, [deleteLinkRequest, itemDeleted],
  );

  const handleEditButtonClick = useCallback(
    (linkItem: LinkItem) => () => {
      setIsModalOpen(true);
      setEditedItemDialog({
        id: linkItem.id,
        isOpen: true,
        title: linkItem.title,
        url: linkItem.url,
      });
    }, [setIsModalOpen],
  );

  const changeOrder = useCallback(
    async (links: Links) => {
      try {
        await update(
          ResourceName.DEAL_LINKS,
          { data: { links }, id: `${record.id}/sort` },
          { returnPromise: true },
        );
        notify('ra.notification.updated', { messageArgs: { smart_count: 1 }, type: 'info' });
        setLinks(links);
      } catch (error: any) {
        showErrorNotification(error, notify);
      }

      refresh();
    },
    [refresh, update, record.id, notify],
  );

  const handleDragEnd = useCallback(
    (result: DropResult) => {
      const newItems = [...links];
      const [removed] = newItems.splice(result.source.index, 1);

      if (result.destination) {
        newItems.splice(result.destination.index, 0, removed);
      }

      changeOrder(newItems);
    },
    [changeOrder, links],
  );

  if (linksRecords.length === 0) {
    return null;
  }

  return (
    <>
      {isChangeOrderLoading && (
        <div className="LinksList-Loader">
          <CircularProgress />
        </div>
      )}

      {!isChangeOrderLoading && (
        <TableContainer>
          <Table>
            <DragDropContext onDragEnd={handleDragEnd}>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>{text.title}</TableCell>
                  <TableCell>URL</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>

              <StrictModeDroppable droppableId="droppable">
                {(provided: DroppableProvided) => (
                  <TableBody
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {links.map(
                      (item: LinkItem, index: number) => (
                        <Draggable
                          key={item.id}
                          draggableId={`${item.id}`}
                          index={index}
                        >
                          {(provided: DraggableProvided) => (
                            <TableRow
                              key={item.id}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <TableCell className="LinksList-IconCell">
                                <SwapVert />
                              </TableCell>

                              <TableCell className="LinksList-TitleCell">
                                {item.title}
                              </TableCell>

                              <TableCell className="LinksList-UrlCell">
                                <a
                                  className="LinksList-UrlLink"
                                  href={item.url}
                                  target='_blank'
                                  rel="noreferrer"
                                >
                                  {item.url}
                                </a>
                              </TableCell>

                              <TableCell className="LinksList-ActionCell">
                                <Button
                                  color="info"
                                  size="small"
                                  onClick={handleEditButtonClick(item)}
                                >
                                  {text.edit}
                                </Button>
                                <Button
                                  color="error"
                                  size="small"
                                  onClick={handleDeleteButtonClick(item.id)}
                                >
                                  {text.delete}
                                </Button>
                              </TableCell>
                            </TableRow>
                          )}
                        </Draggable>
                      ),
                    )}
                  {provided.placeholder}
                </TableBody>
                )}
              </StrictModeDroppable>
            </DragDropContext>
          </Table>
        </TableContainer>
      )}

      <Confirm
        isOpen={isDeleteWindowOpen}
        loading={isLoadingDeleteLink}
        title={text.delete}
        content={text.areYouSureDeleteLink}
        onConfirm={handleDeleteLinkConfirm}
        onClose={handleDeleteWindowClose}
      />

      <LinkEditDialog
        dealId={record.id}
        setLinkDialog={setEditedItemDialog}
        linkDialog={editedItemDialog}
        setIsModalOpen={setIsModalOpen}
      />
    </>
  );
};

export {
  LinksList,
};

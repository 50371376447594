import React, { ReactElement, useMemo } from 'react';
import { useEditController } from 'react-admin';
import { ResourceName } from 'dd-cms-client/app/resources';
import { TranslatableSection } from 'dd-cms-client/common/components/TranslatableSection';
import { AccessoriesList } from 'dd-cms-client/deal/AccessoriesTab/AccessoriesList';
import { Component } from './';

const AccessoriesTab: Component = (): ReactElement => {
  const editControllerProps = useEditController();
  const { record } = editControllerProps;

  const customAccessories = useMemo(
    () => record?.fields ? record.fields.customAccessories : [],
    [record.fields],
  );

  const importedAccessories = useMemo(
    () => record?.fields ? record.fields.importedAccessories : [],
    [record.fields],
  );

  return (
    <>
      <TranslatableSection
        key="accessories"
        resource={ResourceName.DEAL_ACCESSORY_PRODUCT}
      >
        {() => (
          <AccessoriesList
            dealId={record.id}
            accessories={customAccessories}
            accessoriesImported={importedAccessories}
          />
        )}
      </TranslatableSection>
    </>
  );
};

export {
  AccessoriesTab,
};

import { NotFound } from 'react-admin';
import { ResourceName } from 'dd-cms-client/app/resources';
import { hasUserAndTenantPermission } from 'dd-cms-client/auth/utils/permission';
import { Tenant } from 'dd-cms-client/auth/utils/tenant';
import { UserRole } from 'dd-cms-client/auth/utils/userRole';
import { NewsCreate } from './NewsCreate';
import { NewsEdit } from './NewsEdit';
import { NewsList } from './NewsList';

const news = (userRole: UserRole, tenant: Tenant): Record<string, any> => {
  const hasPermissions = hasUserAndTenantPermission(ResourceName.NEWS, userRole, tenant);

  return {
    create: hasPermissions ? NewsCreate : NotFound,
    edit: hasPermissions ? NewsEdit : NotFound,
    list: hasPermissions ? NewsList : NotFound,
  };
};

export {
  news,
};

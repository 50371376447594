import { NotFound } from 'react-admin';
import { ResourceName } from 'dd-cms-client/app/resources';
import { hasUserAndTenantPermission } from 'dd-cms-client/auth/utils/permission';
import { Tenant } from 'dd-cms-client/auth/utils/tenant';
import { UserRole } from 'dd-cms-client/auth/utils/userRole';
import { PromotionCreate } from 'dd-cms-client/promotion/PromotionCreate';
import { PromotionEdit } from 'dd-cms-client/promotion/PromotionEdit';
import { PromotionList } from 'dd-cms-client/promotion/PromotionList';

const promotion = (userRole: UserRole, tenant: Tenant): Record<string, any> => {
  const hasPermissions = hasUserAndTenantPermission(ResourceName.PROMOTION, userRole, tenant);

  return {
    create: hasPermissions ? PromotionCreate : NotFound,
    edit: hasPermissions ? PromotionEdit : NotFound,
    list: hasPermissions ? PromotionList : NotFound,
  };
};

export {
  promotion,
};

import React, {
  ReactElement, useCallback,
  useMemo,
} from 'react';
import { Draggable, DraggableProvided } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { SwapVert } from '@mui/icons-material';
import {
  Button,
  TableCell,
  TableRow,
} from '@mui/material';
import { Accessory } from 'dd-cms-client/deal/AccessoriesTab';
import { Component, Props } from './';
import './AccessoryItem.scss';

const AccessoryItem: Component = ({
  accessoriesGroup,
  setItemToDelete,
  setWindowConfirmOpen,
}: Props): ReactElement => {
  const { t } = useTranslation();

  const text = useMemo(
    () => ({
      delete: t('Delete'),
    }),
    [t],
  );

  const getAccessoryName = useCallback(
    (accessory: Accessory): string => {
      if (accessory.name) {
        return `<strong>${accessory.name}</strong>`;
      }
      return '';
    },
    [],
  );

  const handleDeleteAccessoryButton = useCallback(
    (accessory: Accessory) => () => {
      setItemToDelete(accessory);
      setWindowConfirmOpen(true);
    }, [setItemToDelete, setWindowConfirmOpen],
  );

  return (
    <>
      {accessoriesGroup.products.map(
        (accessory: Accessory, index: number) => (
          <Draggable
            key={accessory.sku}
            draggableId={`${accessory.sku}`}
            index={index}
          >
            {(provided: DraggableProvided) => (
              <TableRow
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <TableCell className="AccessoryItem-IconCell">
                  <SwapVert />
                </TableCell>
                <TableCell
                  dangerouslySetInnerHTML={
                    { __html: getAccessoryName(accessory) }
                  }
                />
                <TableCell className="AccessoryItem-IdCell">
                  {accessory.sku}
                </TableCell>
                <TableCell>
                  <Button
                    className="AccessoryItem-DeleteButton"
                    color="error"
                    size="small"
                    onClick={handleDeleteAccessoryButton(accessory)}
                  >
                    {text.delete}
                  </Button>
                </TableCell>
              </TableRow>
            )}
          </Draggable>
        ),
      )}
    </>
  );
};

export {
  AccessoryItem,
};

import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useNotify, useRefresh, useUpdate } from 'react-admin';
import { CircularProgress } from '@mui/material';
import { ResourceName } from 'dd-cms-client/app/resources';
import { showErrorNotification } from 'dd-cms-client/common/utils/error';
import { Accessories } from '../';
import { AccessoriesTable } from './AccessoriesTable';
import { Component, Props } from './';
import './AccessoriesList.scss';

const AccessoriesList: Component = ({
  dealId,
  accessories: accessoriesProp,
  accessoriesImported,
}: Props): ReactElement | null => {
  const notify = useNotify();
  const [update, { isLoading: isChangeOrderLoading }] = useUpdate();
  const refresh = useRefresh();
  const [accessories, setAccessories] = useState<Accessories>(accessoriesProp);

  const changeAccessories = useCallback(
    async (accessories: Accessories) => {
      try {
        await update(
          ResourceName.DEAL_ACCESSORY_PRODUCT,
          { data: { customAccessories: accessories }, id: `${dealId}` },
          { returnPromise: true },
        );
        notify('ra.notification.updated', { messageArgs: { smart_count: 1 }, type: 'info' });
        setAccessories(accessories);
      } catch (error: any) {
        showErrorNotification(error, notify);
      }

      refresh();
    },
    [refresh, update, dealId, notify],
  );

  useEffect(
    () => {
      setAccessories(accessoriesProp);
    },
    [accessoriesProp],
  );

  return (
    <>
      {isChangeOrderLoading && (
        <div className="AccessoriesList-Loader">
          <CircularProgress />
        </div>
      )}

      {!isChangeOrderLoading && (
        <AccessoriesTable
          accessories={accessories}
          accessoriesImported={accessoriesImported}
          changeAccessories={changeAccessories}
        />
      )}
    </>
  );
};

export {
  AccessoriesList,
};

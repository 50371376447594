import { maxLength, maxValue, minValue, regex, required } from 'react-admin';
import i18nextInit from 'dd-cms-client/i18n/i18nextInit';
import { Validation } from '../types';

const getValidationRules = (validation?: Validation): Array<any> => {
  const validationRules = [];
  const { t } = i18nextInit;
  const text = {
    fieldMustBeEmail: t('Field must be a valid email address.'),
    fieldMustBeUrl: t('Field must be a valid url.'),
  };

  if (validation?.required) {
    validationRules.push(required());
  }

  if (validation?.maxLength) {
    validationRules.push(maxLength(validation.maxLength));
  }

  if (validation?.maxValue) {
    validationRules.push(maxValue(validation.maxValue));
  }

  if (validation?.minValue) {
    validationRules.push(minValue(validation.minValue));
  }

  if (validation?.regex) {
    validationRules.push(regex(new RegExp(validation.regex.pattern.slice(1, -1)), validation.regex.text));
  }

  //TODO custom regex rules should be provided by backend
  if (validation?.url) {
    validationRules.push(
      regex(
        /^(http(s)?:\/\/)(www.)?([a-zA-Z0-9])+([-.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,5}(:[0-9]{1,5})?(\/[^\s]*)?$/,
        text.fieldMustBeUrl,
      ),
    );
  }

  if (validation?.email) {
    validationRules.push(
      regex(
        /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/,
        text.fieldMustBeEmail,
      ),
    );
  }

  return validationRules;
};

export {
  getValidationRules,
};

import React, { ReactElement, SyntheticEvent, useCallback, useEffect, useMemo } from 'react';
import { Button, useNotify, useRedirect } from 'react-admin';
import { useTranslation } from 'react-i18next';
import { API_ENDPOINTS } from 'dd-cms-client/app/dataProvider';
import { getTenant } from 'dd-cms-client/auth/utils/tenant';
import { useRequest } from 'dd-cms-client/common/hooks/useRequest';
import { showErrorNotification } from 'dd-cms-client/common/utils/error';
import { Header } from 'dd-cms-client/common/utils/request';
import { Component, Props } from './';

const ACTION_REQUEST_SUFFIX = '_duplicate';

const DuplicateButton: Component = ({
  record,
  resourceName,
}: Props): ReactElement => {
  const { t } = useTranslation();
  const redirectTo = useRedirect();
  const notify = useNotify();
  const tenant = getTenant();

  const { data, error, isError, isSuccess, makeRequest } = useRequest(
    `${API_ENDPOINTS[resourceName]}/${resourceName}${ACTION_REQUEST_SUFFIX}/${record.id}`,
    'post',
    {
      [Header.TENANT]: tenant,
    },
  );

  const text = useMemo(
    () => ({
      duplicate: t('Duplicate'),
    }),
    [t],
  );

  const handleDuplicate = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation();
      makeRequest();
    },
    [makeRequest],
  );

  useEffect(
    () => {
      if (isSuccess) {
        notify('ra.notification.created', { messageArgs: { smart_count: 1 }, type: 'info' });
        // @ts-ignore
        redirectTo('edit', resourceName, data.id);
      }

      if (isError) {
        showErrorNotification(error, notify);
      }
    },
    [data, error, isError, isSuccess, notify, redirectTo, resourceName],
  );

  return (
    <Button
      label={text.duplicate}
      onClick={handleDuplicate}
      size="small"
    />
  );
};

export {
  DuplicateButton,
};

import i18nextInit from 'dd-cms-client/i18n/i18nextInit';

enum ResourceName {
  APP = 'app',
  ALL_CATEGORIES = 'all_categories',
  ALL_DEALS = 'all_deals',
  BAN = 'ban',
  CATEGORY = 'category',
  COMMENT = 'comment',
  DEAL = 'deal',
  DEAL_APP = 'deal_app',
  DEAL_ACCESSORY_PRODUCT = 'deal_accessory_product',
  DEAL_DELIVERY = 'deal_delivery',
  DEAL_DESCRIPTION = 'deal_description',
  DEAL_DETAIL = 'deal_detail',
  DEAL_LINKS = 'deal_link',
  DEAL_MEDIA = 'deal_media',
  DEAL_RECOMMENDED_PRODUCT = 'deal_recommended_product',
  DEAL_SPECIFICATION = 'deal_specification',
  DEAL_STICKER = 'deal_sticker',
  DEAL_TAG = 'deal_tag',
  DEAL_VARIANT = 'deal_variant',
  DEAL_VARIANT_GROUP = 'deal_variant_group',
  NEWS = 'news',
  THEME = 'theme',
  USER = 'user',
  USER_PROFILE = 'user_profile',
  PROMOTION = 'promotion',
  STICKER = 'sticker',
  STATIC_PAGE = 'page',
}

const { t } = i18nextInit;

const resourceLabel = {
  apps: t('apps'),
  bans: t('bans'),
  categories: t('categories'),
  deal: t('deals'),
  pages: t('pages'),
  promotions: t('promotions'),
  stickers: t('stickers'),
  themes: t('themes'),
  users: t('users'),
};

const GLOBAL_RESOURCES = [ResourceName.USER];

const NON_DELETABLE_RESOURCES: Array<ResourceName | undefined> = [
  ResourceName.CATEGORY,
  ResourceName.DEAL_VARIANT,
  ResourceName.DEAL_STICKER,
  ResourceName.USER_PROFILE,
];

export {
  GLOBAL_RESOURCES,
  NON_DELETABLE_RESOURCES,
  resourceLabel,
  ResourceName,
};

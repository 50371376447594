import React, {
  ReactElement,
  useMemo,
} from 'react';
import { Edit } from 'react-admin';
import { useTranslation } from 'react-i18next';
import { ResourceName } from 'dd-cms-client/app/resources';
import { DataLoader } from 'dd-cms-client/common/components/DataLoader';
import { SchemaFields } from 'dd-cms-client/common/components/SchemaFields';
import { TabsSection } from 'dd-cms-client/common/components/TabsSection';
import { TranslatableSection } from 'dd-cms-client/common/components/TranslatableSection';
import { useForm } from 'dd-cms-client/common/hooks/useForm';
import { RequestType } from 'dd-cms-client/common/utils/request';
import { SchemaType } from 'dd-cms-client/common/utils/schema';

const StickersEdit = (): ReactElement => {
  const { t } = useTranslation();

  const {
    data,
    dataLoaderProps,
  } = useForm(
    ResourceName.STICKER,
    SchemaType.EDIT,
    RequestType.UPDATE,
  );

  const text = useMemo(
    () => ({
      stickers: t('Stickers'),
    }),
    [t],
  );

  return (
    <DataLoader {...dataLoaderProps}>
      <Edit>
        <TabsSection
          tabPanels={[
            <TranslatableSection
              key='main'
              resource={ResourceName.STICKER}
            >
              {(activeLanguage) => (
                <SchemaFields
                  activeLanguage={activeLanguage}
                  schema={data}
                  formType={SchemaFields.FormType.EDIT}
                  resource={ResourceName.STICKER}
                />
              )}
            </TranslatableSection>,
          ]}
          tabs={[{ label: text.stickers }]}
        />
      </Edit>
    </DataLoader>
  );
};

export {
  StickersEdit,
};

import React, { ChangeEvent, ReactElement, useCallback, useMemo, useState } from 'react';
import { Button } from 'react-admin';
import { useTranslation } from 'react-i18next';
import { TextField } from '@mui/material';
import { disableSubmitForEnterKey } from 'dd-cms-client/common/utils/form';
import { Component, Props } from './';

const VideoInput: Component = ({
  addVideoId,
}: Props): ReactElement => {
  const { t } = useTranslation();
  const [videoId, setVideoId] = useState('');

  const text = useMemo(
    () => ({
      add: t('Add'),
      addVideo: t('Add a video Id'),
    }),
    [t],
  );

  const handleVideoIdChange = (event: ChangeEvent<HTMLInputElement>) => {
    setVideoId(event.target.value);
  };

  const handleAddVideoClick = useCallback(
    () => {
      addVideoId(videoId);
      setVideoId('');
    },
    [addVideoId, videoId],
  );

  return (
    <>
      <TextField
        label={text.addVideo}
        onChange={handleVideoIdChange}
        value={videoId}
        onKeyPress={disableSubmitForEnterKey}
      />

      <Button
        label={text.add}
        onClick={handleAddVideoClick}
        variant="outlined"
      />
    </>
  );
};

export {
  VideoInput,
};

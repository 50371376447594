import { NotFound } from 'react-admin';
import { ResourceName } from 'dd-cms-client/app/resources';
import { hasUserAndTenantPermission } from 'dd-cms-client/auth/utils/permission';
import { Tenant } from 'dd-cms-client/auth/utils/tenant';
import { UserRole } from 'dd-cms-client/auth/utils/userRole';
import { CommentList } from './CommentList';
import { CommentThread } from './CommentThread';

const comment = (userRole: UserRole, tenant: Tenant): Record<string, any> => {
  const hasPermissions = hasUserAndTenantPermission(ResourceName.COMMENT, userRole, tenant);

  return {
    edit: hasPermissions ? CommentThread : NotFound,
    list: hasPermissions ? CommentList : NotFound,
  };
};

export {
  comment,
};

import React, { ChangeEvent, ReactElement, useCallback, useEffect } from 'react';
import { useEditController, useRefresh } from 'react-admin';
import { GlobalStateKey, useGlobalState } from 'dd-cms-client/common/globalState';
import { useFormSpy } from 'dd-cms-client/common/hooks/useFormSpy';
import { getValidLanguages, MAIN_LANGUAGE } from 'dd-cms-client/i18n/utils/language';
import { LanguageCode } from 'dd-cms-client/i18n/utils/languageCode';
import { TabPanels } from './TabPanels';
import { Tabs } from './Tabs';
import { Component, Props } from './types';

const TranslatableSection: Component = ({
  children,
  hasToolbar,
  modifiedEditControllerProps,
  ownToolbar,
  onSubmit,
  resource,
  isTranslatableFrozen,
}: Props): ReactElement => {
  const [
    openedLanguageTab,
    setOpenedLanguageTab,
  ] = useGlobalState(GlobalStateKey.OPENED_LANGUAGE_TAB);
  const { isFormDirty, onFormDirtyConfirm, setIsFormDirty } = useFormSpy(resource);
  const editControllerProps = useEditController();
  const refresh = useRefresh();

  const languages = getValidLanguages(
    modifiedEditControllerProps?.record?.activeLanguages
      || editControllerProps.record?.activeLanguages,
  );

  useEffect(
    () => () => setOpenedLanguageTab(MAIN_LANGUAGE),
    [setOpenedLanguageTab],
  );

  const handleChangeTab = useCallback(
    async (event: ChangeEvent<any>, language: LanguageCode): Promise<any> => {
      if (!isFormDirty || await onFormDirtyConfirm()) {
        setOpenedLanguageTab(language);
        setIsFormDirty(false);
        refresh();
      }
    },
    [isFormDirty, onFormDirtyConfirm, refresh, setOpenedLanguageTab, setIsFormDirty],
  );

  return (
    <>
      <Tabs
        activeLanguage={openedLanguageTab}
        languages={languages}
        onChangeTab={handleChangeTab}
        isTranslatableFrozen={isTranslatableFrozen}
      />
      <TabPanels
        activeLanguage={openedLanguageTab}
        content={children}
        editControllerProps={modifiedEditControllerProps || editControllerProps}
        hasToolbar={hasToolbar}
        languages={languages}
        onSubmit={onSubmit}
        ownToolbar={ownToolbar}
        resource={resource}
      />
    </>
  );
};


export {
  TranslatableSection,
};

import React, { ReactElement } from 'react';
import { TabPanel } from './TabPanel';
import { Component, Props } from './types';

const TabPanels: Component = ({
  activeTab,
  tabLabels,
  tabPanels,
}: Props): ReactElement => (
  <>
    {
      tabPanels.map(
        (tabPanel, index) => (
          <TabPanel
            activeTab={activeTab}
            key={tabLabels[index]}
            tab={tabLabels[index]}
          >
            {tabPanel}
          </TabPanel>
        ),
      )
    }
  </>
);

export {
  TabPanels,
};

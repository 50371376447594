import { camelCase, pascalCase } from 'change-case';
import get from 'lodash.get';
import isObject from 'lodash.isobject';
import isString from 'lodash.isstring';
import validateColor from 'validate-color';
import { processValue } from './processValue';

interface FormField {
  config: ThemeManifestValueConfig | null;
  name: string;
}

interface FormFields {
  colors: Array<FormField>;
  designTokens: Array<FormField>;
}

interface Form {
  fields: FormFields;
  values: Record<string, string>;
}

const DESIGN_TOKEN_GROUP_PREFIX = '_';

let mapDesignTokensToFormFieldsKeys: Array<string> = [];
let colorFormFields: Array<FormField> = [];
let designTokenFormFields: Array<FormField> = [];
let formValues: Record<string, string> = {};

const convertKeysToFormFieldName = (keys: Array<string>): string => (
  camelCase(keys.map(key => pascalCase(key)).join(''))
);

const convertValueToFormFieldName = (value: string): string => {
  const formFieldNameParts = value.split('.').map(value => pascalCase(value));

  formFieldNameParts.shift();

  return camelCase(formFieldNameParts.join(''));
};

const getThemeManifestValue = (themeManifest: ThemeManifest) => (
  // eslint-disable-next-line react/display-name
  (value: ThemeManifestValue): string | null => {
    if (isString(value) && validateColor(value)) {
      return value;
    }

    if (isString(value)) {
      return get(themeManifest, value);
    }

    if (isObject(value)) {
      const { defaultValue, process } = value;
      return processValue(get(themeManifest, defaultValue), process);
    }

    return null;
  }
);

const getThemeManifestValueConfig = (
  value: ThemeManifestValue,
): ThemeManifestValueConfig | null => {
  if (isString(value) && !validateColor(value)) {
    return {
      defaultValue: convertValueToFormFieldName(value),
    };
  }

  if (isObject(value)) {
    const { defaultValue, process } = value;

    return {
      defaultValue: convertValueToFormFieldName(defaultValue),
      process,
    };
  }

  return null;
};

const mapColorsToForm = (themeManifest: ThemeManifest) => (
  ([key, value]: [string, string]): void => {
    const formFieldName = convertKeysToFormFieldName([key]);

    colorFormFields.push({
      config: getThemeManifestValueConfig(value),
      name: formFieldName,
    });

    const themeManifestValue = getThemeManifestValue(themeManifest)(value);

    if (themeManifestValue) {
      formValues[formFieldName] = themeManifestValue;
    }
  }
);

const mapDesignTokensToForm = (themeManifest: ThemeManifest) => (
  ([key, value]: [string, string]): void => {
    if (key.startsWith(DESIGN_TOKEN_GROUP_PREFIX)) {
      mapDesignTokensToFormFieldsKeys.push(key.substring(1));

      Object
        .entries(value)
        .forEach(mapDesignTokensToForm(themeManifest));

      mapDesignTokensToFormFieldsKeys.pop();

      return;
    }

    const formFieldName = convertKeysToFormFieldName(
      [
        ...mapDesignTokensToFormFieldsKeys,
        key,
      ],
    );

    designTokenFormFields.push({
      config: getThemeManifestValueConfig(value),
      name: formFieldName,
    });

    const themeManifestValue = getThemeManifestValue(themeManifest)(value);

    if (themeManifestValue) {
      formValues[formFieldName] = themeManifestValue;
    }
  }
);

const mapThemeManifestToForm = (
  themeManifest?: ThemeManifest,
): Form => {
  if (!themeManifest) {
    return {
      fields: {
        colors: [],
        designTokens: [],
      },
      values: {},
    };
  }

  const { colors, designTokens } = themeManifest;

  mapDesignTokensToFormFieldsKeys = [];
  colorFormFields = [];
  designTokenFormFields = [];
  formValues = {};

  Object
    .entries(colors)
    .forEach(mapColorsToForm(themeManifest));

  Object
    .entries(designTokens)
    .forEach(mapDesignTokensToForm(themeManifest));

  return {
    fields: {
      colors: colorFormFields,
      designTokens: designTokenFormFields,
    },
    values: formValues,
  };
};

export {
  mapThemeManifestToForm,
};

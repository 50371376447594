export default Object.freeze({
  auth: {
    clientId: '123',
    clientSecret: 'pass',
  },
  env: 'rc',
  features: {},
  logger: 'sentry',
  sentry: {
    appId: '4505188583079936',
    publicKey: '25f3bb497bdc4d6c80c6fc2689a4ddbe',
    sampleRate: 1,
  },
  url: {
    api: {
      auth: 'https://identity.ext.rc.dd.competec.ninja/api',
      comment: 'https://comment.ext.rc.dd.competec.ninja/api/admin',
      deal: 'https://deal.ext.rc.dd.competec.ninja/api/admin',
      identity: 'https://identity.ext.rc.dd.competec.ninja/api/admin',
      static: 'https://static.ext.rc.dd.competec.ninja/api/admin',
    },
    app: {
      Daydeal: 'https://daydeal.ext.rc.dd.competec.ninja',
      Partnerdeal: 'https://partnerdeal.ext.rc.dd.competec.ninja',
    },
    cdn: 'https://images.ext.rc.dd.competec.ninja/files',
    files: 'https://cdn-static.ext.rc.dd.competec.ninja/files',
  },
});

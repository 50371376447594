import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { RichTextEditor } from 'dd-cms-client/common/components/SchemaFields/components/RichTextEditor';
import { Component, Props } from './types';

const CommentTextEditor: Component = ({
  className,
  ...restInputProps
}: Props): ReactElement => {
  const rootClassNames = classNames(
    'CommentTextEditor',
    className,
  );

  return (
    <RichTextEditor
      className={rootClassNames}
      customInit={{
        height: 300,
        menubar: false,
        newline_behavior: 'linebreak',
        paste_data_images: false,
        toolbar: 'undo redo | '
          + 'bold italic | code | link',
      }}
      {...restInputProps}
    />
  );
};

export {
  CommentTextEditor,
};

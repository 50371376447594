import { Variants, VariantsGroup } from 'dd-cms-client/deal/VariantTab';

const getSelectedVariantGroup = (variants: Variants, variantGroupId: null | string): void | VariantsGroup => (
  variants.find(
    (variantsGroup) => variantsGroup.id === variantGroupId,
  )
);

export {
  getSelectedVariantGroup,
};

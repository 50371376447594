import React, { useCallback, useMemo } from 'react';
import { useListContext, useRedirect } from 'react-admin';
import { useTranslation } from 'react-i18next';
import { ResourceName } from 'dd-cms-client/app/resources';
import { ActionsField } from 'dd-cms-client/comment/common/ActionsField';
import { DealField } from 'dd-cms-client/comment/common/DealField';
import { StateIcon } from 'dd-cms-client/comment/common/StateIcon';
import { TitleAndMessageField } from 'dd-cms-client/comment/common/TitleAndMessageField';
import { Component } from 'dd-cms-client/comment/mobile/CommentListMobile/types';
import { getSanitizedHtml } from 'dd-cms-client/comment/utils/getSanitizedHtml';
import { Status, useList } from 'dd-cms-client/common/hooks/useList';
import './CommentListMobile.scss';

const CommentListMobile: Component = () => {
  const { data } = useListContext();
  const { t } = useTranslation();
  const { getStatusClassNames } = useList(ResourceName.COMMENT);
  const redirectTo = useRedirect();

  const text = useMemo(
    () => ({
      author: t('Author'),
      repliedTo: t('Replied to:'),
    }),
    [t],
  );

  const handleOnClickComment = useCallback(
    (id: number) => () => {
      redirectTo(`${id}`);
    }, [redirectTo],
  );

  return (
    <>
      {data?.map(
        ({ id, fields }) => (
          <div
            className="CommentListMobile-Wrapper"
            onClick={handleOnClickComment(id)}
            key={id}
          >
            <StateIcon
              className="CommentListMobile-Icon"
              state={fields.state}
            />

            <TitleAndMessageField
              authorName={fields.authorName}
              parentId={id}
              title={fields.title}
              isMobile={true}
            />

            <div
              className="CommentListMobile-Content"
              dangerouslySetInnerHTML={
                { __html: getSanitizedHtml(fields.content) }
              }
            />

            <div className="CommentListMobile-Details">
                <span className="CommentListMobile-DetailsAuthor">
                  {text.author}: {fields.authorName}
                </span>

              <span className="CommentListMobile-DetailsDate">
                  {fields.postedDate}
                </span>

              <span className={`CommentListMobile-Status
                ${getStatusClassNames(
                fields.status === Status.PUBLISHED,
                fields.status === Status.PENDING,
              )}`
              }>
                {fields.status}
              </span>
            </div>

            <DealField
              className="CommentLisMobile-Deal"
              deal={fields.deal}
              language={fields.language}
              tenant={fields.tenant}
            />

            <ActionsField
              commentId={id}
              ip={fields.ip}
              parentId={fields.parentId}
              state={fields.state}
              status={fields.status}
            />
          </div>
        ),
      )}
    </>
  );
};

export {
  CommentListMobile,
};

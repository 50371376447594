import React, { ReactElement, useEffect } from 'react';
import { Toolbar } from 'react-admin';
import { Component, Props } from './types';
import './FormToolbarWrapper.scss';

const FormToolbarWrapper: Component = (props: Props): ReactElement => {
  const { children, ...toolbarProps } = props;

  useEffect(
    () => {
      document.body.classList.add('Body--FormToolbar');
      return () => document.body.classList.remove('Body--FormToolbar');
    },
    [],
  );

  return (
    <Toolbar
      {...toolbarProps}
      className="FormToolbar"
    >
      {children}
    </Toolbar>
  );
};

export {
  FormToolbarWrapper,
};

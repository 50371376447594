enum RequestType  {
  CREATE = 'create',
  UPDATE = 'update',
}

enum Header {
  ACCEPT = 'Accept',
  AUTHORIZATION = 'Authorization',
  LANGUAGE = 'x-lang',
  TENANT = 'x-tenant',
}

export {
  Header,
  RequestType,
};

import React, { ReactElement } from 'react';
import { MenuItemLink as RaMenuItemLink, useCreatePath } from 'react-admin';
import DefaultIcon from '@mui/icons-material/ViewList';
import { sentenceCase } from 'change-case';
import { Component, Props } from './';

const MenuItemLink: Component = ({ className, onClick, resource }: Props): ReactElement => {
  const title = resource?.options?.label || resource.name || '';
  const icon = resource.icon ? <resource.icon /> : <DefaultIcon />;
  const createPath = useCreatePath();

  return (
    <RaMenuItemLink
      className={className}
      key={resource.name}
      leftIcon={icon}
      onClick={onClick}
      primaryText={sentenceCase(title)}
      to={createPath({ resource: resource.name, type: 'list' })}
    />
  );
};

export {
  MenuItemLink,
};

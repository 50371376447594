import React, {
  ReactElement,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer, TableHead,
  TableRow,
} from '@mui/material';
import { AccessoriesGroup, Accessory } from 'dd-cms-client/deal/AccessoriesTab';
import { Component, Props } from './';
import './AccessoriesImported.scss';

const AccessoriesImported: Component = ({
  accessoriesImported,
}: Props): ReactElement => {
  const { t } = useTranslation();

  const text = useMemo(
    () => ({
      autoImport: t('Auto imported'),
      group: t('Group'),
    }),
    [t],
  );

  return (
    <>
      <TableContainer className="AccessoriesImported-Container">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="AccessoriesImported-Header">
                {text.autoImport}
              </TableCell>
            </TableRow>
          </TableHead>

          {accessoriesImported.map(
            (accessoriesGroup: AccessoriesGroup) => (
              <TableBody key={accessoriesGroup.label}>
                <TableRow className="AccessoriesImported-GroupName">
                  <TableCell
                    colSpan={8}
                    dangerouslySetInnerHTML={
                      { __html: `${text.group}: ${accessoriesGroup.label}` }
                    }
                  />
                </TableRow>

                {accessoriesGroup.products.map(
                  (accessory: Accessory) => (
                    <TableRow key={accessory.sku}>
                      <TableCell
                        dangerouslySetInnerHTML={
                          { __html: accessory.name || '' }
                        }
                      />
                      <TableCell>
                        {accessory.sku}
                      </TableCell>
                    </TableRow>
                  ),
                )}
              </TableBody>
            ),
          )}
        </Table>
      </TableContainer>
    </>
  );
};

export {
  AccessoriesImported,
};

import React, { ReactElement, useCallback, useMemo } from 'react';
import { Create, SaveButton, SimpleForm, useCreate, useNotify, useRefresh } from 'react-admin';
import { useGetIdentity } from 'react-admin';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { ResourceName } from 'dd-cms-client/app/resources';
import { DataLoader } from 'dd-cms-client/common/components/DataLoader';
import { SchemaFields } from 'dd-cms-client/common/components/SchemaFields';
import { useForm } from 'dd-cms-client/common/hooks/useForm';
import { showErrorNotification } from 'dd-cms-client/common/utils/error';
import { SchemaType } from 'dd-cms-client/common/utils/schema';
import { CommentTextEditor } from '../common/CommentTextEditor';
import { SelectDeal } from '../common/SelectDeal';
import { Component, Props } from './';
import './CreateComment.scss';

const CreateComment: Component = ({
  onDialogClose,
}: Props): ReactElement => {
  const { t } = useTranslation();
  const [create] = useCreate();
  const refresh = useRefresh();
  const notify = useNotify();
  const { identity } = useGetIdentity();

  const text = useMemo(
    () => ({
      cancel: t('Cancel'),
      postingAs: t('Posting as'),
      publish: t('Publish'),
    }),
    [t],
  );

  const {
    data,
    dataLoaderProps,
  } = useForm(
    ResourceName.COMMENT,
    SchemaType.CREATE,
  );

  const handleSubmit = useCallback(
    async (values: any) => {
      try {
        await create(
          ResourceName.COMMENT,
          { data: values.fields, meta: { tenant: values.fields.tenant } },
          { returnPromise: true },
        );
        notify('ra.notification.created', { messageArgs: { smart_count: 1 }, type: 'info' });
        onDialogClose();
      } catch (error: any) {
        showErrorNotification(error, notify);
      }

      refresh();
    },
    [onDialogClose, refresh, create, notify],
  );

  return (
    <DataLoader {...dataLoaderProps}>
      <Create>
        <SimpleForm
          mode="onBlur"
          toolbar={false}
          onSubmit={handleSubmit}
        >
          <SchemaFields
            customFieldComponent={{
              content: CommentTextEditor,
              dealId: SelectDeal,
            }}
            formType={SchemaFields.FormType.INSIDE_MODAL}
            resource={ResourceName.COMMENT}
            schema={data}
          />

          <div className="CreateComment-PostAuthor">
            {text.postingAs}&nbsp;
            {identity?.fullName || ''}
          </div>

          <div className="Dialog-ContentActions">
            <SaveButton label={text.publish} />

            <Button
              color="primary"
              onClick={onDialogClose}
              variant="outlined"
            >
              {text.cancel}
            </Button>
          </div>
        </SimpleForm>
      </Create>
    </DataLoader>
  );
};

export {
  CreateComment,
};

import React, { ReactElement, useMemo } from 'react';
import { Edit, useEditController } from 'react-admin';
import { useTranslation } from 'react-i18next';
import { ResourceName } from 'dd-cms-client/app/resources';
import { hasNewsletter } from 'dd-cms-client/auth/utils/permission';
import { getTenant } from 'dd-cms-client/auth/utils/tenant';
import { CategorySelector } from 'dd-cms-client/common/components/CategorySelector';
import { DataLoader, DataLoaderProp } from 'dd-cms-client/common/components/DataLoader';
import { SchemaFields } from 'dd-cms-client/common/components/SchemaFields';
import { TabsSection } from 'dd-cms-client/common/components/TabsSection';
import { TranslatableSection } from 'dd-cms-client/common/components/TranslatableSection';
import { TranslationTab } from 'dd-cms-client/common/components/TranslationTab';
import { getGlobalState, GlobalStateKey } from 'dd-cms-client/common/globalState';
import { useForm } from 'dd-cms-client/common/hooks/useForm';
import { RequestType } from 'dd-cms-client/common/utils/request';
import { getRecordFieldsReferToSchema, SchemaType } from 'dd-cms-client/common/utils/schema';
import { AccessoriesTab } from 'dd-cms-client/deal/AccessoriesTab/AccessoriesTab';
import { AppsTab } from 'dd-cms-client/deal/AppsTab';
import { LinksTab } from 'dd-cms-client/deal/LinksTab';
import { NewsletterCategorySelector } from 'dd-cms-client/deal/NewsletterCategory';
import { NewsletterTab } from 'dd-cms-client/deal/NewsletterTab';
import { StickersTab } from 'dd-cms-client/deal/StickersTab';
import { TagsTab } from 'dd-cms-client/deal/TagsTab';
import { getConfig } from '../config/utils/config';
import { MediaTab } from './MediaTab';
import { RecommendationTab } from './RecommendationTab';
import { VariantTab } from './VariantTab';

const DealEdit = (): ReactElement => {
  const { t } = useTranslation();
  const tenant = getTenant();
  const openedLanguageTab = getGlobalState(GlobalStateKey.OPENED_LANGUAGE_TAB);

  const {
    data: basicInformationData,
    dataLoaderProps: basicInformationDataLoaderProps,
  } = useForm(
    ResourceName.DEAL,
    SchemaType.EDIT,
    RequestType.UPDATE,
  );

  const {
    data: descriptionData,
    dataLoaderProps: descriptionDataLoaderProps,
  } = useForm(
    ResourceName.DEAL_DESCRIPTION,
    SchemaType.EDIT,
    RequestType.UPDATE,
  );

  const {
    data: specificationData,
    dataLoaderProps: specificationDataLoaderProps,
  } = useForm(
    ResourceName.DEAL_SPECIFICATION,
    SchemaType.EDIT,
    RequestType.UPDATE,
  );

  const {
    data: detailData,
    dataLoaderProps: detailDataLoaderProps,
  } = useForm(
    ResourceName.DEAL_DETAIL,
    SchemaType.EDIT,
    RequestType.UPDATE,
  );

  const {
    data: deliveryData,
    dataLoaderProps: deliveryDataLoaderProps,
  } = useForm(
    ResourceName.DEAL_DELIVERY,
    SchemaType.EDIT,
    RequestType.UPDATE,
  );

  const text = useMemo(
    () => ({
      accessories: t('Accessories'),
      apps: t('Apps'),
      basicInformation: t('Basic information'),
      description: t('Description'),
      detail: t('Detail'),
      links: t('Links'),
      media: t('Media'),
      newsletter: t('Newsletter'),
      recommendations: t('Recommendations'),
      scopeOfDelivery: t('Scope of Delivery'),
      specification: t('Specification'),
      stickers: t('Stickers'),
      tags: t('Tags'),
      translations: t('Translations'),
      variants: t('Variants'),
    }),
    [t],
  );

  const editControllerProps = useEditController();
  const { record } = editControllerProps;

  const modifiedBasicInformationRecord = useMemo(
    () => {
      if (record?.fields && basicInformationData?.fields) {
        return ({
          ...record,
          fields: {
            ...getRecordFieldsReferToSchema(record?.fields, basicInformationData?.fields),
            keyFeatures: (record.fields.keyFeatures?.length < 4) ? (
              record.fields.keyFeatures.concat(Array(4 - record.fields.keyFeatures.length).fill({ keyFeature: '' }))
            ) : (
              record.fields.keyFeatures || Array(4).fill({ keyFeature: '' })
            ),
            slug: `${getConfig(`url.app.${tenant}`)}/${openedLanguageTab}/deal/${record.fields.slug}`,
          },
        });
      }
    },
    [basicInformationData?.fields, openedLanguageTab, record, tenant],
  );

  const getDataLoaderProp = (prop: DataLoaderProp) => (
    basicInformationDataLoaderProps[prop]
    || descriptionDataLoaderProps[prop]
    || detailDataLoaderProps[prop]
    || specificationDataLoaderProps[prop]
    || deliveryDataLoaderProps[prop]
  );

  return (
    <DataLoader
      error={getDataLoaderProp(DataLoaderProp.ERROR)}
      isError={getDataLoaderProp(DataLoaderProp.IS_ERROR)}
      isLoading={getDataLoaderProp(DataLoaderProp.IS_LOADING)}
      isSuccess={getDataLoaderProp(DataLoaderProp.IS_SUCCESS)}
    >
      <Edit>
        <TabsSection
          tabs={[
            {
              label: text.basicInformation,
            }, {
              label: text.variants,
              resource: ResourceName.DEAL_VARIANT,
            }, {
              label: text.links,
              resource: ResourceName.DEAL_LINKS,
            }, {
              label: text.media,
              resource: ResourceName.DEAL_MEDIA,
            }, {
              label: text.recommendations,
              resource: ResourceName.DEAL_RECOMMENDED_PRODUCT,
            }, {
              label: text.description,
              resource: ResourceName.DEAL_DESCRIPTION,
            }, {
              label: text.detail,
              resource: ResourceName.DEAL_DETAIL,
            }, {
              label: text.specification,
              resource: ResourceName.DEAL_SPECIFICATION,
            }, {
              label: text.scopeOfDelivery,
              resource: ResourceName.DEAL_DELIVERY,
            }, {
              label: text.accessories,
              resource: ResourceName.DEAL_ACCESSORY_PRODUCT,
            }, {
              label: text.apps,
              resource: ResourceName.DEAL_APP,
            }, {
              label: text.stickers,
              resource: ResourceName.DEAL_STICKER,
            }, {
              label: text.tags,
              resource: ResourceName.DEAL_TAG,
            }, ...(
              hasNewsletter(tenant) ? [{
                label: text.newsletter,
              }] : []
            ), {
              label: text.translations,
            },
          ]}
          tabPanels={[
            <TranslatableSection
              key="main"
              modifiedEditControllerProps={{
                ...editControllerProps,
                record: modifiedBasicInformationRecord,
              }}
              resource={ResourceName.DEAL}
            >
              {activeLanguage => (
                <SchemaFields
                  activeLanguage={activeLanguage}
                  customFieldComponent={{
                    categoryId: CategorySelector,
                    ...(hasNewsletter(tenant) && { newsletterCategory: NewsletterCategorySelector }),
                  }}
                  formType={SchemaFields.FormType.EDIT}
                  resource={ResourceName.DEAL}
                  schema={basicInformationData}
                />
              )}
            </TranslatableSection>,
            <VariantTab key="variant" />,
            <LinksTab key="links" />,
            <MediaTab key="media" />,
            <RecommendationTab key="recommendation" />,
            <TranslatableSection
              key="description"
              modifiedEditControllerProps={{
                ...editControllerProps,
                record: {
                  ...record,
                  fields: getRecordFieldsReferToSchema(record?.fields?.description, descriptionData?.fields),
                },
              }}
              resource={ResourceName.DEAL_DESCRIPTION}
            >
              {activeLanguage => (
                <SchemaFields
                  activeLanguage={activeLanguage}
                  formType={SchemaFields.FormType.EDIT}
                  resource={ResourceName.DEAL_DESCRIPTION}
                  schema={descriptionData}
                />
              )}
            </TranslatableSection>,
            <TranslatableSection
              key="detail"
              modifiedEditControllerProps={{
                ...editControllerProps,
                record: {
                  ...record,
                  fields: getRecordFieldsReferToSchema(record?.fields, detailData?.fields),
                },
              }}
              resource={ResourceName.DEAL_DETAIL}
            >
              {activeLanguage => (
                <SchemaFields
                  activeLanguage={activeLanguage}
                  formType={SchemaFields.FormType.EDIT}
                  resource={ResourceName.DEAL_DETAIL}
                  schema={detailData}
                />
              )}
            </TranslatableSection>,
            <TranslatableSection
              key="specification"
              modifiedEditControllerProps={{
                ...editControllerProps,
                record: {
                  ...record,
                  fields: getRecordFieldsReferToSchema(record?.fields, specificationData?.fields),
                },
              }}
              resource={ResourceName.DEAL_SPECIFICATION}
            >
              {activeLanguage => (
                <SchemaFields
                  activeLanguage={activeLanguage}
                  formType={SchemaFields.FormType.EDIT}
                  resource={ResourceName.DEAL_SPECIFICATION}
                  schema={specificationData}
                />
              )}
            </TranslatableSection>,
            <TranslatableSection
              key="scopeOfDelivery"
              modifiedEditControllerProps={{
                ...editControllerProps,
                record: {
                  ...record,
                  fields: getRecordFieldsReferToSchema(record?.fields, deliveryData?.fields),
                },
              }}
              resource={ResourceName.DEAL_DELIVERY}
            >
              {activeLanguage => (
                <SchemaFields
                  activeLanguage={activeLanguage}
                  formType={SchemaFields.FormType.EDIT}
                  resource={ResourceName.DEAL_DELIVERY}
                  schema={deliveryData}
                />
              )}
            </TranslatableSection>,
            <AccessoriesTab key="accessories" />,
            <AppsTab key="apps" />,
            <StickersTab key="stickers" />,
            <TagsTab
              key="tags"
              dealId={record?.id}
            />,
            ...(hasNewsletter(tenant) ? [
              <NewsletterTab
                key="newsletter"
                dealId={record?.id}
              />,
            ] : []),
            <TranslationTab
              key="translation"
              resource={ResourceName.DEAL}
            />,
          ]}
        />
      </Edit>
    </DataLoader>
  );
};

export {
  DealEdit,
};

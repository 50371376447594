import React, { ReactElement, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog } from 'dd-cms-client/common/components/Dialog';
import { RequestType } from 'dd-cms-client/common/utils/request';
import { CreateGroup } from './CreateGroup';
import { UpdateGroup } from './UpdateGroup';
import { Component, Props } from './';

const GroupLabelDialog: Component = ({
  editControllerProps,
  groupDialog,
  setGroupDialog,
  variants,
}: Props): ReactElement => {
  const { t } = useTranslation();
  const { record } = editControllerProps;

  const text = useMemo(
    () => ({
      createGroupLabel: t('Create new group label'),
      renameGroupLabel: t('Rename group label'),
    }),
    [t],
  );

  const handleDialogClose = useCallback(
    () => {
      setGroupDialog(
        (prevState) => ({
          groupId: null,
          isOpen: false,
          requestType: prevState.requestType, // to prevent jumping inside dialog window
        }),
      );
    },
    [setGroupDialog],
  );

  return (
    <Dialog
      isOpen={groupDialog.isOpen}
      onClose={handleDialogClose}
      title={
        groupDialog.requestType === RequestType.CREATE
          ? text.createGroupLabel
          : text.renameGroupLabel
      }
      content={
        groupDialog.requestType === RequestType.CREATE
          ? (
            <CreateGroup
              dealId={record?.id}
              onDialogClose={handleDialogClose}
            />
          )
          : (
            <UpdateGroup
              editControllerProps={editControllerProps}
              onDialogClose={handleDialogClose}
              variantGroupId={groupDialog.groupId}
              variants={variants}
            />
          )
      }
    />
  );
};

export {
  GroupLabelDialog,
};

import { KeyboardEvent } from 'react';

const disableSubmitForEnterKey = (event: KeyboardEvent<any>): void => {
  if (event.key === 'Enter') {
    event.preventDefault();
  }
};

export {
  disableSubmitForEnterKey,
};

import React, { ReactElement } from 'react';
import { Create, SimpleForm } from 'react-admin';
import { ResourceName } from 'dd-cms-client/app/resources';
import { DataLoader } from 'dd-cms-client/common/components/DataLoader';
import { FormToolbar } from 'dd-cms-client/common/components/Form/FormToolbar';
import { SchemaFields } from 'dd-cms-client/common/components/SchemaFields';
import { useForm } from 'dd-cms-client/common/hooks/useForm';
import { RequestType } from 'dd-cms-client/common/utils/request';
import { SchemaType } from 'dd-cms-client/common/utils/schema';

const AppCreate = (): ReactElement => {
  const {
    data,
    dataLoaderProps,
    onSubmit,
  } = useForm(
    ResourceName.APP,
    SchemaType.CREATE,
    RequestType.CREATE,
  );

  return (
    <DataLoader {...dataLoaderProps}>
      <Create>
        <SimpleForm
          mode="onBlur"
          toolbar={<FormToolbar />}
          onSubmit={onSubmit}
        >
          <SchemaFields
            formType={SchemaFields.FormType.ADD}
            resource={ResourceName.APP}
            schema={data}
          />
        </SimpleForm>
      </Create>
    </DataLoader>
  );
};

export {
  AppCreate,
};

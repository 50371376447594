import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { useEditController } from 'react-admin';
import { API_ENDPOINTS } from 'dd-cms-client/app/dataProvider';
import { getTenant } from 'dd-cms-client/auth/utils/tenant';
import { useRequest } from 'dd-cms-client/common/hooks/useRequest';
import { Header } from 'dd-cms-client/common/utils/request';
import { getValidLanguages, MAIN_LANGUAGE } from 'dd-cms-client/i18n/utils/language';
import { LanguageCode } from 'dd-cms-client/i18n/utils/languageCode';
import { LanguageList } from './LanguageList';
import { TranslationTabDialog } from './TranslationTabDialog';
import { Component, Props } from './types';

enum ActionType {
  ADD = 'add',
  REMOVE = 'remove',
}

const TranslationTab: Component = ({
  resource,
}: Props): ReactElement => {
  const editControllerProps = useEditController();
  const [state, setState] = useState<{
    actionType?: ActionType;
    isDialogOpen: boolean;
    language?: LanguageCode;
  }>({
    actionType: undefined,
    isDialogOpen: false,
    language: undefined,
  });

  const id = editControllerProps.record?.id;
  const tenant = getTenant();
  const { isError, isLoading, isSuccess, makeRequest } = useRequest(
    `${API_ENDPOINTS[resource]}/translate/${resource}/${id}`,
    undefined,
    {
      [Header.TENANT]: tenant,
    },
  );

  const activeLanguages = useMemo(
    () => (
      getValidLanguages(editControllerProps.record?.activeLanguages)
        .filter((language) => language !== MAIN_LANGUAGE)
    ),
    [editControllerProps.record?.activeLanguages],
  );

  const availableLanguages = useMemo(
    () => (
      getValidLanguages(editControllerProps.record?.availableLanguages)
        .filter((language) => language !== MAIN_LANGUAGE)
    ),
    [editControllerProps.record?.availableLanguages],
  );

  const translatableLanguages = useMemo(
    () => (
      availableLanguages
        .filter(language => !activeLanguages.includes(language))
    ),
    [activeLanguages, availableLanguages],
  );

  const handleDialogClose = useCallback(
    () => {
      setState({
        actionType: undefined,
        isDialogOpen: false,
        language: undefined,
      });
    },
    [],
  );

  const handleTranslation = useCallback(
    (actionType: ActionType, language: LanguageCode) => {
      const method = actionType === ActionType.ADD ? 'post' : 'delete';
      makeRequest({ extraHeaders: { [Header.LANGUAGE]: language }, method });

      setState({
        actionType,
        isDialogOpen: true,
        language,
      });
    },
    [makeRequest],
  );

  return (
    <>
      <LanguageList
        actionType={ActionType.REMOVE}
        languages={activeLanguages}
        onTranslation={handleTranslation}
      />

      <LanguageList
        actionType={ActionType.ADD}
        languages={translatableLanguages}
        onTranslation={handleTranslation}
      />

      {state.actionType && (
        <TranslationTabDialog
          {...state}
          isError={isError}
          isLoading={isLoading}
          isSuccess={isSuccess}
          onDialogClose={handleDialogClose}
          onTranslation={handleTranslation}
        />
      )}
    </>
  );
};

export {
  ActionType,
  TranslationTab,
};

import { FC } from 'react';

enum MediaType {
  PHOTO = 'Photo',
  VIDEO = 'Video',
}

interface Item {
  source: string;
  caption: string | null;
  type: MediaType;
}

type Component = FC;

export type {
  Component,
  Item,
};

export { MediaType };

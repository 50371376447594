import React, { MouseEvent, ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FileInput as RaFileInput } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { CircularProgress } from '@mui/material';
import axios, { CancelTokenSource } from 'axios';
import classNames from 'classnames';
import { fromEvent } from 'file-selector';
import { ResourceName } from 'dd-cms-client/app/resources';
import { getTenant } from 'dd-cms-client/auth/utils/tenant';
import { tokenManager } from 'dd-cms-client/auth/utils/tokenManager';
import { getValidationRules } from 'dd-cms-client/common/components/SchemaFields/utils/getValidationRules';
import { GlobalStateKey, useGlobalState } from 'dd-cms-client/common/globalState';
import { useOnInitialRender } from 'dd-cms-client/common/hooks/useOnInitialRender';
import { Header } from 'dd-cms-client/common/utils/request';
import { getConfig } from 'dd-cms-client/config/utils/config';
import i18nextInit from 'dd-cms-client/i18n/i18nextInit';
import { Component, Props } from './types';
import { validateFiles } from './utils/validateFiles';
import './FileInput.scss';

enum RequestFieldName {
  FILE = 'file',
}

const DESIRED_IMAGE_SIZE = {
  [ResourceName.DEAL]: '1144 x 1040px',
  [ResourceName.NEWS]: '1376 x 788px (ratio: 16:9)',
  [ResourceName.PROMOTION]: '1376 x 688px (ratio: 2:1)',
  [ResourceName.USER]: '96 x 96px (ratio: 1:1)',
  [ResourceName.DEAL_VARIANT]: '1380 x 1380px',
  [ResourceName.DEAL_DESCRIPTION]: '1120 × 1018px',
  [ResourceName.STATIC_PAGE]: '3440 × 955px', //header image for static page is enough big, not needed for retina screens (the file is too large)
};

const setFileFormat = (value: string | Record<string | number, any>) => {
  if (!value || typeof value === 'string') {
    return { src: value };
  }

  return value;
};

const FileInput: Component = ({
  className,
  getResponseFileNames,
  hasPreview = true,
  isDisabled,
  isMultiple,
  isImage = false,
  label,
  name = '',
  resource,
  validation,
}: Props): ReactElement => {
  const { getValues, setValue, formState: { errors } } = useFormContext();
  const values = getValues();
  const tenant = getTenant();
  const { t } = i18nextInit;

  const text = useMemo(
    () => ({
      desiredImageSize: t('Desired image size'),
      maximumFileUpload: (maxSize: number) => (
        t('Maximum file upload size is {maxSize}KB', { maxSize })
      ),
      maximumImageHeight: (maxHeight: number) => (
        t('Maximum image height is {maxHeight}px', { maxHeight })
      ),
      maximumImageWidth: (maxWidth: number) => (
        t('Maximum image width is {maxWidth}px', { maxWidth })
      ),
      remove: t('Remove'),
      somethingWentWrong: t('Something went wrong, please try again.'),
    }),
    [t],
  );

  const [fileNames, setFileNames] = useState<Array<string>>(
    values.fields?.[name]
      ? Array.isArray(values.fields[name])
        ? values.fields[name]
        : [values.fields[name]]
      : [],
  );
  const [isDragOver, setIsDragOver] = useState(false);
  const [isFileUploading, setIsFileUploading] = useGlobalState(GlobalStateKey.IS_FORM_FIELD_UPLOADING);
  const cancelUploadRequest = useRef<CancelTokenSource>(axios.CancelToken.source());
  const isInitialRender = useRef<boolean>(false);

  useOnInitialRender(
    () => isInitialRender.current = true,
  );

  const rootClassName = classNames(
    'FileInput',
    {
      'FileInput--Disable': isDisabled,
      'FileInput--DragOver': isDragOver,
      'FileInput--Error': errors?.fields?.[name], // required because in react admin version 4 when fields are incorrect, label and error text are not red
    },
    className,
  );

  const previewClassName = classNames(
    'FileInput-Preview',
    {
      'FileInput-Preview--Disable': isDisabled,
    },
  );

  useEffect(
    () => {
      if (fileNames.length) {
        setValue(
          `fields.${name}`,
          fileNames.length > 1 ? fileNames : fileNames[0],
        );

        isInitialRender.current = false;
        return;
      }

      setValue(
        `fields.${name}`,
        null,
        { shouldDirty: !isInitialRender.current },
      );

      isInitialRender.current = false;
    },
    [setValue, fileNames, name],
  );

  useEffect(
    () => {
      cancelUploadRequest.current = axios.CancelToken.source();

      return () => {
        setIsFileUploading(false);
        cancelUploadRequest.current.cancel();
      };
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(
    () => {
      //hack for logo in themes, in order to logo field will be updated after changes - the hack forced by rerenders in theme
      if (resource === ResourceName.THEME && name === 'logo') {
        if (values.fields?.logo) {
          setFileNames([values.fields?.logo]);
        } else {
          setFileNames([]);
        }
      }
    },
    [name, resource, values.fields?.logo],
  );

  const handleFileRemove = useCallback(
    (fileName: string) => (
      (e: MouseEvent<HTMLElement>): void => {
        e.preventDefault();

        setFileNames(
          prevState => (
            prevState.filter(stateFileName => stateFileName !== fileName)
          ),
        );

        getResponseFileNames?.([]);
      }
    ),
    [getResponseFileNames],
  );

  const handleDropAccepted = async (acceptedFiles: Array<File>) => {
    const authorizationHeader = await tokenManager.getAuthorizationHeader();
    let countAcceptedFiles = 0;
    const responseFiles: Array<string> = [];

    acceptedFiles.forEach(file => {
      const formData = new FormData();
      formData.append(RequestFieldName.FILE, file);

      axios({
        cancelToken: cancelUploadRequest.current.token,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
          [Header.TENANT]: tenant,
          [Header.AUTHORIZATION]: authorizationHeader,
        },
        method: 'post',
        onUploadProgress: () => {
          setIsFileUploading(true);
          setValue(
            `fields.${name}`,
            '',
          );
        },
        url: `${getConfig('url.api.static')}/upload`,
      }).then(
        response => {
          setIsFileUploading(false);
          countAcceptedFiles++;
          responseFiles.push(response.data.name);
          setFileNames(
            prevState => {
              if (isMultiple) {
                return [...prevState, response.data.name];
              }
              return [response.data.name];
            },
          );

          if (countAcceptedFiles === acceptedFiles.length) {
            getResponseFileNames?.(responseFiles);
          }
        },
      ).catch(
        error => {
          setIsFileUploading(false);
          console.log(error); // eslint-disable-line no-console
          if (!axios.isCancel(error)) {
            alert(text.somethingWentWrong);
          }
        },
      );
    });
  };

  const helperText = useMemo(
    (): string => {
      const helperInfo: Array<string> = [];

      if (resource && DESIRED_IMAGE_SIZE[resource] && label !== 'OG image') { //TODO remove DESIRED_IMAGE_SIZE when we get this info from schema: https://competecjira.atlassian.net/browse/DD4-800
        helperInfo.push(`${text.desiredImageSize}: ${DESIRED_IMAGE_SIZE[resource]}`);
      }

      if (label === 'OG image') { // TODO to remove - hack for og images
        helperInfo.push(`${text.desiredImageSize}: 1200 x 630px`);
      }

      if (validation?.maxSize) {
        helperInfo.push(text.maximumFileUpload(validation.maxSize));
      }

      if (validation?.maxHeight) {
        helperInfo.push(text.maximumImageHeight(validation.maxHeight));
      }

      if (validation?.maxWidth) {
        helperInfo.push(text.maximumImageWidth(validation.maxWidth));
      }

      return helperInfo.join('; ');
    },
    [label, resource, text, validation],
  );

  return (
    <>
      <RaFileInput
        name={label}
        className={rootClassName}
        label={t(label) as string}
        source={`fields.${name}`}
        helperText={helperText}
        validate={getValidationRules(validation)}
        accept={
          validation?.formats
            ?.map(
              format => isImage ? `image/${format}` : `application/${format}`,
            )
            .join()
        }
        format={setFileFormat}
        multiple={isMultiple}
        options={{
          disabled: isDisabled,
          getFilesFromEvent: async (e) => {
            setIsDragOver(false);
            // @ts-ignore
            const files: Array<File> = await fromEvent(e);

            if (isImage) {
              return await validateFiles(files, {
                maxHeight: validation?.maxHeight,
                maxSize: validation?.maxSize,
                maxWidth: validation?.maxWidth,
              }, isImage);
            }
            return await validateFiles(files, {
              formats: validation?.formats,
              maxSize: validation?.maxSize,
            }, isImage);
          },
          onDragLeave: () => {
            setIsDragOver(false);
          },
          onDragOver: () => {
            setIsDragOver(true);
          },
          onDropAccepted: handleDropAccepted,
        }}
      >
        {/* empty div is necessary to avoid the error: https://github.com/marmelab/react-admin/issues/4876 */}
        <div />
      </RaFileInput>

      <div className={previewClassName}>
        {(hasPreview && (!isMultiple && !isFileUploading || isMultiple)) && fileNames.map(
          fileName => (
            <div
              className="FileInput-PreviewItem"
              key={fileName}
            >
              {isImage ? (
                <img
                  alt={t(label)}
                  className="FileInput-PreviewImage"
                  src={`${getConfig('url.cdn')}/${fileName}`}
                />
              ) : (
                <a
                  href={`${getConfig('url.files')}/${fileName}`}
                  target='_blank' rel="noreferrer"
                >
                  {`${getConfig('url.files')}/${fileName}`}
                </a>
              )}
              <a
                className="FileInput-PreviewRemoveLink"
                href="#"
                onClick={handleFileRemove(fileName)}
              >
                {text.remove}
              </a>
            </div>
          ),
        )}

        {isFileUploading && (
          <div className="FileInput-Loader">
            <CircularProgress />
          </div>
        )}
      </div>

    </>
  );
};

export {
  FileInput,
  RequestFieldName,
};

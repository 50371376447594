import React, { ReactElement } from 'react';
import { Box } from '@mui/material';
import { Component, Props } from './types';

const TabPanel: Component = ({
  activeTab,
  children,
  tab,
}: Props): ReactElement | null => {
  if (activeTab === tab) {
    return (
      <Box p={2}>
        {children}
      </Box>
    );
  }

  return null;
};

export {
  TabPanel,
};

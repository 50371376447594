import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useEditController, useNotify, useUpdate } from 'react-admin';
import { DropResult } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { ResourceName } from 'dd-cms-client/app/resources';
import { SimpleFormToolbar } from 'dd-cms-client/common/components/Form/SimpleFormToolbar';
import { FileInput } from 'dd-cms-client/common/components/SchemaFields/components/FileInput';
import { TranslatableSection } from 'dd-cms-client/common/components/TranslatableSection';
import { useFormSpy } from 'dd-cms-client/common/hooks/useFormSpy';
import { showErrorNotification } from 'dd-cms-client/common/utils/error';
import { MediaList } from './MediaList';
import { VideoInput } from './VideoInput';
import { Component, Item, MediaType } from './';
import './MediaTab.scss';

const MediaTab: Component = (): ReactElement => {
  const { t } = useTranslation();
  const [update, { isLoading: isUpdateLoading }] = useUpdate();
  const notify = useNotify();
  const { isFormDirty, setIsFormDirty, setOpenFormValues } = useFormSpy();

  const editControllerProps = useEditController();
  const { record } = editControllerProps;

  const mediaRecords = useMemo(
    () => (
      record?.fields.media || []
    ),
    [record?.fields.media],
  );

  const [items, setItems] = useState<Array<Item>>(mediaRecords);

  const text = useMemo(
    () => ({
      uploadImage: t('Upload image'),
    }),
    [t],
  );

  const handleResponseFileNames = useCallback(
    (fileNames: Array<string>) => {
      fileNames.map(
        fileName => {
          setItems(
            current => [
              ...current,
              {
                caption: null,
                source: fileName,
                type: MediaType.PHOTO,
              },
            ],
          );
        },
      );
      setIsFormDirty(true);
    },
    [setIsFormDirty],
  );

  const addVideoId = useCallback(
    (videoId: string) => {
      if (videoId) {
        setItems(
          current => [
            ...current,
            {
              caption: null,
              source: videoId,
              type: MediaType.VIDEO,
            },
          ],
        );
        setIsFormDirty(true);
      }
    },
    [setIsFormDirty],
  );

  const onSubmit = useCallback(
    async () => {
      try {
        await update(
          ResourceName.DEAL_MEDIA,
          { data: { media: items }, id: record?.id },
          { returnPromise: true },
        );
        notify('ra.notification.updated', { messageArgs: { smart_count: 1 }, type: 'info' });
        setIsFormDirty(false);
      } catch (error: any) {
        showErrorNotification(error, notify);
      }
    },
    [update, items, record?.id, notify, setIsFormDirty],
  );

  const onDeleteButtonClick = useCallback(
    (index: number) => {
      const newItems = items.filter((item, itemIndex) => index !== itemIndex);
      setItems(newItems);
      setIsFormDirty(true);
    },
    [items, setIsFormDirty],
  );

  const onCaptionChange = useCallback(
    (index: number, inputValue: string) => {
      const newItems = JSON.parse(JSON.stringify(items));
      const [changedItem] = newItems.splice(index, 1);

      if (changedItem.caption !== inputValue && changedItem.caption !== null) {
        setIsFormDirty(true);
      }
      changedItem.caption = inputValue;
      newItems.splice(index, 0, changedItem);
      setItems(newItems);
    },
    [items, setIsFormDirty],
  );

  const onDragEnd = useCallback(
    (result: DropResult) => {
      const newItems = [...items];
      const [removed] = newItems.splice(result.source.index, 1);
      const destinationIndex = result.destination?.index;
      if (destinationIndex !== undefined) {
        newItems.splice(destinationIndex, 0, removed);
      }
      setItems(newItems);
      setIsFormDirty(true);
    },
    [items, setIsFormDirty],
  );

  useEffect(
    () => {
      setItems(mediaRecords);
    },
    [mediaRecords],
  );

  useEffect(
    () => {
      setOpenFormValues({ fields: { media: items }, id: record?.id });
    },
    [items, record?.id, setOpenFormValues],
  );

  return (
    <>
      <TranslatableSection
        key="media"
        resource={ResourceName.DEAL_MEDIA}
        ownToolbar={
          <SimpleFormToolbar
            isSubmitDisabled={isUpdateLoading || !isFormDirty}
            onSubmit={onSubmit}
          />
        }
      >
        {() => (
          <>
            <div className="MediaTab-InputsWrapper">
              <div className="MediaTab-ImageInput">
                <FileInput
                  hasPreview={false}
                  isDisabled={false}
                  isImage={true}
                  isMultiple={true}
                  label={text.uploadImage}
                  validation={{
                    formats: [
                      'jpg',
                      'jpeg',
                      'png',
                    ],
                    maxHeight: 1200,
                    maxSize: 2048,
                    maxWidth: 1200,
                  }}
                  getResponseFileNames={handleResponseFileNames}
                />
              </div>

              <div className="MediaTab-VideoInput">
                <VideoInput
                  addVideoId={addVideoId}
                />
              </div>
            </div>

            <MediaList
              items={items}
              onCaptionChange={onCaptionChange}
              onDeleteButtonClick={onDeleteButtonClick}
              onDragEnd={onDragEnd}
            />
          </>
        )}
      </TranslatableSection>
    </>
  );
};

export {
  MediaTab,
};

import React, { ReactElement, useMemo } from 'react';
import { EditContextProvider, SimpleForm } from 'react-admin';
import { Box } from '@mui/material';
import isEqual from 'lodash.isequal';
import { FormToolbar } from 'dd-cms-client/common/components/Form/FormToolbar';
import { useForm } from 'dd-cms-client/common/hooks/useForm';
import { usePrevious } from 'dd-cms-client/common/hooks/usePrevious';
import { RequestType } from 'dd-cms-client/common/utils/request';
import { SchemaType } from 'dd-cms-client/common/utils/schema';
import { FormFields } from './FormFields';
import { Component, Props } from './types';

const TabPanels: Component = ({
  activeLanguage,
  content,
  editControllerProps,
  hasToolbar = true,
  languages,
  onSubmit,
  ownToolbar,
  resource,
}: Props): ReactElement => {
  const { onSubmit: useFormOnSubmit } = useForm(
    resource,
    SchemaType.EDIT,
    RequestType.UPDATE,
    false,
  );

  const record = editControllerProps?.record;
  const prevRecord = usePrevious(record);
  //check if it is necessary after upgrade RA
  const shouldRerender = isEqual(prevRecord, record);

  const renderFormFields = useMemo(
    (): ReactElement => (
      // @ts-ignore
      <EditContextProvider value={editControllerProps}>
        <SimpleForm
          // temporary solution - in order to fill fields with correct data
          key={shouldRerender.toString()}
          mode="onBlur"
          record={record}
          onSubmit={onSubmit || useFormOnSubmit}
          toolbar={
            hasToolbar && (
              ownToolbar || <FormToolbar resourceName={resource}/>
            )
          }
        >
          <FormFields
            resource={resource}
            content={content}
            activeLanguage={activeLanguage}
          />
        </SimpleForm>
      </EditContextProvider>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      activeLanguage,
      content,
      shouldRerender,
    ],
  );

  return (
    <>
      {languages.map(language => (
        <div key={language}>
          {activeLanguage === language && (
            <Box p={2}>
              {renderFormFields}
            </Box>
          )}
        </div>
      ))}
    </>
  );
};

export {
  TabPanels,
};

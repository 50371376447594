import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useInput, useResourceContext, ValidationError } from 'react-admin';
import * as ReactColor from 'react-color';
import { ColorResult } from 'react-color';
import { TextField } from '@mui/material';
import { FieldTitle } from 'ra-core';
import { validateHTMLColorRgb } from 'validate-color';
import { Component, Props } from './types';
import './ColorInput.scss';

const ColorInput: Component = (
  {
    className,
    defaultValue,
    isRequired,
    label,
    picker,
    source,
    validate,
  }: Props,
): ReactElement => {
  const {
    field,
    fieldState: { isTouched, error, invalid },
    formState: { isSubmitted },
  } = useInput({ defaultValue, isRequired, source, validate });
  const { onChange, value } = field;

  const resource = useResourceContext();
  const isValueRGBA = validateHTMLColorRgb(value);
  const Picker = ReactColor[`${picker}Picker`];
  const [isPickerDisplayed, setIsPickerDisplayed] = useState<boolean>(false);
  const [currentPickerColor, setCurrentPickerColor] = useState(value);

  const getChangeCallback = useCallback(
    (handler: (arg: any) => void) => (
      ({ hex, rgb: { r, g, b, a } }: ColorResult) => (
        handler(
          isValueRGBA
            ? `rgba(${r}, ${g}, ${b}, ${a})`
            : hex,
        )
      )
    ),
    [isValueRGBA],
  );

  const handlePickerChange = getChangeCallback(setCurrentPickerColor);
  const handlePickerChangeComplete = getChangeCallback(onChange);

  useEffect(
    () => setCurrentPickerColor(value),
    [value],
  );

  return (
    <div className="ColorInput">
      <TextField
        {...field}
        className={className}
        error={(isTouched || isSubmitted) && invalid}
        helperText={
          (isTouched || isSubmitted) && invalid
            ? <ValidationError error={error?.message || ''} />
            : ''
        }
        label={(
          <FieldTitle
            isRequired={isRequired}
            label={label}
            resource={resource}
            source={source}
          />
        )}
        margin="normal"
        onFocus={() => setIsPickerDisplayed(true)}
        value={value || ''}
      />

      <div
        className="ColorInput-Indicator"
        style={{ backgroundColor: value }}
      />

      {isPickerDisplayed && (
        <div className="ColorInput-Picker">
          <div
            className="ColorInput-PickerCover"
            onClick={() => setIsPickerDisplayed(false)}
          />

          <Picker
            color={currentPickerColor}
            disableAlpha={!isValueRGBA}
            onChange={handlePickerChange}
            onChangeComplete={handlePickerChangeComplete}
          />
        </div>
      )}
    </div>
  );
};

ColorInput.defaultProps = {
  picker: 'Chrome',
};

export {
  ColorInput,
};

import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Datagrid,
  FunctionField,
  List,
  RaRecord,
  TextInput,
  useNotify,
} from 'react-admin';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Checkbox } from '@mui/material';
import { API_ENDPOINTS } from 'dd-cms-client/app/dataProvider';
import { ResourceName } from 'dd-cms-client/app/resources';
import { getTenant } from 'dd-cms-client/auth/utils/tenant';
import { Dialog } from 'dd-cms-client/common/components/Dialog';
import { useRequest } from 'dd-cms-client/common/hooks/useRequest';
import { showErrorNotification } from 'dd-cms-client/common/utils/error';
import { Header } from 'dd-cms-client/common/utils/request';
import { getConfig } from 'dd-cms-client/config/utils/config';
import { ButtonsActionProps } from 'dd-cms-client/deal/StickersTab/StickersGlobalList/ButtonsAction';
import { ButtonsAction } from 'dd-cms-client/deal/StickersTab/StickersGlobalList/ButtonsAction/ButtonsAction';
import { Component, Props } from './index';
import './StickersGlobalList.scss';

const StickersGlobalList: Component = ({
  isModalOpen,
  setIsModalOpen,
  dealId,
}: Props): React.ReactElement | null => {
  const { t } = useTranslation();
  const notify = useNotify();
  const tenant = getTenant();
  const location = useLocation();
  const history = useNavigate();

  const buttonsActionRef = useRef<ButtonsActionProps>(null);

  const [selectedStickers, setSelectedStickers] = useState<{
    ids: Array<string>;
  }>({
    ids: [],
  });

  const text = useMemo(
    () => ({
      addStickerFromGlobalAssets: t('Add sticker from global assets'),
      cancel: t('Cancel'),
      save: t('Save'),
      search: t('Search'),
    }),
    [t],
  );

  const {
    error: errorAssign,
    isError: isAssignError,
    isSuccess: isAssignSuccess,
    makeRequest: assignItemsRequest,
  } = useRequest(
    `${API_ENDPOINTS[ResourceName.DEAL_STICKER]}/${ResourceName.DEAL_STICKER}/${dealId}/assign`,
    'PUT',
    {
      [Header.TENANT]: tenant,
    },
  );

  useEffect(
    () => {
      if (isAssignSuccess) {
        notify('ra.notification.updated', { messageArgs: { smart_count: 1 }, type: 'info' });
      }
      if (isAssignError) {
        showErrorNotification(errorAssign, notify);
      }
    },
    [isModalOpen, isAssignError, isAssignSuccess, notify, errorAssign],
  );

  useEffect(() => {
    const clearFilters = setTimeout(() => {
      if (Object.keys(buttonsActionRef.current?.filterValues()).length > 0) {
        buttonsActionRef.current?.resetFilters();
      }
    }, 0);

    return () => {
      clearTimeout(clearFilters);
    };
  }, []);

  const handleDialogClose = useCallback(
    () => {
      setIsModalOpen(false);
      buttonsActionRef.current?.setStartPaginationPage();
      if (location.search !== '') {
        history(location.pathname);
      }
    },
    [history, location.pathname, location.search, setIsModalOpen],
  );

  const handleCheckboxChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const selectedItems = [...selectedStickers.ids];
      const isChecked = selectedItems.includes(e.target.name);
      if (!isChecked) {
        selectedItems.push(e.target.name);
      } else {
        selectedItems.splice(selectedStickers.ids.indexOf(e.target.name), 1);
      }
      setSelectedStickers({ ids: selectedItems });
    }, [selectedStickers.ids],
  );

  const handleSaveButton = useCallback(
    () => {
      assignItemsRequest({ body: selectedStickers });
      buttonsActionRef.current?.setStartPaginationPage();
      setIsModalOpen(false);
      if (location.search !== '') {
        history(location.pathname);
      }
    }, [assignItemsRequest, history, location.pathname, location.search, selectedStickers, setIsModalOpen],
  );

  const filters = [
    <TextInput
      label={text.search}
      key="search"
      source="fields.name"
      alwaysOn
      resettable
    />,
  ];

  return (
    <Dialog
      isOpen={isModalOpen}
      onClose={handleDialogClose}
      title={text.addStickerFromGlobalAssets}
      content={
      <>
        <List
          actions={false}
          className="List"
          resource={ResourceName.STICKER}
          filters={filters}
          sort={{ field: 'name', order: 'DESC' }}
        >
          <Datagrid
            bulkActionButtons={false}
          >
            <FunctionField
              render={(record: RaRecord) => (
                <div
                  className='StickersGlobalList-StickerItemContainer'
                  key={record?.id}
                >
                  <img
                    className="List-Image"
                    src={`${getConfig('url.cdn')}/${record?.fields?.image}`}
                    alt={record?.fields?.label}
                    title={record?.fields?.label}
                  />
                  <div className="StickersGlobalList-ItemDescContainer">
                    <div>
                      <h3 className='StickersGlobalList-ItemDesc'>
                        {record?.fields?.name}
                      </h3>
                      <p className='StickersGlobalList-ItemDesc'>DE - {record?.fields?.label}</p>
                      <p className='StickersGlobalList-ItemDesc'>FR - {record?.fields?.languages.fr.label}</p>
                    </div>
                  </div>
                  <Checkbox
                    name={`${record?.id}`}
                    onChange={handleCheckboxChange}
                  />
                </div>
              )}
            />
          </Datagrid>

          <ButtonsAction
            disabled={selectedStickers.ids.length === 0}
            onCancel={handleDialogClose}
            onSave={handleSaveButton}
            ref={buttonsActionRef}
          />
        </List>
      </>
      }
    />
  );
};

export {
  StickersGlobalList,
};

import { ReactElement } from 'react';
import { useWarnWhenUnsavedChanges } from 'dd-cms-client/common/hooks/useWarnWhenUnsavedChanges';
import { Component, Props } from './';

const FormFields: Component = ({
  activeLanguage,
  content,
  resource,
}: Props): ReactElement => {
  useWarnWhenUnsavedChanges(resource);
  return content(activeLanguage);
};

export {
  FormFields,
};

import React, { ReactElement, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import classNames from 'classnames';
import { useThemeManifest } from '../hooks/useThemeManifest';
import { mapThemeManifestToForm } from '../utils/mapThemeManifestToForm';
import { Field } from './Field/Field';
import { Component, Props } from '.';
import './ManifestFieldGroup.scss';

const ManifestFieldGroup: Component = ({
  source,
}: Props): ReactElement => {
  const { t } = useTranslation();
  const { data: themeManifest } = useThemeManifest();
  const { fields } = mapThemeManifestToForm(themeManifest);
  const [
    areComponentColorsDisplayed,
    setAreComponentColorsDisplayed,
  ] = useState(false);

  const colorFormFields = useMemo(
    () => (
      fields.colors.map(({ config, name }) => (
        <Field
          config={config || null}
          key={`${source}.${name}`}
          name={`${source}.${name}`}
        />
      ))
    ),
    [fields], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const designTokenFormFields = useMemo(
    () => (
      fields.designTokens.map(({ config, name }) => (
        <Field
          config={config || null}
          key={`${source}.${name}`}
          name={`${source}.${name}`}
        />
      ))
    ),
    [fields], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const text = useMemo(
    () => ({
      baseColors: t('Base Colors'),
      componentColors: t('Component Colors'),
      hideComponentColors: t('Hide Component Colors'),
      showComponentColors: t('Show Component Colors'),
    }),
    [t],
  );

  return (
    <div className="ManifestFieldGroup">
      <div className="ManifestFieldGroup-BaseColors">
        <h2>{text.baseColors}</h2>

        <div className="SchemaFields">
          {colorFormFields}
        </div>
      </div>

      <div className="ManifestFieldGroup-DesignTokens">
        <div className={classNames(
          'ManifestFieldGroup-DesignTokensFields',
          {
            'ManifestFieldGroup-DesignTokensFields--Displayed':
              areComponentColorsDisplayed,
          },
        )}>
          <h2>{text.componentColors}</h2>

          <div className="SchemaFields">
            {designTokenFormFields}
          </div>
        </div>

        <div className="ManifestFieldGroup-DesignTokensButton">
          <Button
            color="primary"
            onClick={() => (
              setAreComponentColorsDisplayed(!areComponentColorsDisplayed)
            )}
            variant="outlined"
          >
            {areComponentColorsDisplayed
              ? text.hideComponentColors
              : text.showComponentColors
            }
          </Button>
        </div>
      </div>
    </div>
  );
};

export {
  ManifestFieldGroup,
};

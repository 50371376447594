import isPlainObject from 'lodash.isplainobject';

const replaceEmptyStringsWithNull = (obj: Record<string, any>): Record<string, any> => {
  if (!isPlainObject(obj)) {
    return obj;
  }

  return Object.entries(obj).reduce((result, [key, val]) => {
    if (val === '') {
      result[key] = null;
    } else if (isPlainObject(val)) {
      result[key] = replaceEmptyStringsWithNull(val);
    } else if (Array.isArray(val)) {
      result[key] = val.map(val => replaceEmptyStringsWithNull(val));
    } else {
      result[key] = val;
    }

    return result;
  }, {});
};
export {
  replaceEmptyStringsWithNull,
};

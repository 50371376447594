import React, { ReactElement } from 'react';
import { getTenantUrl, Tenant } from 'dd-cms-client/auth/utils/tenant';
import { DropDownMenu } from 'dd-cms-client/common/components/DropDownMenu';
import { Component, Props } from './';

const TenantSelector: Component = ({
  currentTenant,
  otherTenants,
}: Props): ReactElement => {
  const handleClick = (selectedTenant: Tenant) => async () => {
    try {
      window.location.href = getTenantUrl(selectedTenant);
    } catch (err) {
      console.log(err); // eslint-disable-line no-console
    }
  };

  return (
    <DropDownMenu
      triggerButtonName={currentTenant}
      items={
        otherTenants.map(otherTenant => ({
          name: otherTenant,
          onClick: handleClick(otherTenant),
        }))
      }
    />
  );
};

export {
  TenantSelector,
};

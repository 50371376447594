import React, { ReactElement, useMemo } from 'react';
import {
  Button,
  ChipField,
  Datagrid,
  DateTimeInput,
  FunctionField,
  List,
  RaRecord,
  ReferenceArrayField,
  SelectInput,
  SingleFieldList,
  TextField,
  TextInput,
} from 'react-admin';
import { useTranslation } from 'react-i18next';
import { ResourceName } from 'dd-cms-client/app/resources';
import { ActiveLanguages } from 'dd-cms-client/common/components/ActiveLanguages';
import { ListActions } from 'dd-cms-client/common/components/ListActions';
import { Select } from 'dd-cms-client/common/components/SchemaFields/components/Select';
import { Status, useList } from 'dd-cms-client/common/hooks/useList';
import { DEFAULT_ITEMS_PER_PAGE } from 'dd-cms-client/common/utils/list';
import { getConfig } from 'dd-cms-client/config/utils/config';
import { ShowOnPageValues } from 'dd-cms-client/news/ShowOnPage';

const NewsList = (): ReactElement => {
  const { t } = useTranslation();
  const { handleDuplicate, getActiveRowBackground, getStatusClassNames } = useList(ResourceName.NEWS);

  const text = useMemo(
    () => ({
      actions: t('Actions'),
      all: t('all'),
      categories: t('Categories'),
      duplicate: t('Duplicate'),
      id: t('ID'),
      image: t('Image'),
      news: t('news'),
      search: t('Search'),
      showOnPage: t('Show on page'),
      status: t('Status'),
      title: t('Title'),
      updatedAt: t('Updated at'),
      visibleFrom: t('Visible from'),
      visibleUntil: t('Visible until'),
    }),
    [t],
  );

  const showOnPageOptions =  [{
    id: ShowOnPageValues.ALL,
    name: text.all,
  }, {
    id: ShowOnPageValues.NEWS,
    name: text.news,
  }, {
    id: ShowOnPageValues.CATEGORIES,
    name: text.categories,
  }];

  const filters = [
    <TextInput
      label={text.search}
      key="search"
      source="fields.title"
      alwaysOn
      resettable
    />,
    <SelectInput
      label={text.showOnPage}
      key="showOnPage"
      source="fields.showOnPage.value"
      choices={showOnPageOptions}
      alwaysOn
    />,
    <Select
      alwaysOn
      className="List-Select"
      isMultiple
      options={{
        resource: ResourceName.CATEGORY,
        searchBy: 'fields.name',
      }}
      key="categories"
      commonInputProps={{
        key: 'selected-categories',
        label: text.categories,
        source: 'fields.showOnPage.categories',
        validate: [],
      }}
    />,
    <DateTimeInput
      label={text.visibleFrom}
      key="visibleFrom"
      source="fields.visibilityFrom"
      alwaysOn
    />,
    <DateTimeInput
      label={text.visibleUntil}
      key="visibleUntil"
      source="fields.visibilityUntil"
      alwaysOn
    />,
  ];

  const filterDefaultValues = {
    fields: {
      visibilityFrom: '',
      visibilityUntil: '',
    },
  };

  return (
    <List
      actions={<ListActions filterDefaultValues={filterDefaultValues}/>}
      className="List"
      filterDefaultValues={filterDefaultValues}
      filters={filters}
      perPage={DEFAULT_ITEMS_PER_PAGE}
      sort={{
        field: 'visibilityFrom',
        order: 'DESC',
      }}
    >
      <Datagrid
        bulkActionButtons={false}
        rowClick="edit"
        rowStyle={
          (record: RaRecord) => getActiveRowBackground(
            record?.fields?.visibilityFrom,
            record?.fields?.visibilityUntil,
            record?.fields?.status === Status.PUBLISHED,
          )
        }
      >
        <TextField
          source="id"
          label={text.id}
        />
        <FunctionField
          label={text.image}
          render={(record: RaRecord) => {
            if (record?.fields?.image) {
              return (
                <img
                  alt={text.image}
                  className="List-Image"
                  src={`${getConfig('url.cdn')}/${record?.fields?.image}`}
                />
              );
            }

            return null;
          }}
        />
        <FunctionField
          label={text.title}
          render={(record: RaRecord) => (
            <div>
              <div className="List-Title">
                {record?.fields?.title}
              </div>
              <ActiveLanguages languages={record?.activeLanguages}/>
            </div>
          )}
        />
        <FunctionField
          label={text.showOnPage}
          render={(record: RaRecord) => {
            if (record?.fields?.showOnPage?.value === ShowOnPageValues.CATEGORIES) {
              return (
                <ReferenceArrayField
                  source="fields.showOnPage.categories"
                  reference={ResourceName.CATEGORY}
                >
                  <SingleFieldList>
                    <ChipField source="fields.name" />
                  </SingleFieldList>
                </ReferenceArrayField>
              );
            }

            return (
              <ChipField source="fields.showOnPage.value" />
            );
          }}
        />
        <TextField
          source="fields.visibilityFrom"
          label={text.visibleFrom}
          sortBy="visibilityFrom"
        />
        <TextField
          source="fields.visibilityUntil"
          label={text.visibleUntil}
          sortBy="visibilityUntil"
        />
        <TextField
          source="fields.updatedAt"
          label={text.updatedAt}
          sortBy="updatedAt"
        />
        <FunctionField
          label={text.status}
          render={(record: RaRecord) => (
            <span className={getStatusClassNames(record?.fields?.status === Status.PUBLISHED)}>
              {record?.fields?.status}
            </span>
          )}
        />
        <FunctionField
          label={text.actions}
          render={(record: RaRecord) => (
            <Button
              label={text.duplicate}
              onClick={handleDuplicate(record)}
              size="small"
            />
          )}
        />
      </Datagrid>
    </List>
  );
};

export {
  NewsList,
};

import React, { ReactElement, useMemo } from 'react';
import {
  Menu as RaMenu,
  MenuProps,
  usePermissions,
  useResourceDefinitions,
  useSidebarState,
} from 'react-admin';
import { useTranslation } from 'react-i18next';
import {
  Apps,
  Block,
  Category,
  Label,
  SupervisedUserCircle,
} from '@mui/icons-material';
import { ExpandableSection } from 'dd-cms-client/app/Layout/Menu/ExpandableSection';
import { MenuItemLink } from 'dd-cms-client/app/Layout/Menu/MenuItemLink';
import { resourceLabel, ResourceName } from 'dd-cms-client/app/resources';
import { hasUserAndTenantPermission } from 'dd-cms-client/auth/utils/permission';
import { getTenant } from 'dd-cms-client/auth/utils/tenant';
import { hasAdministratorPermissions } from 'dd-cms-client/auth/utils/userRole';
import { Component } from './';
import './Menu.scss';

const MANUALLY_ADDED_RESOURCES: Array<string> = [
  ResourceName.APP,
  ResourceName.BAN,
  ResourceName.CATEGORY,
  ResourceName.COMMENT,
  ResourceName.USER,
  ResourceName.STICKER,
  ResourceName.USER_PROFILE,
];

const settings = [
  {
    icon: Category,
    name: ResourceName.CATEGORY,
    options: {
      label: resourceLabel.categories,
    },
  },
  {
    icon: Apps,
    name: ResourceName.APP,
    options: {
      label: resourceLabel.apps,
    },
  },
  {
    icon: Label,
    name: ResourceName.STICKER,
    options: {
      label: resourceLabel.stickers,
    },
  },
  {
    icon: Block,
    name: ResourceName.BAN,
    options: {
      label: resourceLabel.bans,
    },
  },
];

const user = {
  icon: SupervisedUserCircle,
  name: ResourceName.USER,
  options: {
    label: resourceLabel.users,
  },
};

const Menu: Component = (props: MenuProps): ReactElement => {
  const { permissions } = usePermissions();
  const resourcesDefinitions = useResourceDefinitions();
  const resources = Object.keys(resourcesDefinitions).map(name => resourcesDefinitions[name]);
  const tenant = getTenant();

  const { t } = useTranslation();
  const [isSidebarOpened] = useSidebarState();

  const text = useMemo(
    () => ({
      settings: t('Settings'),
    }),
    [t],
  );

  return (
    <RaMenu {...props} className="Menu">
      {
        resources
          .filter(resource => (
            !MANUALLY_ADDED_RESOURCES.includes(resource.name)
            && hasUserAndTenantPermission(resource.name as ResourceName, permissions?.userRole, tenant)
          ))
          .map(resource => (
            <MenuItemLink
              className='Menu-Link'
              key={resource.name}
              resource={resource}
            />
          ))
      }

      <div className='Menu-UserSettings-Container'>
        <ExpandableSection
          resources={settings}
          title={text.settings}
          isSidebarClosed={!isSidebarOpened}
        />

        {hasAdministratorPermissions(permissions?.userRole) && (
          <MenuItemLink
            className='Menu-Link Menu-Link--Gap'
            key={ResourceName.USER}
            resource={user}
          />
        )}
      </div>
    </RaMenu>
  );
};

export {
  Menu,
};

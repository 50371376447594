import React, {
  ReactElement,
  useMemo,
} from 'react';
import {
  Datagrid,
  FunctionField,
  List,
  RaRecord,
  TextInput,
} from 'react-admin';
import { useTranslation } from 'react-i18next';
import { ListActions } from 'dd-cms-client/common/components/ListActions';
import { DEFAULT_ITEMS_PER_PAGE } from 'dd-cms-client/common/utils/list';
import { getConfig } from 'dd-cms-client/config/utils/config';
import './StickersList.scss';

const StickersList = (): ReactElement => {
  const { t } = useTranslation();

  const text = useMemo(
    () => ({
      image: t('Image'),
      label: t('Labels'),
      name: t('Name'),
      search: t('Search'),
      url: t('URLs'),
    }),
    [t],
  );

  const filters = [
    <TextInput
      label={text.search}
      key="search"
      source="fields.name"
      alwaysOn
      resettable
    />,
  ];

  return (
    <List
      actions={<ListActions />}
      className="List"
      filters={filters}
      perPage={DEFAULT_ITEMS_PER_PAGE}
      sort={{
        field: 'name',
        order: 'DESC',
      }}
    >
      <Datagrid
        bulkActionButtons={false}
        rowClick='edit'
      >
        <FunctionField
          label={text.image}
          render={(record: RaRecord) => (
            <img
              alt={text.image}
              className="List-Image"
              src={`${getConfig('url.cdn')}/${record?.fields?.image}`}
            />
          )}
        />
        <FunctionField
          label={`${text.name}/${text.label}`}
          render={(record: RaRecord) => (
            <div className='StickersList-DescWrapper'>
              <h3 className="List-Title">
                {record?.fields?.name}
              </h3>
              <span className='StickersList-DescTextLanguage'>
                DE - {record?.fields?.label}
              </span>
              <span className='StickersList-DescTextLanguage'>
                FR - {record?.fields?.languages?.fr?.label}
              </span>
            </div>
          )}
        />
        <FunctionField
          label={text.url}
          render={(record: RaRecord) => (
            <div className='StickersList-URLsWrapper'>
              {record?.fields?.url && (
                <span>
                  DE - {record.fields.url}
                </span>
              )}
              {record?.fields?.languages?.fr?.url && (
                <span>
                  FR - {record.fields.languages.fr.url}
                </span>
              )}
            </div>
          )}
        />
      </Datagrid>
    </List>
  );
};

export {
  StickersList,
};

import { NotFound } from 'react-admin';
import { ResourceName } from 'dd-cms-client/app/resources';
import { hasUserAndTenantPermission } from 'dd-cms-client/auth/utils/permission';
import { Tenant } from 'dd-cms-client/auth/utils/tenant';
import { UserRole } from 'dd-cms-client/auth/utils/userRole';
import { DealCreate } from './DealCreate';
import { DealEdit } from './DealEdit';
import { DealList } from './DealList';

const deal = (userRole: UserRole, tenant: Tenant): Record<string, any> => {
  const hasPermissions = hasUserAndTenantPermission(ResourceName.DEAL, userRole, tenant);

  return {
    create: hasPermissions ? DealCreate : NotFound,
    edit: hasPermissions ? DealEdit : NotFound,
    list: hasPermissions ? DealList : NotFound,
  };
};

export {
  deal,
};

import React, {
  ReactElement,
  useCallback,
} from 'react';
import {
  Create as RaCreate,
  SimpleForm,
  useCreate,
  useNotify,
  useRefresh,
} from 'react-admin';
import { ResourceName } from 'dd-cms-client/app/resources';
import { DataLoader } from 'dd-cms-client/common/components/DataLoader';
import { FormToolbar } from 'dd-cms-client/common/components/Form/FormToolbar';
import { SchemaFields } from 'dd-cms-client/common/components/SchemaFields';
import { useForm } from 'dd-cms-client/common/hooks/useForm';
import { showErrorNotification } from 'dd-cms-client/common/utils/error';
import { SchemaType } from 'dd-cms-client/common/utils/schema';
import { Component, Props } from './index';
import './Create.scss';

const Create: Component = ({
  dealId,
  onBackButton,
}: Props): ReactElement => {
  const [create] = useCreate();
  const refresh = useRefresh();
  const notify = useNotify();

  const {
    data,
    dataLoaderProps,
  } = useForm(
    ResourceName.DEAL_STICKER,
    SchemaType.CREATE,
  );

  const handleSubmit = useCallback(
    async (values: any) => {
      try {
        await create(
          ResourceName.DEAL_STICKER,
          { data: { id: dealId, ...values.fields } },
          { returnPromise: true },
        );
        notify('ra.notification.created', { messageArgs: { smart_count: 1 }, type: 'info' });
        onBackButton();
      } catch (error: any) {
        showErrorNotification(error, notify);
      }

      refresh();
    },
    [refresh, create, dealId, notify, onBackButton],
  );

  return (
    <DataLoader {...dataLoaderProps}>
      <RaCreate className='StickersCreate-Form'>
        <SimpleForm
          mode="onBlur"
          toolbar={<FormToolbar onBackButtonClick={onBackButton}/>}
          onSubmit={handleSubmit}
        >
          <SchemaFields
            formType={SchemaFields.FormType.ADD}
            resource={ResourceName.DEAL_STICKER}
            schema={data}
          />
        </SimpleForm>
      </RaCreate>
    </DataLoader>
  );
};

export {
  Create,
};

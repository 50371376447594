import React, {
  ChangeEvent,
  FocusEvent,
  ReactElement,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Confirm } from 'react-admin';
import {
  DragDropContext,
  DroppableProvided,
  DropResult,
} from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow, TextField,
} from '@mui/material';
import { StrictModeDroppable } from 'dd-cms-client/common/components/StrictModeDroppable';
import { AccessoriesGroup, Accessory } from 'dd-cms-client/deal/AccessoriesTab';
import { AccessoryItem } from 'dd-cms-client/deal/AccessoriesTab/AccessoriesList/AccessoriesTable/AccessoryItem';
import {
  AccessoriesImported,
} from 'dd-cms-client/deal/AccessoriesTab/AccessoriesList/AccessoriesTable/AcessoriesImported';
import { Component, Props } from './';
import './AccessoriesTable.scss';

const AccessoriesTable: Component = ({
  accessories,
  accessoriesImported,
  changeAccessories,
}: Props): ReactElement => {
  const { t } = useTranslation();

  const [isContentEditable, setIsContentEditable] = useState<boolean>(false);
  const [isSkuInputVisible, setIsSkuInputVisible] = useState<boolean>(false);
  const [isWindowConfirmOpen, setWindowConfirmOpen] = useState<boolean>(false);
  const [isGroupBeingDeleted, setIsGroupBeingDeleted] = useState<boolean>(false);
  const [newGroupName, setNewGroupName] = useState<string>('');
  const [openedItem, setOpenedItem] = useState<number | null>(null);
  const [accessoryItem, setAccessoryItem] = useState<Accessory>({
    name: '',
    sku: '',
  });
  const [itemToDelete, setItemToDelete] = useState<AccessoriesGroup | Accessory>();

  const refGroup = useRef<Array<HTMLTableCellElement>>([]);

  const text = useMemo(
    () => ({
      add: t('Add'),
      addSku: t('Add SKU'),
      areYouSureDeleteAccessory: t('Are you sure you want to delete this accessory?'),
      areYouSureDeleteGroup: t('Are you sure you want to delete this group?'),
      autoImport: t('Auto imported'),
      cancel: t('Cancel'),
      delete: t('Delete'),
      deleteAccessory: t('Delete accessory'),
      deleteGroup: t('Delete group'),
      edit: t('Edit'),
      group: t('Group'),
      labelInputGroup: t('Add group'),
    }),
    [t],
  );

  const removeFromList = useCallback(
    (list: Array<Accessory>, index: number): [Accessory, Array<Accessory>] => {
      const result = [...list];
      const [removed] = result.splice(index, 1);
      return [removed, result];
    },
    [],
  );

  const addToList = useCallback(
    (list: Array<Accessory>, index: number, element: Accessory) => {
      const result = [...list];
      result.splice(index, 0, element);
      return result;
    },
    [],
  );

  const handleDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) {
        return;
      }

      const accessoriesCopy: Array<AccessoriesGroup> = JSON.parse(JSON.stringify(accessories));
      const [sourceAccessoryKey] = result.source.droppableId.split('_');

      const [removedSourceAccessoryItem, newSourceAccessoryProducts] = removeFromList(
        accessoriesCopy[sourceAccessoryKey].products,
        result.source.index,
      );

      accessoriesCopy[sourceAccessoryKey].products = newSourceAccessoryProducts;

      const [destinationAccessoryKey] = result.destination.droppableId.split('_');

      const destinationAccessoryProducts = accessoriesCopy[destinationAccessoryKey].products;

      accessoriesCopy[destinationAccessoryKey].products = addToList(
        destinationAccessoryProducts,
        result.destination.index,
        removedSourceAccessoryItem,
      );

      changeAccessories(accessoriesCopy);
    },
    [addToList, changeAccessories, removeFromList, accessories],
  );

  const inputChangeHandler = useCallback(
    (e: FocusEvent<HTMLTableCellElement>, id: string) => {
      const result = accessories.map(
        (data: AccessoriesGroup) => (
          data.label === id
            ? { ...data, label: `${e.target.textContent}` }
            : { ...data }),
      );

      if (e.target.textContent !== id) {
        changeAccessories(result);
      }

      setIsContentEditable(false);
    }, [accessories, changeAccessories],
  );

  const handleAddGroupButton = useCallback(
    () => {
      if (newGroupName) {
        changeAccessories(accessories.concat({
          label: newGroupName,
          products: [],
        }));
      }
    }, [accessories, changeAccessories, newGroupName],
  );

  const handleRenameButton = useCallback(
    (index: number) => () => {
      setIsContentEditable(true);
      setTimeout(() => {
        refGroup.current[index].focus();
      }, 0);
    }, [],
  );

  const handleDeleteAccessoryWindowConfirm = useCallback(
    () => {
      setWindowConfirmOpen(false);
      changeAccessories(accessories.map(
        (data: AccessoriesGroup) => ({
          label: data.label,
          products: data.products.filter(value => value !== itemToDelete),
        }),
      ));
    }, [accessories, changeAccessories, itemToDelete],
  );

  const handleDeleteGroupButton = useCallback(
    (value: AccessoriesGroup) => () => {
      setItemToDelete(value);
      setIsGroupBeingDeleted(true);
      setWindowConfirmOpen(true);
    }, [],
  );

  const handleDeleteGroupWindowConfirm = useCallback(
    () => {
      setWindowConfirmOpen(false);
      setIsGroupBeingDeleted(false);
      changeAccessories(accessories.filter(item => item !== itemToDelete));
    }, [accessories, changeAccessories, itemToDelete],
  );

  const handleAddButton = useCallback(
    (value: { label: string }, index: number) => () => {
      setIsSkuInputVisible(true);
      setAccessoryItem({
        name: value.label,
        sku: '',
      });
      setOpenedItem(index);
    }, [],
  );

  const handleConfirmAddAccessory = useCallback(
    () => {
      if (accessoryItem.sku) {
        changeAccessories(accessories.map(
          (data: AccessoriesGroup) => {
            if (data.label === accessoryItem.name) {
              return {
                label: data.label,
                products: data.products.concat({ sku: accessoryItem.sku }),
              };
            }
            return data;
          },
        ));
      }
    }, [accessories, accessoryItem.name, accessoryItem.sku, changeAccessories],
  );

  const handleCloseConfirmWindow = useCallback(
    () => {
      setWindowConfirmOpen(false);
      setIsGroupBeingDeleted(false);
    }, [],
  );

  const handleOnKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLTableCellElement>) => (
      e.key === 'Enter' && e.currentTarget.blur()
    ), [],
  );

  const handleAddSkuInput = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => setAccessoryItem((prevState) => ({
      ...prevState,
      sku: e.target.value,
    })),
    [],
  );

  const handleCancelAddSkuButton = useCallback(
    () => setIsSkuInputVisible(false),
    [],
  );

  const handleNewGroupNameInput = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => setNewGroupName(e.target.value),
    [],
  );

  return (
    <>
      <div className="AccessoriesTable-AddGroupContainer">
        <TextField
          onChange={handleNewGroupNameInput}
          label={text.labelInputGroup}
        />
        <Button
          onClick={handleAddGroupButton}
          variant='contained'
        >
          {text.add}
        </Button>
      </div>

      <TableContainer>
        <Table>
          <DragDropContext onDragEnd={handleDragEnd}>
            {accessories.map(
              (accessoriesGroup: AccessoriesGroup, indexGroup: number) => (
                <StrictModeDroppable
                  droppableId={`${indexGroup}_${accessoriesGroup.label}`}
                  key={accessoriesGroup.label}
                  isDropDisabled={isContentEditable}
                >
                  {(provided: DroppableProvided) => (
                    <TableBody
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      <TableRow className="AccessoriesTable-GroupName">
                        <TableCell
                          colSpan={8}
                          onKeyDown={handleOnKeyDown}
                          onBlur={(e: FocusEvent<HTMLTableCellElement>) => inputChangeHandler(e, accessoriesGroup.label)}
                          contentEditable={isContentEditable}
                          ref={(element: HTMLTableCellElement) => {
                            if (refGroup?.current) {
                              refGroup.current[indexGroup] = element;
                            }
                          }}
                          dangerouslySetInnerHTML={
                            { __html: accessoriesGroup.label }
                          }
                        />
                        <TableCell>
                          <div className='AccessoriesTable-ButtonsGroupContainer'>
                            <Button
                              size="small"
                              onClick={handleRenameButton(indexGroup)}
                            >
                              {text.edit}
                            </Button>
                            <Button
                              color="error"
                              size="small"
                              onClick={handleDeleteGroupButton(accessoriesGroup)}
                            >
                              {text.delete}
                            </Button>
                            <Button
                              variant='contained'
                              color="success"
                              size="small"
                              onClick={handleAddButton(accessoriesGroup, indexGroup)}
                            >
                              {text.add}
                            </Button>
                          </div>
                        </TableCell>
                      </TableRow>

                      <AccessoryItem
                        accessoriesGroup={accessoriesGroup}
                        setItemToDelete={setItemToDelete}
                        setWindowConfirmOpen={setWindowConfirmOpen}
                      />

                      {provided.placeholder}

                      {isSkuInputVisible && openedItem === indexGroup && (
                        <TableRow className="AccessoriesTable-AddSkuContainer">
                          <TableCell className='AccessoriesTable-AddSkuLabelCell'>
                            {text.addSku}
                          </TableCell>
                          <TableCell className='AccessoriesTable-AddSkuFormCell'>
                            <TextField
                              type="number"
                              onChange={handleAddSkuInput}
                            />
                            <Button
                              className='AccessoriesTable-AddSkuButton'
                              variant='contained'
                              size="small"
                              onClick={handleConfirmAddAccessory}
                            >
                              {text.add}
                            </Button>
                            <Button
                              variant='contained'
                              size="small"
                              onClick={handleCancelAddSkuButton}
                            >
                              {text.cancel}
                            </Button>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  )}
                </StrictModeDroppable>
              ),
            )}
          </DragDropContext>
        </Table>
      </TableContainer>

      <Confirm
        isOpen={isWindowConfirmOpen}
        title={isGroupBeingDeleted ? text.deleteGroup : text.deleteAccessory}
        content={isGroupBeingDeleted ? text.areYouSureDeleteGroup : text.areYouSureDeleteAccessory}
        onConfirm={isGroupBeingDeleted ? handleDeleteGroupWindowConfirm : handleDeleteAccessoryWindowConfirm}
        onClose={handleCloseConfirmWindow}
      />

      <AccessoriesImported accessoriesImported={accessoriesImported} />
    </>
  );
};

export {
  AccessoriesTable,
};

import { BroadcastChannel, createLeaderElection, LeaderElector } from 'broadcast-channel';
import { jwtDecode } from 'jwt-decode';
import localforage from 'localforage';
import { getConfig } from 'dd-cms-client/config/utils/config';
import { getTenant, getTenantUrl, TENANT_BY_DOMAIN } from './tenant';
import { UserRole } from './userRole';

interface Token {
  access_token: string;
  expires_at: number;
  expires_in: number;
  refresh_token: string;
  token_type: string;
}

interface DecodedToken {
  aud: number;
  avatar: string;
  id: number;
  role: UserRole;
  shops: Array<string>;
  username: string;
}

enum AuthEndpoint {
  LOGIN = 'login',
  REFRESH_TOKEN = 'refreshToken',
}

enum AuthGrantType {
  PASSWORD = 'password',
  REFRESH_TOKEN = 'refresh_token',
}

enum AuthScope {
  CMS = 'cms',
}

const TOKEN_KEY = 'token';

const createTokenManager = () => {
  const freshTokenLoadedInAnotherTab = () => {
    createRefreshTokenTimeout();
  };

  const waitForTokenRefresh = async () => {
    if (!isRefreshing) {
      return Promise.resolve();
    }

    await isRefreshing;
    isRefreshing = null;
    return true;
  };

  const createRefreshTokenTimeout = async () => {
    if (!channel) {
      //Current tab is logged in, so we can start leader election
      channel = new BroadcastChannel('__auth_token__');
      channel.onmessage = freshTokenLoadedInAnotherTab;
      elector = createLeaderElection(channel);
      elector.awaitLeadership(); //NOTE: required to trigger leader election
    }

    const token = await getToken();
    if (token) {
      const expiresIn = token.expires_at - Date.now();
      clearRefreshTokenTimeout();
      refreshTokenTimeoutId = window.setTimeout(
        refreshToken,
        expiresIn - 5000,
      );
    }
  };

  const clearRefreshTokenTimeout = () => {
    if (refreshTokenTimeoutId) {
      window.clearTimeout(refreshTokenTimeoutId);
    }
  };

  const refreshToken = async () : Promise<any> => {
    if (elector?.hasLeader && !elector?.isLeader) {
      return;
    }

    const token = await getToken();

    if (!token) {
      return Promise.resolve(false);
    }

    const formData = new FormData();

    formData.append('client_id', getConfig('auth.clientId'));
    formData.append('client_secret', getConfig('auth.clientSecret'));
    formData.append('grant_type', AuthGrantType.REFRESH_TOKEN);
    formData.append('refresh_token', token.refresh_token);
    formData.append('scope', 'cms');

    const request = new Request(
      `${getConfig('url.api.auth')}/${AuthEndpoint.REFRESH_TOKEN}`,
      {
        body: formData,
        method: 'POST',
      },
    );

    isRefreshing = fetch(request);
    const response = await isRefreshing;

    if (response.status !== 200) {
      await eraseToken();
      return false;
    }

    const refreshedToken = await response.json();

    if (refreshedToken) {
      if (channel && !channel.isClosed) {
        channel.postMessage(null);
      }
      return await setToken(refreshedToken);
    }

    return isRefreshing;
  };

  const login = async (username: string, password: string) => {
    const formData = new FormData();

    formData.append('client_id', getConfig('auth.clientId'));
    formData.append('client_secret', getConfig('auth.clientSecret'));
    formData.append('grant_type', AuthGrantType.PASSWORD);
    formData.append('password', password);
    formData.append('scope', AuthScope.CMS);
    formData.append('username', username);

    const request = new Request(
      `${getConfig('url.api.auth')}/${AuthEndpoint.LOGIN}`,
      {
        body: formData,
        method: 'POST',
      },
    );

    const response = await fetch(request);

    if (response.status < 200 || response.status >= 300) {
      throw new Error(response.statusText);
    }

    await setToken(await response.json());

    return Promise.resolve();
  };

  const getAuthorizationHeader = async (): Promise<string> => {
    const token = await getToken();
    return token ? `${token.token_type} ${token.access_token}` : '';
  };

  const getDecodedToken = async (): Promise<DecodedToken | null> => {
    const token = await getToken();
    return token ? jwtDecode(token.access_token) : null;
  };

  const getToken = async (): Promise<Token | null> => (
    await localforage.getItem(TOKEN_KEY)
  );

  const isTokenValid = async () => {
    const token = await getToken();
    return token ? token.expires_at > Date.now() : false;
  };

  const setToken = async (token: Token) => {
    token.expires_at = Date.now() + token.expires_in * 1000;

    await localforage.setItem(TOKEN_KEY, token);
    await createRefreshTokenTimeout();
    await selectTenant();

    return true;
  };

  const eraseToken = async () => {
    clearRefreshTokenTimeout();
    await localforage.removeItem(TOKEN_KEY);
    await elector?.die();
    elector = null;
    await channel?.close();
    channel = null;

    return true;
  };

  const selectTenant = async () => {
    const decodedToken = await getDecodedToken();

    if (decodedToken?.shops.length === 1) {
      const currentTenant = getTenant();
      const tenant = TENANT_BY_DOMAIN[decodedToken.shops[0]];

      if (tenant !== currentTenant) {
        window.location.href = getTenantUrl(tenant);
      }
    }
  };

  const init = async () => {
    const token = await getToken();
    if (!token) {
      return;
    }

    const isValid = await isTokenValid();
    if (isValid) {
      return createRefreshTokenTimeout();
    }
    return refreshToken();
  };

  //There should be a leader selected from all tabs that are logged in
  //If leader exists it should call refreshToken endpoint and broadcast a message that fresh token is available
  let channel: BroadcastChannel | null = null;
  let elector: LeaderElector | null = null;
  let isRefreshing: Promise<Response> | null = null;
  let refreshTokenTimeoutId: number;

  init();

  return {
    eraseToken,
    getAuthorizationHeader,
    getDecodedToken,
    getToken,
    isTokenValid,
    login,
    waitForTokenRefresh,
  };
};

const tokenManager = createTokenManager();

export {
  tokenManager,
};
export type {
  DecodedToken,
  Token,
};

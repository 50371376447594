import config from '../config';

const getConfig = (path: string, defaultValue: any = undefined): any => {
  const separator = '.';

  try {
    return path.replace('[', separator).replace(']', '').split(separator).reduce(
      (accumulator: Record<string, any>, currentValue: string) => accumulator[currentValue],
      config,
    );
  } catch (error) {
    return defaultValue;
  }
};

export {
  getConfig,
};

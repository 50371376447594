import React, { MouseEvent, useRef, useState } from 'react';
import { useClickAway } from 'react-use';
import { MoreVert } from '@mui/icons-material';
import { Button, Tooltip } from '@mui/material';
import classNames from 'classnames';
import { List } from 'dd-cms-client/common/components/ContextMenu/List';
import { Component, Props } from './';
import './ContextMenu.scss';

const ContextMenu: Component = ({
  className,
  links,
}: Props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const listRef = useRef<HTMLUListElement | null>(null);
  const rootClassNames = classNames(
    'ContextMenu',
    className,
  );

  useClickAway(listRef, () => {
    if (isMenuOpen) {
      setIsMenuOpen(false);
    }
  });

  const handleButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsMenuOpen(true);
  };

  const handleMenuClose = () => setIsMenuOpen(false);

  return (
    <Tooltip
      classes={{
        popper: rootClassNames,
      }}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      onClose={handleMenuClose}
      open={isMenuOpen}
      placement="bottom-end"
      title={(
        <List
          links={links}
          listRef={listRef}
          onMenuClose={handleMenuClose}
        />
      )}
    >
      <Button
        className="ContextMenu-TriggerButton"
        onClick={handleButtonClick}
        variant="outlined"
      >
        <MoreVert />
      </Button>
    </Tooltip>
  );
};

export {
  ContextMenu,
};

import React, { ReactElement, useMemo } from 'react';
import {
  Edit,
  SimpleForm,
  useGetIdentity,
} from 'react-admin';
import { useTranslation } from 'react-i18next';
import { ResourceName } from 'dd-cms-client/app/resources';
import { DataLoader } from 'dd-cms-client/common/components/DataLoader';
import { FormToolbar } from 'dd-cms-client/common/components/Form/FormToolbar';
import { SchemaFields } from 'dd-cms-client/common/components/SchemaFields';
import { TabsSection } from 'dd-cms-client/common/components/TabsSection';
import { useForm } from 'dd-cms-client/common/hooks/useForm';
import { RequestType } from 'dd-cms-client/common/utils/request';
import { SchemaType } from 'dd-cms-client/common/utils/schema';

const UserProfile = (): ReactElement => {
  const { t } = useTranslation();

  const text = useMemo(
    () => ({
      profile: t('Profile'),
    }),
    [t],
  );

  const {
    data,
    dataLoaderProps,
    onSubmit,
  } = useForm(
    ResourceName.USER_PROFILE,
    SchemaType.EDIT,
    RequestType.UPDATE,
  );

  const { identity } = useGetIdentity();

  return (
    <DataLoader {...dataLoaderProps}>
      <Edit id={identity?.userId}>
        <TabsSection
          tabPanels={[
            <SimpleForm
              key='main'
              mode="onBlur"
              onSubmit={onSubmit}
              toolbar={<FormToolbar resourceName={ResourceName.USER_PROFILE}/>}
            >

              <SchemaFields
                formType={SchemaFields.FormType.EDIT}
                resource={ResourceName.USER_PROFILE}
                schema={data}
              />
            </SimpleForm>,
          ]}
          tabs={[{ label: text.profile }]}
        />
      </Edit>
    </DataLoader>
  );
};

export {
  UserProfile,
};

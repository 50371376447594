import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronRight, Reply } from '@mui/icons-material';
import classNames from 'classnames';
import { getSanitizedHtml } from '../../utils/getSanitizedHtml';
import { Component, Props } from './types';
import './TitleAndMessageField.scss';

const TitleAndMessageField: Component = ({
  authorName,
  className,
  content,
  parentId,
  title,
  isMobile,
}: Props): ReactElement => {
  const { t } = useTranslation();
  const rootClassNames = classNames(
    'Comment-TitleAndMessage',
    className,
  );

  const text = useMemo(
    () => ({
      author: t('Author'),
      repliedTo: t('Replied to:'),
    }),
    [t],
  );

  return (
    <div className={rootClassNames}>
      <div className="Comment-TitleWrapper">
        {parentId && (
          <>
            <Reply className="Comment-ReplyIcon"/>
            <span className="Comment-ReplyLabel">
              {text.repliedTo}
            </span>
          </>
        )}
        <span className="Comment-Title">
          {title}
        </span>
        <ChevronRight className="Comment-ArrowRightIcon"/>
      </div>

      {content && (
        <div
          className="Comment-Message"
          dangerouslySetInnerHTML={{ __html: getSanitizedHtml(content) }}
        />
      )}
      {!isMobile && (
        <div className="Comment-AuthorName">
          {text.author}: {authorName}
        </div>
      )}
    </div>
  );
};

export {
  TitleAndMessageField,
};

import React, { forwardRef, useImperativeHandle, useMemo } from 'react';
import { useListContext } from 'react-admin';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { ButtonsActionProps, Props } from 'dd-cms-client/deal/StickersTab/StickersGlobalList/ButtonsAction/types';

// eslint-disable-next-line react/display-name
const ButtonsAction = forwardRef<ButtonsActionProps, Props>(
  (props, ref) => {
    const { t } = useTranslation();
    const { setFilters, filterValues, setPage } = useListContext();

    const text = useMemo(
      () => ({
        cancel: t('Cancel'),
        save: t('Save'),
      }),
      [t],
    );

    // @ts-ignore
    useImperativeHandle(ref, () => ({
      filterValues() {
        return filterValues;
      },

      resetFilters() {
        setFilters({}, []);
      },

      setStartPaginationPage() {
        setPage(1);
      },
    }));

    return (
    <div className='StickersGlobalList-ButtonsActionWrapper'>
      <Button
        color="primary"
        variant="contained"
        disabled={props.disabled}
        onClick={props.onSave}
      >
        {text.save}
      </Button>
      <Button
        color="primary"
        variant="outlined"
        sx={{ ml: 2 }}
        onClick={props.onCancel}
      >
        {text.cancel}
      </Button>
    </div>
    );
  },
);

export {
  ButtonsAction,
};

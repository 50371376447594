import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';
import classNames from 'classnames';
import Censored from './assets/Censored.svg';
import Pinned from './assets/Pinned.svg';
import { Component, Props } from './types';
import './StateIcon.scss';

enum State {
  CENSORED = 'censored',
  EMPTY = 'empty',
  PINNED = 'pinned',
}

const StateIcon: Component = ({
  className,
  state,
}: Props): ReactElement | null => {
  const { t } = useTranslation();

  const text = useMemo(
    () => ({
      censored: t('censored'),
      pinned: t('pinned'),
    }),
    [t],
  );

  const rootClassNames = classNames(
    'StateIcon',
    className,
  );

  switch (state) {
    case State.PINNED:
      return (
        <div className={rootClassNames}>
          <Tooltip title={text.pinned}>
            <Pinned />
          </Tooltip>
        </div>
      );

    case State.CENSORED:
      return (
        <div className={rootClassNames}>
          <Tooltip title={text.censored}>
            <Censored />
          </Tooltip>
        </div>
      );

    default:
      return null;
  }
};

export {
  State,
  StateIcon,
};

import {  useEffect, useRef } from 'react';
import { useFormState } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { Transition } from 'history';
import { history } from 'dd-cms-client/app/CustomRouter';
import { ResourceName } from 'dd-cms-client/app/resources';
import { useFormSpy } from 'dd-cms-client/common/hooks/useFormSpy';

// Based on: node_modules/ra-core/src/form/useWarnWhenUnsavedChanges.tsx
const useWarnWhenUnsavedChanges = (resource?: ResourceName): void => {
  const location = useLocation();
  const { onFormDirtyConfirm, isConfirmationWindowOpen, isFormDirty, setIsFormDirty } = useFormSpy(resource);
  const { isSubmitSuccessful, isSubmitting } = useFormState();

  const initialLocation = useRef(location.pathname);

  useEffect(
    () => {
      // added specially, to do not execute after rerender (issue with web browser back button)
      if (isConfirmationWindowOpen || !isFormDirty) {
        return;
      }

      const release = history.block((tx: Transition) => {
        const isInsideForm = tx.location.pathname.startsWith(
          initialLocation.current,
        );

        if (isFormDirty && !isInsideForm && !isSubmitSuccessful && !isSubmitting) {
          setIsFormDirty(false);

          onFormDirtyConfirm().then(
            (isConfirm) => {
              if (isConfirm) {
                release();
                tx.retry();
              }
            },
          );
          return;
        }
        release();
        tx.retry();

        return;
      });

      return () => {
        if (release) {
          release();
        }
      };
    },
    [isFormDirty, isSubmitSuccessful, isSubmitting, onFormDirtyConfirm, navigator], // eslint-disable-line react-hooks/exhaustive-deps
  );
};

export {
  useWarnWhenUnsavedChanges,
};

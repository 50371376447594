import React, { ReactElement, useCallback, useMemo } from 'react';
import { DeleteWithConfirmButton, SaveButton, useRecordContext } from 'react-admin';
import { useFormContext, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NON_DELETABLE_RESOURCES } from 'dd-cms-client/app/resources';
import { BackButton } from 'dd-cms-client/common/components/BackButton';
import { FormToolbarWrapper } from 'dd-cms-client/common/components/Form/FromToolbarWrapper';
import { GlobalStateKey, useGlobalState } from 'dd-cms-client/common/globalState';
import { Component, Props } from './types';
import './FormToolbar.scss';

const FormToolbar: Component = (props: Props): ReactElement => {
  const {
    children,
    onBackButtonClick,
    resourceName,
    ...toolbarProps
  } = props;
  const { isDirty, dirtyFields } = useFormState();
  const { t } = useTranslation();
  const record = useRecordContext();
  const { reset } = useFormContext();
  const isCreate = !record;
  const [isFormFieldUploading] = useGlobalState(GlobalStateKey.IS_FORM_FIELD_UPLOADING);

  const text = useMemo(
    () => ({
      cancel: t('Cancel'),
    }),
    [t],
  );

  const handleDeleteClick = useCallback(
    () => {
      reset(); // reset dirty fields (thus isFormDirty = false) and warning window does not appear
    },
    [reset],
  );

  return (
    <FormToolbarWrapper
      {...toolbarProps}
    >
      <SaveButton
        disabled={
          !(isDirty && Object.keys(dirtyFields).length > 0)
          || isFormFieldUploading
        }
      />

      <BackButton
        color="primary"
        variant="outlined"
        onBackClick={onBackButtonClick}
      >
        {text.cancel}
      </BackButton>

      {children}

      {!NON_DELETABLE_RESOURCES.includes(resourceName)
        && !isCreate && (
        <DeleteWithConfirmButton
          className="FormToolbar-Delete"
          onClick={handleDeleteClick}
        />
      )}
    </FormToolbarWrapper>
  );
};

export {
  FormToolbar,
};

import React, { SetStateAction, useCallback, useMemo } from 'react';
import { useDataProvider, useResourceContext, useTranslate } from 'react-admin';
import { confirm } from 'react-confirm-box';
import { ResourceName } from 'dd-cms-client/app/resources';
import { ConfirmationWindow } from 'dd-cms-client/common/components/ConfirmationWindow';
import { GlobalStateKey, useGlobalState } from 'dd-cms-client/common/globalState';

interface UseFormSpyType {
  isConfirmationWindowOpen: boolean;
  isFormDirty: boolean;
  onFormDirtyConfirm: () => Promise<boolean>;
  setIsConfirmationWindowOpen: (state: SetStateAction<boolean>) => void;
  setIsFormDirty: (state: SetStateAction<boolean>) => void;
  setOpenFormValues: (state: SetStateAction<any>) => void;
  openFormValues: any;
}

const useFormSpy = (resource?: ResourceName): UseFormSpyType => {
  const [isFormDirty, setIsFormDirty] = useGlobalState(GlobalStateKey.IS_FORM_DIRTY);
  const [openFormValues, setOpenFormValues] = useGlobalState(GlobalStateKey.OPEN_FORM_VALUES);
  const [isConfirmationWindowOpen, setIsConfirmationWindowOpen] = useGlobalState(GlobalStateKey.IS_CONFIRMATION_WINDOW_OPEN);
  const dataProvider = useDataProvider();
  const translate = useTranslate();
  const resourceContext = useResourceContext();
  const resourceName = resource || resourceContext;

  const confirmWindowOption = useMemo(
    () => ({
      render: (
        message: string,
        onConfirm: () => void,
        onCancel: () => void,
      ) => {
        return (
          <ConfirmationWindow
            message={message}
            onConfirm={onConfirm}
            onCancel={onCancel}
            openFormValues={openFormValues}
            dataProvider={dataProvider}
            setIsConfirmationWindowOpen={setIsConfirmationWindowOpen}
            resource={resourceName}
          />
        );
      },
    }),
    [dataProvider, openFormValues, resourceName, setIsConfirmationWindowOpen],
  );

  const handleFormDirtyConfirm = useCallback(
    (): Promise<boolean> => {
      setIsConfirmationWindowOpen(true);

      return confirm(
        translate('ra.message.unsaved_changes'),
        // @ts-ignore
        confirmWindowOption,
      );
    },
    [confirmWindowOption, setIsConfirmationWindowOpen, translate],
  );

  return {
    isConfirmationWindowOpen,
    isFormDirty,
    onFormDirtyConfirm: handleFormDirtyConfirm,
    openFormValues,
    setIsConfirmationWindowOpen,
    setIsFormDirty,
    setOpenFormValues,
  };
};

export {
  useFormSpy,
};

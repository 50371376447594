import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { Component, Props } from './types';
import './LangChip.scss';

const LangChip: Component = ({
  className,
  country,
}: Props): ReactElement => {
  const rootClassName = classNames(
    'LangChip',
    className,
  );

  return (
    <div className={rootClassName}>
      {country.toUpperCase()}
    </div>
  );
};

export {
  LangChip,
};

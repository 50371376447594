import React, { useEffect, useState } from 'react';
import { Router } from 'react-router-dom';
import { Action, Location } from 'history';
import { Component, Props } from './';

const CustomRouter: Component = ({
  children,
  history,
}: Props) => {
  const [historyState, setHistoryState] = useState<{
    action: Action;
    location: Location;
  }>({
    action: history.action,
    location: history.location,
  });

  useEffect(
    () => {
      history.listen(setHistoryState);
    },
    [history],
  );

  return (
    <Router
      location={historyState.location}
      navigationType={historyState.action}
      navigator={history}
    >
      {children}
    </Router>
  );
};

export {
  CustomRouter,
};

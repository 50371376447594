import React, { ReactElement, useMemo } from 'react';
import { CreateButton, TopToolbar, useListContext } from 'react-admin';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import classNames from 'classnames';
import { Props } from './';
import './ListActions.scss';

const ListActions = ({
  filterDefaultValues = {},
  hasCreateButton = true,
}: Props): ReactElement => {
  const { t } = useTranslation();
  const { setFilters } = useListContext();
  const rootClassName = classNames(
    'ListActions',
    {
      'ListActions--Narrow': !hasCreateButton,
    },
  );

  const text = useMemo(
    () => ({
      resetAllFilters: t('Reset all filters'),
    }),
    [t],
  );

  return (
    <TopToolbar className={rootClassName}>
      <Button
        size='small'
        onClick={() => {
          setFilters(filterDefaultValues, {});
        }}
      >
        {text.resetAllFilters}
      </Button>
      {hasCreateButton && (
        <CreateButton variant="outlined" />
      )}
    </TopToolbar>
  );
};

export {
  ListActions,
};

import { LanguageCode } from 'dd-cms-client/i18n/utils/languageCode';
import packageJson from '../../package.json';
import envConfig from './env';

export default Object.freeze({
  adminUILanguages: {
    available: [
      {
        code: LanguageCode.EN,
        label: 'EN',
      },
      {
        code: LanguageCode.DE,
        label: 'DE',
      },
    ],
  },
  apiUrlTest: 'http://localhost:3004',
  appName: packageJson.name,
  languages: {
    available: [
      {
        code: LanguageCode.DE,
        label: 'Deutsch',
      },
      {
        code: LanguageCode.FR,
        label: 'Français',
      },
    ],
  },
  mainLanguage: LanguageCode.DE,
  version: packageJson.version,
  ...envConfig,
});

import React, { ReactElement, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { UserRole } from 'dd-cms-client/auth/utils/userRole';
import { CategorySelector } from 'dd-cms-client/common/components/CategorySelector';
import { Component, Props } from './types';

const CategoriesSelector: Component = (props: Props): ReactElement | null => {
  const { getValues, setValue } = useFormContext();
  const role = getValues('fields')?.role;

  useEffect(
    () => {
      if (role === UserRole.ADMINISTRATOR) {
        setValue(props.source, []);
      }
    },
    [props.source, role, setValue],
  );

  return role !== UserRole.ADMINISTRATOR ? (
    <CategorySelector
      {...props}
      isMultiple
      hasAllCategories
    />
  ) : null;
};

export {
  CategoriesSelector,
};

import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, DialogActions, DialogTitle, Paper } from '@mui/material';
import { Component, Props } from './types';
import './ConfirmationWindow.scss';

const ConfirmationWindow: Component = ({
  dataProvider,
  message,
  onCancel,
  onConfirm,
  openFormValues,
  resource,
  setIsConfirmationWindowOpen,
}: Props): ReactElement => {
  const [updateErrorMessage, setUpdateErrorMessage] = useState('');
  const { t } = useTranslation();
  const text = useMemo(
    () => ({
      close: t('close and save inside form'),
      ignoreChanges: t('ignore changes'),
      saveChanges: t('save changes'),
      somethingWentWrong: t('Something went wrong'),
    }),
    [t],
  );

  const handleSaveChangesClick = useCallback(
    (): void => {
      dataProvider.update(
        resource,
        {
          data: { ...openFormValues.fields, id: openFormValues.id },
          id: openFormValues.customWarningRequestUrlId || openFormValues.id,
          previousData: undefined,
        },
      )
        .then(
          () => {
            setIsConfirmationWindowOpen(false);
            onConfirm();
          },
        )
        .catch(
          (error) => {
            setUpdateErrorMessage(error.toString());
          },
        );
    },
    [dataProvider, onConfirm, openFormValues, resource, setIsConfirmationWindowOpen],
  );

  const handleIgnoreChangesClick = useCallback(
    (): void => {
      setIsConfirmationWindowOpen(false);
      onConfirm();
    },
    [onConfirm, setIsConfirmationWindowOpen],
  );

  const handleCloseClick = useCallback(
    (): void => {
      setIsConfirmationWindowOpen(false);
      onCancel();
    },
    [onCancel, setIsConfirmationWindowOpen],
  );

  return (
    <Paper
      className="ConfirmDialog"
      elevation={24}
    >
      <DialogTitle>
        {updateErrorMessage && (
          `${text.somethingWentWrong} (${updateErrorMessage})`
        )}

        {!updateErrorMessage && (
          message
        )}
      </DialogTitle>

      <DialogActions>
        <Button
          color="primary"
          onClick={handleIgnoreChangesClick}
          variant="outlined"
        >
          {text.ignoreChanges}
        </Button>
        {!updateErrorMessage && (
          <Button
            color="primary"
            onClick={handleSaveChangesClick}
            variant="contained"
          >
            {text.saveChanges}
          </Button>
        )}

        {updateErrorMessage && (
          <Button
            color="primary"
            onClick={handleCloseClick}
            variant="contained"
          >
            {text.close}
          </Button>
        )}
      </DialogActions>
    </Paper>
  );
};

export {
  ConfirmationWindow,
};

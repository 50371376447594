import React, { ReactElement, useMemo } from 'react';
import { Edit } from 'react-admin';
import { useTranslation } from 'react-i18next';
import { ResourceName } from 'dd-cms-client/app/resources';
import { DataLoader } from 'dd-cms-client/common/components/DataLoader';
import { SchemaFields } from 'dd-cms-client/common/components/SchemaFields';
import { TabsSection } from 'dd-cms-client/common/components/TabsSection';
import { TranslatableSection } from 'dd-cms-client/common/components/TranslatableSection';
import { TranslationTab } from 'dd-cms-client/common/components/TranslationTab';
import { useForm } from 'dd-cms-client/common/hooks/useForm';
import { RequestType } from 'dd-cms-client/common/utils/request';
import { SchemaType } from 'dd-cms-client/common/utils/schema';

const PromotionEdit = (): ReactElement => {
  const { t } = useTranslation();
  const {
    data,
    dataLoaderProps,
  } = useForm(
    ResourceName.PROMOTION,
    SchemaType.EDIT,
    RequestType.UPDATE,
  );

  const text = useMemo(
    () => ({
      mainContent: t('Main content'),
      translations: t('Translations'),
    }),
    [t],
  );

  return (
    <DataLoader {...dataLoaderProps}>
      <Edit>
        <TabsSection
          tabs={[
            { label: text.mainContent },
            { label: text.translations },
          ]}
          tabPanels={[
            <TranslatableSection
              key='main'
              resource={ResourceName.PROMOTION}
            >
              {activeLanguage => (
                <SchemaFields
                  activeLanguage={activeLanguage}
                  formType={SchemaFields.FormType.EDIT}
                  resource={ResourceName.PROMOTION}
                  schema={data}
                />
              )}
            </TranslatableSection>,
            <TranslationTab
              key="translation"
              resource={ResourceName.PROMOTION}
            />,
          ]}
        />
      </Edit>
    </DataLoader>
  );
};

export {
  PromotionEdit,
};

import React, { ReactElement, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { Dialog } from 'dd-cms-client/common/components/Dialog';
import { ActionType } from 'dd-cms-client/common/components/TranslationTab';
import { MAIN_LANGUAGE } from 'dd-cms-client/i18n/utils/language';
import { LanguageCode } from 'dd-cms-client/i18n/utils/languageCode';
import { Component, Props } from './types';

const TranslationTabDialog: Component = ({
  actionType = ActionType.ADD,
  isDialogOpen,
  isError,
  isLoading,
  isSuccess,
  language = MAIN_LANGUAGE,
  onDialogClose,
  onTranslation,
}: Props): ReactElement | null => {
  const { t } = useTranslation();
  const text = useMemo(
    () => ({
      addingTranslations: t('Adding translations'),
      close: t('close'),
      processingRemoving: (language: string) => t('Processing removing request [{language}]...', { language }),
      processingTranslation: (language: string) => t('Processing translation request [{language}]...', { language }),
      removingProcessFailed: t('Removing process failed. Please try again.'),
      removingTranslations: t('Removing translations'),
      translationAddedSuccessfully: t('Translation were added successfully.'),
      translationProcessFailed: t('Translation process failed. Please try again.'),
      translationRemovedSuccessfully: t('Translation were removed successfully.'),
      tryAgain: t('try again'),
    }),
    [t],
  );

  const content = useMemo(
    () => {
      if (actionType === ActionType.ADD) {
        return (
          isLoading
            ? text.processingTranslation(language.toUpperCase())
            : isSuccess
              ? text.translationAddedSuccessfully
              : text.translationProcessFailed
        );
      }

      return (
        isLoading
          ? text.processingRemoving(language.toUpperCase())
          : isSuccess
            ? text.translationRemovedSuccessfully
            : text.removingProcessFailed
      );
    },
    [actionType, isLoading, isSuccess, language, text],
  );

  const handleTryAgainButtonClick = useCallback(
    (language: LanguageCode) => () => {
      onTranslation(actionType, language);
    },
    [actionType, onTranslation],
  );

  if (!isError && !isLoading && !isSuccess) {
    return null;
  }

  return (
    <Dialog
      isOpen={isDialogOpen}
      onClose={onDialogClose}
      title={
        actionType === ActionType.ADD
          ? text.addingTranslations
          : text.removingTranslations
      }
      content={content}
      actions={!isLoading && (
        <>
          <Button
            color="primary"
            onClick={onDialogClose}
            variant="outlined"
          >
            {text.close}
          </Button>

          {isError && (
            <Button
              color="primary"
              onClick={handleTryAgainButtonClick(language)}
              variant="contained"
            >
              {text.tryAgain}
            </Button>
          )}
        </>
      )}
    />
  );
};

export {
  TranslationTabDialog,
};

import React, { ReactElement, useCallback } from 'react';
import { Edit, SimpleForm } from 'react-admin';
import { ResourceName } from 'dd-cms-client/app/resources';
import { DataLoader } from 'dd-cms-client/common/components/DataLoader';
import { SchemaFields } from 'dd-cms-client/common/components/SchemaFields';
import { useForm } from 'dd-cms-client/common/hooks/useForm';
import { useWarnWhenUnsavedChanges } from 'dd-cms-client/common/hooks/useWarnWhenUnsavedChanges';
import { RequestType } from 'dd-cms-client/common/utils/request';
import { SchemaType } from 'dd-cms-client/common/utils/schema';
import { EditFormToolbar } from './EditFormToolbar';
import { ManifestFieldGroup } from './ManifestFieldGroup';

const ThemeEdit = (): ReactElement => {
  const {
    data,
    dataLoaderProps,
    onSubmit,
  } = useForm(
    ResourceName.THEME,
    SchemaType.EDIT,
    RequestType.UPDATE,
  );

  const warnWhenUnsavedChanges = useWarnWhenUnsavedChanges;

  const FormFields = useCallback(
    () => {
      warnWhenUnsavedChanges();

      return (
        <SchemaFields
          customFieldComponent={{
            manifest: ManifestFieldGroup,
          }}
          schema={data}
          formType={SchemaFields.FormType.EDIT}
          resource={ResourceName.THEME}
        />
      );
    },
    [data, warnWhenUnsavedChanges],
  );

  return (
    <DataLoader {...dataLoaderProps}>
      <Edit>
        <SimpleForm
          mode="onBlur"
          onSubmit={onSubmit}
          toolbar={<EditFormToolbar />}
        >
          <FormFields />
        </SimpleForm>
      </Edit>
    </DataLoader>
  );
};

export {
  ThemeEdit,
};

import { NotFound } from 'react-admin';
import { ResourceName } from 'dd-cms-client/app/resources';
import { hasUserAndTenantPermission } from 'dd-cms-client/auth/utils/permission';
import { Tenant } from 'dd-cms-client/auth/utils/tenant';
import { UserRole } from 'dd-cms-client/auth/utils/userRole';
import { ThemeCreate } from './ThemeCreate';
import { ThemeEdit } from './ThemeEdit';
import { ThemeList } from './ThemeList';

const theme = (userRole: UserRole, tenant: Tenant): Record<string, any> => {
  const hasPermissions = hasUserAndTenantPermission(ResourceName.THEME, userRole, tenant);

  return {
    create: hasPermissions ? ThemeCreate : NotFound,
    edit: hasPermissions ? ThemeEdit : NotFound,
    list: hasPermissions ? ThemeList : NotFound,
  };
};

export {
  theme,
};

import { AuthProvider } from 'ra-core';
import { getTenantsByDomains } from 'dd-cms-client/auth/utils/tenant';
import { tokenManager } from 'dd-cms-client/auth/utils/tokenManager';

const createAuthProvider = (): AuthProvider => {
  return {
    checkAuth: () => {
      return tokenManager.waitForTokenRefresh().then(async () => {
        const token = await tokenManager.getToken();
        return token ? Promise.resolve() : Promise.reject();
      });
    },

    checkError: async error => {
      const status = error.status;

      if (status === 401 || status === 403) {
        await tokenManager.eraseToken();
        return Promise.reject();
      }

      return Promise.resolve();
    },

    getIdentity: async () => {
      const decodedToken = await tokenManager.getDecodedToken();

      if (!decodedToken) {
        return Promise.reject();
      }

      const {
        avatar,
        aud: id,
        username: fullName,
        shops,
        id: userId,
      } = decodedToken;

      return Promise.resolve({
        avatar,
        fullName,
        id,
        shops,
        userId,
      });
    },

    getPermissions: async () => {
      return tokenManager.waitForTokenRefresh().then(async () => {
        const decodedToken = await tokenManager.getDecodedToken();

        return decodedToken
          ? Promise.resolve({
            userRole: decodedToken.role,
            userTenants: getTenantsByDomains(decodedToken.shops),
          })
          : Promise.reject();
      });
    },

    login: ({ password, username }) => {
      return tokenManager.login(username, password);
    },

    logout: async () => {
      await tokenManager.eraseToken();
      return Promise.resolve();
    },
  };
};

export {
  createAuthProvider,
};

import { NotFound } from 'react-admin';
import { ResourceName } from 'dd-cms-client/app/resources';
import { AppCreate } from 'dd-cms-client/apps/AppCreate';
import { AppEdit } from 'dd-cms-client/apps/AppEdit';
import { AppList } from 'dd-cms-client/apps/AppList';
import { hasUserAndTenantPermission } from 'dd-cms-client/auth/utils/permission';
import { Tenant } from 'dd-cms-client/auth/utils/tenant';
import { UserRole } from 'dd-cms-client/auth/utils/userRole';

const app = (userRole: UserRole, tenant: Tenant): Record<string, any> => {
  const hasPermissions = hasUserAndTenantPermission(ResourceName.APP, userRole, tenant);

  return {
    create: hasPermissions ? AppCreate : NotFound,
    edit: hasPermissions ? AppEdit : NotFound,
    list: hasPermissions ? AppList : NotFound,
  };
};

export {
  app,
};

import { NotificationOptions, NotificationType } from 'react-admin';
import i18nextInit from 'dd-cms-client/i18n/i18nextInit';

const showErrorNotification = (
  error: any,
  notify: (message: string, options?: (NotificationOptions & {type?: NotificationType})) => void,
): void => {
  const { t } = i18nextInit;

  const text = {
    somethingWentWrong: t('Something went wrong, please try again.'),
  };

  error?.message
    ? notify(error.message, { type: 'warning' })
    : notify(text.somethingWentWrong, { type: 'warning' });
};

export {
  showErrorNotification,
};

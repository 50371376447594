import React, { ReactElement } from 'react';
import { CircularProgress } from '@mui/material';
import { Component, Props } from './types';
import './DataLoader.scss';

enum DataLoaderProp {
  ERROR = 'error',
  IS_ERROR = 'isError',
  IS_LOADING = 'isLoading',
  IS_SUCCESS = 'isSuccess',
}

const DataLoader: Component = ({
  error,
  isError,
  isLoading,
  isSuccess,
  children,
}: Props): ReactElement | null => {
  if (isLoading) {
    return (
      <div className="LoadingIndicator">
        <CircularProgress />
      </div>
    );
  }

  if (isError) {
    return (
      <div>
        <h2>Error Occurred</h2>
        <p>{error?.toString?.()}</p>
      </div>
    );
  }


  if (isSuccess) {
    return (
      <>
        {children}
      </>
    );
  }

  return null;
};

export {
  DataLoader,
  DataLoaderProp,
};

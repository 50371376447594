import React, {
  ChangeEvent,
  FocusEvent,
  ReactElement,
  useCallback, useEffect,
  useMemo,
  useState,
} from 'react';
import {
  useNotify,
  useRefresh,
  useUpdate,
} from 'react-admin';
import { useTranslation } from 'react-i18next';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { ResourceName } from 'dd-cms-client/app/resources';
import { SimpleFormToolbar } from 'dd-cms-client/common/components/Form/SimpleFormToolbar';
import { useFormSpy } from 'dd-cms-client/common/hooks/useFormSpy';
import { showErrorNotification } from 'dd-cms-client/common/utils/error';
import { getConfig } from 'dd-cms-client/config/utils/config';
import { App } from '../';
import { Component, Props } from './';
import './AppsList.scss';

const AppsList: Component = ({
  dealId,
  apps,
}: Props): ReactElement | null => {
  const { t } = useTranslation();
  const notify = useNotify();
  const [update, { isLoading: isUpdateLoading }] = useUpdate();
  const refresh = useRefresh();
  const { isFormDirty, setIsFormDirty, setOpenFormValues } = useFormSpy();

  const [appsDescription, setAppsDescription] = useState<string | null>(apps.description);
  const [linksApps, setLinksApps] = useState<Array<App>>(apps.links);

  const text = useMemo(
    () => ({
      appDescription: t('App description'),
      image: t('Image'),
      save: t('Save'),
      url: t('URL'),
    }),
    [t],
  );

  useEffect(
    () => {
      setOpenFormValues({ fields: { description: appsDescription, links: linksApps }, id: dealId });
    },
    [appsDescription, dealId, linksApps, setOpenFormValues],
  );

  const changeApps = useCallback(
    async (app: Array<App>) => {
      try {
        await update(
          ResourceName.DEAL_APP,
          { data: { description: appsDescription, links: app }, id: dealId },
          { returnPromise: true },
        );
        notify('ra.notification.updated', { messageArgs: { smart_count: 1 }, type: 'info' });
        setIsFormDirty(false);
      } catch (error: any) {
        showErrorNotification(error, notify);
      }

      refresh();
    },
    [refresh, update, appsDescription, dealId, notify, setIsFormDirty],
  );

  const handleDescriptionChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setAppsDescription(e.target.value);
      setIsFormDirty(true);
    },
    [setIsFormDirty],
  );

  const handleUrlChange = useCallback(
    (e: FocusEvent<HTMLInputElement>, id: number) => {
      setLinksApps(
        linksApps.map(item => item.id === id
          ? { ...item, url: `${e.target.value}`  }
          : item),
      );
      setIsFormDirty(true);
    }, [linksApps, setIsFormDirty],
  );

  const handleOnSaveButton = useCallback(
    () => {
      changeApps(linksApps);
    },
    [changeApps, linksApps],
  );

  return (
    <>
      <div className="AppsList-AppDescriptionContainer">
        <TextField
          className="AppsList-AppDescriptionInput"
          onChange={handleDescriptionChange}
          label={text.appDescription}
          value={appsDescription || ''}
        />
      </div>

      <TableContainer sx={{ mb: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{text.image}</TableCell>
              <TableCell>{text.url}</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {apps.links.map(
              (item: App, index: number) => (
                <TableRow key={item.id}>
                  <TableCell className="AppsList-ImageCell">
                    <img
                      alt={text.image}
                      src={`${getConfig('url.cdn')}/${item.image}`}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      className="AppsList-UrlInput"
                      value={linksApps[index].url || ''}
                      label={text.url}
                      onChange={(e: FocusEvent<HTMLInputElement>) => handleUrlChange(e, item.id)}
                    />
                  </TableCell>
                </TableRow>
              ),
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <SimpleFormToolbar
        isSubmitDisabled={isUpdateLoading || !isFormDirty}
        onSubmit={handleOnSaveButton}
      />
    </>
  );
};

export {
  AppsList,
};

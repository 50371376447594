import React, { ReactElement, useMemo } from 'react';
import {
  Datagrid,
  FunctionField,
  List,
  RaRecord,
} from 'react-admin';
import { useTranslation } from 'react-i18next';
import { ActiveLanguages } from 'dd-cms-client/common/components/ActiveLanguages';
import { DEFAULT_ITEMS_PER_PAGE } from 'dd-cms-client/common/utils/list';
import { getConfig } from 'dd-cms-client/config/utils/config';

const AppList = (): ReactElement => {
  const { t } = useTranslation();

  const text = useMemo(
    () => ({
      image: t('Image'),
      name: t('Name'),
    }),
    [t],
  );

  return (
    <List
      perPage={DEFAULT_ITEMS_PER_PAGE}
      sort={{
        field: 'order',
        order: 'ASC',
      }}
    >
      <Datagrid
        bulkActionButtons={false}
        rowClick='edit'
      >
        <FunctionField
          label={text.name}
          render={(record: RaRecord) => (
            <div>
              <div className="List-Title">
                {record?.fields?.name}
              </div>
              <ActiveLanguages languages={record?.activeLanguages} />
            </div>
          )}
          sortBy='name'
        />
        <FunctionField
          label={text.image}
          render={(record: RaRecord) => (
            <img
              alt={text.image}
              className="List-Image"
              src={`${getConfig('url.cdn')}/${record?.fields?.image}`}
            />
          )}
        />
      </Datagrid>
    </List>
  );
};

export {
  AppList,
};

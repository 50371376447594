import { matchPath } from 'react-router';
import { BASE_PATH } from 'dd-cms-client/app/App';
import { ResourceName } from 'dd-cms-client/app/resources';

enum Tenant {
  DAYDEAL = 'Daydeal',
  PARTNERDEAL = 'Partnerdeal'
}

//TODO remove domain -> tenant mapping when backend is ready
const TENANT_BY_DOMAIN: Record<string, Tenant> = {
  'daydeal.ch': Tenant.DAYDEAL,
  'partnerangebote.brack.ch': Tenant.PARTNERDEAL,
  [Tenant.DAYDEAL]: Tenant.DAYDEAL,
  [Tenant.PARTNERDEAL]: Tenant.PARTNERDEAL,
};

const ALL_PERMISSIONS = 'all';

const TENANT_PERMISSIONS: Record<Tenant, Array<ResourceName | typeof ALL_PERMISSIONS>> = {
  [Tenant.DAYDEAL]: [ALL_PERMISSIONS],
  [Tenant.PARTNERDEAL]: [
    ResourceName.DEAL,
    ResourceName.THEME,
    ResourceName.APP,
    ResourceName.BAN,
    ResourceName.STICKER,
    ResourceName.CATEGORY,
    ResourceName.USER,
    ResourceName.USER_PROFILE,
    ResourceName.COMMENT,
  ],
};

enum SpecificFunctionality {
  NEWSLETTER = 'newsletter'
}

const TENANT_SPECIFIC_FUNCTIONALITY: Record<Tenant, Array<SpecificFunctionality>> = {
  [Tenant.DAYDEAL]: [SpecificFunctionality.NEWSLETTER],
  [Tenant.PARTNERDEAL]: [],
};

const DEFAULT_TENANT = Tenant.DAYDEAL;

const getTenantUrl = (tenant: Tenant): string => `/${tenant}/${ResourceName.DEAL}`;

const getTenant = (): Tenant => {
  const match = matchPath(BASE_PATH, window.location.pathname);

  return match?.params?.tenant as Tenant || DEFAULT_TENANT;
};

const getTenantsByDomains = (domains: Array<keyof typeof TENANT_BY_DOMAIN>): Array<Tenant> => {
  if (!Array.isArray(domains)) {
    return [];
  }

  return domains.map(domain => TENANT_BY_DOMAIN[domain]);
};

export {
  ALL_PERMISSIONS,
  DEFAULT_TENANT,
  getTenant,
  getTenantsByDomains,
  getTenantUrl,
  SpecificFunctionality,
  Tenant,
  TENANT_BY_DOMAIN,
  TENANT_PERMISSIONS,
  TENANT_SPECIFIC_FUNCTIONALITY,
};

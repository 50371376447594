import React, { ReactElement, useMemo, useState } from 'react';
import { useEditController } from 'react-admin';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { RequestType } from 'dd-cms-client/common/utils/request';
import { GroupLabelDialog } from 'dd-cms-client/deal/VariantTab/GroupLabelDialog';
import { Variant } from './Variant';
import { VariantList } from './VariantList';
import { Component, GroupDialogState, SelectedVariantState } from './';
import './VariantTab.scss';

const VariantTab: Component = (): ReactElement => {
  const { t } = useTranslation();

  const [groupDialog, setGroupDialog] = useState<GroupDialogState>({
    groupId: null,
    isOpen: false,
    requestType: null,
  });

  const [selectedVariant, setSelectedVariant] = useState<SelectedVariantState>({
    isVisible: false,
    requestType: null,
    variantId: null,
  });

  const editControllerProps = useEditController();
  const { record } = editControllerProps;

  const variants = useMemo(
    () => record?.fields ? record.fields.variants : [],
    [record.fields],
  );

  const activeLanguages = useMemo(
    () => record ? record.activeLanguages : [],
    [record],
  );

  const text = useMemo(
    () => ({
      createNewGroup: t('+ Create new group'),
      createNewVariant: t('+ Create new variant'),
    }),
    [t],
  );

  const handleCreateGroupClick = () => (
    setGroupDialog({
      groupId: null,
      isOpen: true,
      requestType: RequestType.CREATE,
    })
  );

  const handleCreateVariantClick = () => (
    setSelectedVariant({
      isVisible: true,
      requestType: RequestType.CREATE,
      variantId: null,
    })
  );

  return (
    <>
      {!selectedVariant.isVisible && (
        <>
          <Button
            className="VariantTab-CreateVariantButton"
            onClick={handleCreateVariantClick}
            variant="contained"
          >
            {text.createNewVariant}
          </Button>

          <Button
            onClick={handleCreateGroupClick}
            variant="outlined"
          >
            {text.createNewGroup}
          </Button>

          <VariantList
            activeLanguages={activeLanguages}
            dealId={record.id}
            setGroupDialog={setGroupDialog}
            setSelectedVariant={setSelectedVariant}
            variants={variants}
          />
        </>
      )}

      {selectedVariant.isVisible && (
        <Variant
          editControllerProps={editControllerProps}
          setSelectedVariant={setSelectedVariant}
          selectedVariant={selectedVariant}
          variants={variants}
        />
      )}

      <GroupLabelDialog
        editControllerProps={editControllerProps}
        groupDialog={groupDialog}
        setGroupDialog={setGroupDialog}
        variants={variants}
      />
    </>
  );
};

export {
  VariantTab,
};

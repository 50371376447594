import React, { MouseEvent, ReactElement, useCallback, useState } from 'react';
import { ArrowDropDown } from '@mui/icons-material';
import { Button, Menu, MenuItem, PopoverOrigin } from '@mui/material';
import classNames from 'classnames';
import { Component, Props } from './';
import './DropDownMenu.scss';

const AnchorOrigin: PopoverOrigin = {
  horizontal: 'right',
  vertical: 'bottom',
};

const TransformOrigin: PopoverOrigin = {
  horizontal: 'right',
  vertical: 'top',
};

const DropDownMenu: Component = ({
  className,
  items,
  triggerButtonName,
}: Props): ReactElement => {
  const rootClassName = classNames(
    'DropDownMenu',
    className,
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);

  const handleOpen = (e: MouseEvent<HTMLButtonElement>) => setAnchorEl(e.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const handleClick = useCallback(
    (onMenuItemClick: any) => () => {
      onMenuItemClick();
      handleClose();
    },
    [],
  );

  return (
    <div className={rootClassName}>
      <Button
        className="DropDownMenu-TriggerButton"
        onClick={handleOpen}
        endIcon={<ArrowDropDown />}
      >
        {triggerButtonName}
      </Button>

      <Menu
        anchorEl={anchorEl}
        anchorOrigin={AnchorOrigin}
        onClose={handleClose}
        open={isOpen}
        transformOrigin={TransformOrigin}
      >
        {items.map(
          ({ onClick, name }) => {
            return (
              <MenuItem
                key={name}
                onClick={handleClick(onClick)}
              >
                {name}
              </MenuItem>
            );
          },
        )}
      </Menu>
    </div>
  );
};

export {
  DropDownMenu,
};

import React, { ReactElement, useMemo } from 'react';
import { Edit, SimpleForm } from 'react-admin';
import { useTranslation } from 'react-i18next';
import { ResourceName } from 'dd-cms-client/app/resources';
import { DataLoader } from 'dd-cms-client/common/components/DataLoader';
import { FormToolbar } from 'dd-cms-client/common/components/Form/FormToolbar';
import { SchemaFields } from 'dd-cms-client/common/components/SchemaFields';
import { TabsSection } from 'dd-cms-client/common/components/TabsSection';
import { useForm } from 'dd-cms-client/common/hooks/useForm';
import { useWarnWhenUnsavedChanges } from 'dd-cms-client/common/hooks/useWarnWhenUnsavedChanges';
import { RequestType } from 'dd-cms-client/common/utils/request';
import { SchemaType } from 'dd-cms-client/common/utils/schema';

const BanEdit = (): ReactElement => {
  const { t } = useTranslation();
  const {
    data,
    dataLoaderProps,
    onSubmit,
  } = useForm(
    ResourceName.BAN,
    SchemaType.EDIT,
    RequestType.UPDATE,
  );

  const text = useMemo(
    () => ({
      ban: t('Ban'),
    }),
    [t],
  );

  const FormFields = (): ReactElement => {
    useWarnWhenUnsavedChanges();

    return (
      <SchemaFields
        schema={data}
        formType={SchemaFields.FormType.EDIT}
        resource={ResourceName.BAN}
      />
    );
  };

  return (
    <DataLoader {...dataLoaderProps}>
      <Edit>
        <TabsSection
          tabPanels={[
            <SimpleForm
              key='main'
              mode="onBlur"
              onSubmit={onSubmit}
              toolbar={<FormToolbar />}
            >

              <FormFields />
            </SimpleForm>,
          ]}
          tabs={[{ label: text.ban }]}
        />
      </Edit>
    </DataLoader>
  );
};

export {
  BanEdit,
};

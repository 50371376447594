import React, { ChangeEvent, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useNotify, useRefresh, useUpdate } from 'react-admin';
import { useTranslation } from 'react-i18next';
import { Checkbox, CircularProgress, FormControlLabel } from '@mui/material';
import { ResourceName } from 'dd-cms-client/app/resources';
import { showErrorNotification } from 'dd-cms-client/common/utils/error';
import { Variants } from '../';
import { GroupTable } from './GroupTable';
import { VariantTable } from './VariantTable';
import { Component, Props } from './';
import './VariantList.scss';

const VariantList: Component = ({
  activeLanguages,
  dealId,
  setGroupDialog,
  setSelectedVariant,
  variants: variantsProp,
}: Props): ReactElement | null => {
  const { t } = useTranslation();
  const notify = useNotify();
  const [update, { isLoading: isChangeOrderLoading }] = useUpdate();
  const refresh = useRefresh();
  const [isCheckedGroupOrder, setIsCheckedGroupOrder] = useState(false);
  const [variants, setVariants] = useState<Variants>(variantsProp);

  const text = useMemo(
    () => ({
      changeGroupsOrder: t('Change groups order'),
    }),
    [t],
  );

  const changeOrder = useCallback(
    async (variants: Variants) => {
      try {
        await update(
          ResourceName.DEAL_VARIANT,
          { data: { groups: variants }, id: `${dealId}/order` },
          { returnPromise: true },
        );
        notify('ra.notification.updated', { messageArgs: { smart_count: 1 }, type: 'info' });
        setVariants(variants);
      } catch (error: any) {
        showErrorNotification(error, notify);
      }

      refresh();
    },
    [refresh, update, dealId, notify],
  );

  useEffect(
    () => {
      setVariants(variantsProp);
    },
    [variantsProp],
  );

  const handleCheckedGroupOrder = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setIsCheckedGroupOrder(event.target.checked);
    },
    [],
  );

  if (variants.length === 0) {
    return null;
  }

  return (
    <>
      <div className="VariantList-CheckboxGroupOrderWrapper">
        <FormControlLabel
          className="VariantList-CheckboxGroupOrder"
          control={
            <Checkbox
              checked={isCheckedGroupOrder}
              onChange={handleCheckedGroupOrder}
            />
          }
          label={text.changeGroupsOrder}
        />
      </div>

      {isChangeOrderLoading && (
        <div className="VariantList-Loader">
          <CircularProgress />
        </div>
      )}

      {isCheckedGroupOrder && !isChangeOrderLoading && (
        <GroupTable
          activeLanguages={activeLanguages}
          changeOrder={changeOrder}
          variants={variants}
        />
      )}

      {!isCheckedGroupOrder && !isChangeOrderLoading && (
        <VariantTable
          activeLanguages={activeLanguages}
          changeOrder={changeOrder}
          dealId={dealId}
          setGroupDialog={setGroupDialog}
          setSelectedVariant={setSelectedVariant}
          variants={variants}
        />
      )}
    </>
  );
};

export {
  VariantList,
};

import React, { ReactElement, useMemo } from 'react';
import {
  Button,
  Datagrid,
  FunctionField,
  List,
  RaRecord,
  SelectInput,
  TextField,
  TextInput,
} from 'react-admin';
import { useTranslation } from 'react-i18next';
import { ResourceName } from 'dd-cms-client/app/resources';
import { ListActions } from 'dd-cms-client/common/components/ListActions';
import { Status, useList } from 'dd-cms-client/common/hooks/useList';
import { DEFAULT_ITEMS_PER_PAGE } from 'dd-cms-client/common/utils/list';

const ThemeList = (): ReactElement => {
  const { t } = useTranslation();
  const { handleDuplicate, getStatusClassNames } = useList(ResourceName.THEME);

  const text = useMemo(
    () => ({
      createdAt: t('Created at'),
      duplicate: t('Duplicate'),
      id: t('ID'),
      search: t('Search'),
      status: t('Status'),
      title: t('Title'),
      updatedAt: t('Updated at'),
    }),
    [t],
  );

  const statusOptions = [
    {
      id: Status.PUBLISHED,
      name: Status.PUBLISHED,
    }, {
      id: Status.UNPUBLISHED,
      name: Status.UNPUBLISHED,
    },
  ];

  const filters = [
    <TextInput label={text.search} key="search" source="fields.title" alwaysOn resettable />,
    <SelectInput label={text.status} key="status" source="fields.status" choices={statusOptions} alwaysOn />,
  ];

  return (
    <List
      actions={<ListActions />}
      className="List"
      filters={filters}
      perPage={DEFAULT_ITEMS_PER_PAGE}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <Datagrid
        bulkActionButtons={false}
        rowClick="edit"
      >
        <TextField
          source="id"
          label={text.id}
        />
        <TextField
          source="fields.title"
          label={text.title}
          sortBy="title"
        />
        <TextField
          source="fields.createdAt"
          label={text.createdAt}
          sortBy="createdAt"
        />
        <TextField
          source="fields.updatedAt"
          label={text.updatedAt}
          sortBy="updatedAt"
        />
        <FunctionField
          label={text.status}
          render={(record: RaRecord) => (
            <span className={getStatusClassNames(record?.fields?.status === Status.PUBLISHED)}>
              {record?.fields?.status}
            </span>
          )}
        />
        <FunctionField
          label="Actions"
          render={(record: RaRecord) => (
            <Button
              label={text.duplicate}
              onClick={handleDuplicate(record)}
              size="small"
            />
          )}
        />
      </Datagrid>
    </List>
  );
};

export {
  ThemeList,
};

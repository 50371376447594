import React, { ReactElement, useMemo } from 'react';
import { RadioButtonGroupInput, required } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { CategorySelector } from 'dd-cms-client/common/components/CategorySelector';
import { Component, Props } from './types';
import './ShowOnPage.scss';

enum ShowOnPageValues {
  ALL = 'all',
  NEWS = 'news',
  CATEGORIES = 'categories',
}

const ShowOnPage: Component = ({
  isDisabled,
  label,
}: Props): ReactElement => {
  const { getValues } = useFormContext();
  const fields = getValues('fields') || {};
  const { t } = useTranslation();
  const text = useMemo(
    () => ({
      allPages: t('All pages'),
      newsPageOnly: t('News page only'),
      selectedCategories: t('Selected categories'),
      showOnPage: t('Show on page'),
    }),
    [t],
  );
  const rootClassName = classNames(
    'ShowOnPage-RadioGroup',
    {
      'ShowOnPage-RadioGroup--Disabled': isDisabled,
    },
  );

  //NOTE: HACK to solve nested property issue
  if (!fields.showOnPage) {
    fields.showOnPage = {};
  }

  const options = [{
    id: ShowOnPageValues.ALL,
    name: text.allPages,
  }, {
    id: ShowOnPageValues.NEWS,
    name: text.newsPageOnly,
  }, {
    id: ShowOnPageValues.CATEGORIES,
    name: text.selectedCategories,
  }];

  return (
    <>
      <RadioButtonGroupInput
        choices={options}
        defaultValue={ShowOnPageValues.ALL}
        label={label}
        options={{ className: rootClassName }}
        source='fields.showOnPage.value'
      />
      {fields.showOnPage.value === ShowOnPageValues.CATEGORIES && (
        <div className='ShowOnPage-Select'>
          <CategorySelector
            key='selected-categories'
            label={text.selectedCategories}
            source='fields.showOnPage.categories'
            isMultiple
            validate={[required()]}
          />
        </div>
      )}
    </>
  );
};

export {
  ShowOnPage,
  ShowOnPageValues,
};

import { Variant, Variants } from 'dd-cms-client/deal/VariantTab';

const getSelectedVariant = (variants: Variants, variantId: null | string): void | Variant => (
  variants
    .map(variantGroup => variantGroup.items)
    .flat()
    .find(variant => variant.id === variantId)
);

export {
  getSelectedVariant,
};

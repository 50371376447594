import { required } from 'react-admin';
import validateColor from 'validate-color';

const color = (message = 'ra.validation.color') => (
  (value: any): string | undefined => (
    !validateColor(value) ? message : undefined
  )
);

const createColorValidationRules = (
  t: TranslateFunction,
): Array<any> => {
  const text = {
    color: t('Must be a valid color'),
    required: t('Required'),
  };

  return [
    required(text.required),
    color(text.color),
  ];
};

export {
  createColorValidationRules,
};

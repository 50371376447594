import React, { ReactElement, SyntheticEvent, useCallback, useMemo } from 'react';
import {
  Button,
  Datagrid,
  DateTimeInput,
  FunctionField,
  List,
  RaRecord,
  ReferenceField,
  SelectInput,
  TextField,
  TextInput,
} from 'react-admin';
import { useTranslation } from 'react-i18next';
import { ResourceName } from 'dd-cms-client/app/resources';
import { getTenant } from 'dd-cms-client/auth/utils/tenant';
import { ActiveLanguages } from 'dd-cms-client/common/components/ActiveLanguages';
import { DuplicateButton } from 'dd-cms-client/common/components/DuplicateButton';
import { ListActions } from 'dd-cms-client/common/components/ListActions';
import { Select } from 'dd-cms-client/common/components/SchemaFields/components/Select';
import { Status, useList } from 'dd-cms-client/common/hooks/useList';
import { DEFAULT_ITEMS_PER_PAGE } from 'dd-cms-client/common/utils/list';
import { getConfig } from 'dd-cms-client/config/utils/config';

const DealList = (): ReactElement => {
  const { t } = useTranslation();
  const { getActiveRowBackground, getStatusClassNames } = useList(ResourceName.DEAL);
  const tenant = getTenant();

  const text = useMemo(
    () => ({
      actions: t('Actions'),
      availableStock: t('Available stock'),
      category: t('Category'),
      endDate: t('End date'),
      image: t('Image'),
      navisionId: t('Navision ID'),
      preview: t('Preview'),
      searchByNavisionId: t('Search by navision ID'),
      searchBySKU: t('Search by SKU'),
      searchBySubtitle: t('Search by subtitle'),
      searchByTitle: t('Search by title'),
      soldOut: t('Sold out'),
      startDate: t('Start date'),
      status: t('Status'),
      title: t('Title'),
    }),
    [t],
  );

  const statusOptions = [
    {
      id: Status.PUBLISHED,
      name: Status.PUBLISHED,
    }, {
      id: Status.UNPUBLISHED,
      name: Status.UNPUBLISHED,
    },
  ];

  const filters = [
    <TextInput label={text.searchByTitle} key="searchByTitle" source="fields.title" alwaysOn resettable />,
    <TextInput label={text.searchBySubtitle} key="searchBySubtitle" source="fields.subtitle" alwaysOn resettable />,
    <TextInput label={text.searchByNavisionId} key="searchByNavisionId" source="fields.navisionId" alwaysOn resettable />,
    <TextInput label={text.searchBySKU} key="searchBySKU" source="fields.sku" alwaysOn resettable />,
    <SelectInput label={text.status} key="status" source="fields.status" choices={statusOptions} alwaysOn />,
    <DateTimeInput label={text.startDate} key="startDate" source="fields.startDate" alwaysOn />,
    <DateTimeInput label={text.endDate} key="endDate" source="fields.endDate" alwaysOn />,
    <Select
      alwaysOn
      className="List-Select"
      options={{ resource: ResourceName.CATEGORY, searchBy: 'fields.name' }}
      key="categories"
      commonInputProps={{
        key: 'selected-category',
        label: 'Category',
        source: 'fields.categoryId',
        validate: [],
      }}
    />,
  ];

  const handlePreview = useCallback(
    (record: RaRecord) => (e: SyntheticEvent) => {
      e.stopPropagation();

      const slug = `${getConfig(`url.app.${tenant}`)}/${getConfig('mainLanguage')}/deal/${record?.fields?.slug}`;
      window.open(slug, '_blank');
    },
    [tenant],
  );

  return (
    <List
      actions={<ListActions />}
      className="List"
      filters={filters}
      perPage={DEFAULT_ITEMS_PER_PAGE}
      sort={{ field: 'startDate', order: 'DESC' }}
    >
      <Datagrid
        bulkActionButtons={false}
        rowClick="edit"
        rowStyle={
          (record: RaRecord) => getActiveRowBackground(
            record?.fields?.startDate,
            record?.fields?.endDate,
            record?.fields?.status === Status.PUBLISHED,
          )
        }
      >
        <TextField
          source="fields.navisionId"
          label={text.navisionId}
          sortBy="navisionId"
        />
        <FunctionField
          label={text.image}
          render={(record: RaRecord) => {
            if (record?.fields?.mainImage) {
              return (
                <img
                  alt={text.image}
                  className="List-Image"
                  src={`${getConfig('url.cdn')}/${record?.fields?.mainImage}`}
                />
              );
            }

            return null;
          }}
        />
        <FunctionField
          label={text.title}
          render={(record: RaRecord) => (
            <>
              <div className="List-Title">
                {record?.fields?.title}
              </div>
              <ActiveLanguages languages={record?.activeLanguages}/>
              <ReferenceField
                className="List-CellContent List-CellContent--MarginTop"
                label="Theme"
                reference={ResourceName.THEME}
                source="fields.themeId"
              >
                <TextField source="fields.title" />
              </ReferenceField>
            </>
          )}
        />
        <TextField
          className="List-Chip List-Chip--Category"
          source="fields.category"
          label={text.category}
          sortBy="category"
        />
        <FunctionField
          label={text.status}
          render={(record: RaRecord) => (
            <span className={getStatusClassNames(record?.fields?.status === Status.PUBLISHED)}>
              {record?.fields?.status}
            </span>
          )}
        />
        <TextField
          source="fields.startDate"
          label={text.startDate}
          sortBy="startDate"
        />
        <TextField
          source="fields.endDate"
          label={text.endDate}
          sortBy="endDate"
        />
        <TextField
          source="fields.soldOutOn"
          label={text.soldOut}
          sortBy="soldOutOn"
        />
        <FunctionField
          label={text.availableStock}
          render={(record: RaRecord) => (
            record?.fields?.availableStock
              ? `${record.fields.availableStock}%`
              : null
          )}
          sortBy="availableStock"
        />
        <FunctionField
          label={text.actions}
          render={(record: RaRecord) => (
            <>
              <DuplicateButton
                record={record}
                resourceName={ResourceName.DEAL}
              />

              <Button
                label={text.preview}
                onClick={handlePreview(record)}
                size="small"
              />
            </>
          )}
        />
      </Datagrid>
    </List>
  );
};

export {
  DealList,
};

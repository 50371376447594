import React, { ReactElement, useCallback } from 'react';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DroppableProvided,
  DropResult,
} from 'react-beautiful-dnd';
import { SwapVert } from '@mui/icons-material';
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { StrictModeDroppable } from 'dd-cms-client/common/components/StrictModeDroppable';
import { VariantsGroup } from 'dd-cms-client/deal/VariantTab';
import { getVariantGroupName } from 'dd-cms-client/deal/VariantTab/utils/getVariantGroupName';
import { Component, Props } from './';
import './GroupTable.scss';

const GroupTable: Component = ({
  activeLanguages,
  changeOrder,
  variants,
}: Props): ReactElement => {
  const handleDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) {
        return;
      }
      const newVariants = [...variants];
      const [removed] = newVariants.splice(result.source.index, 1);
      newVariants.splice(result.destination.index, 0, removed);

      changeOrder(newVariants);
    },
    [changeOrder, variants],
  );

  return (
    <TableContainer>
      <Table className="GroupTable">
        <DragDropContext onDragEnd={handleDragEnd}>
          <StrictModeDroppable
            droppableId="droppableId"
          >
            {(provided: DroppableProvided) => (
              <TableBody
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {variants.map(
                  (variantsGroup: VariantsGroup, index) => (
                    <Draggable
                      key={variantsGroup.id}
                      draggableId={variantsGroup.id}
                      index={index}
                    >
                      {(provided: DraggableProvided) => (
                        <TableRow
                          className="VariantList-GroupNameRow"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <TableCell className="GroupTable-IconCell">
                            <SwapVert />
                          </TableCell>
                          <TableCell
                            className="GroupTable-GroupNameCell"
                            dangerouslySetInnerHTML={
                              { __html: getVariantGroupName(variantsGroup.id, variants, activeLanguages) }
                            }
                          />
                        </TableRow>
                      )}
                    </Draggable>
                  ),
                )}
                {provided.placeholder}
              </TableBody>
            )}
          </StrictModeDroppable>
        </DragDropContext>
      </Table>
    </TableContainer>
  );
};

export {
  GroupTable,
};

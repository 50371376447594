import React, { ReactElement } from 'react';
import { Tab, Tabs as MaterialUiTabs } from '@mui/material';
import { LangChip } from 'dd-cms-client/common/components/LangChip';
import { Component, Props } from './types';

const Tabs: Component = ({
  activeLanguage,
  onChangeTab,
  languages,
  isTranslatableFrozen = false,
}: Props): ReactElement => {
  return (
      <MaterialUiTabs
        value={activeLanguage}
        onChange={onChangeTab}
      >
        {languages.map(
          language => (
            <Tab
              disabled={isTranslatableFrozen}
              icon={
                <LangChip
                  country={language}
                  className="LangChip--Tab"
                />
              }
              value={language}
              key={language}
            />
          ),
        )}
      </MaterialUiTabs>
  );
};


export {
  Tabs,
};

import React, { memo, ReactElement, useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { capitalCase } from 'change-case';
import get from 'lodash.get';
import isEqual from 'lodash.isequal';
import { ColorInput } from 'dd-cms-client/common/components/Form/ColorInput';
import { processValue } from '../../utils/processValue';
import { createColorValidationRules } from '../../validationRules';
import { Component, Props } from './';

const SOURCE_PREFIX = 'fields.manifest.';

// eslint-disable-next-line react/display-name
const Field: Component = memo(
  ({ config, name }: Props): ReactElement => {
    const { t } = useTranslation();
    const { setValue, watch, formState: { isDirty, dirtyFields } } = useFormContext();
    const dependentField = config && watch(`${SOURCE_PREFIX}${config.defaultValue}`);
    const colorValidationRules = createColorValidationRules(t as any);

    const handleDependentFieldChange = useCallback(
      (value: string) => {
        if (isDirty && config && get(dirtyFields, `${SOURCE_PREFIX}${config.defaultValue}`)) {
          setValue(
            name,
            processValue(value, config.process),
          );
        }
      },
      [isDirty, dirtyFields, config, setValue, name],
    );

    useEffect(
      () => handleDependentFieldChange(dependentField),
      [dependentField, handleDependentFieldChange],
    );

    return (
      <ColorInput
        isRequired
        label={capitalCase(name.replace(SOURCE_PREFIX, ''))}
        source={name}
        validate={colorValidationRules}
      />
    );
  },
  (prevProps, nextProps) => isEqual(prevProps, nextProps),
);

export {
  Field,
};

import React, { ReactElement } from 'react';
import { TextInput, UrlField } from 'react-admin';
import { useTranslation } from 'react-i18next';
import { Component, Props } from './types';
import './Url.scss';

const Url: Component = ({
  commonInputProps,
  isDisabled,
}: Props): ReactElement => {
  const { t } = useTranslation();

  if (isDisabled) {
    return (
      <div className="UrlField">
        <span className="UrlField-Label">
          {t(commonInputProps.label)}:
        </span>
        <UrlField
          {...commonInputProps}
          target="_blank"
        />
      </div>
    );
  }

  return (
    <TextInput
      {...commonInputProps}
      InputProps={{ disabled: isDisabled }}
      type="url"
    />
  );
};

export {
  Url,
};

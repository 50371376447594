import React, { ReactElement, useCallback, useMemo } from 'react';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DroppableProvided,
  DropResult,
} from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { SwapVert } from '@mui/icons-material';
import {
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { StrictModeDroppable } from 'dd-cms-client/common/components/StrictModeDroppable';
import { Component, Props } from './';
import './RecommendationList.scss';

const RecommendationList: Component = ({
  isLoadingSkuItem,
  items,
  onDeleteButtonClick,
  onDragEnd,
}: Props): ReactElement | null => {
  const { t } = useTranslation();

  const text = useMemo(
    () => ({
      action: t('Action'),
      cancel: t('Cancel'),
      delete: t('Delete'),
      thumbnail: t('Thumbnail'),
      title: t('Title'),
    }),
    [t],
  );

  const handleDragEnd = useCallback(
    (result: DropResult) => {
      if (result.destination) {
        onDragEnd(result);
      }
    },
    [onDragEnd],
  );

  const handleDeleteButtonClick = useCallback(
    (index: number) => () => {
      onDeleteButtonClick(index);
    },
    [onDeleteButtonClick],
  );

  if (items.length === 0) {
    return null;
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>{text.thumbnail}</TableCell>
            <TableCell>{text.title}</TableCell>
            <TableCell>SKU</TableCell>
            <TableCell>{text.action}</TableCell>
          </TableRow>
        </TableHead>
        <DragDropContext onDragEnd={handleDragEnd}>
          <StrictModeDroppable droppableId="droppable">
            {(provided: DroppableProvided) => (
              <TableBody
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {isLoadingSkuItem && (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}

                {!isLoadingSkuItem && items.map(
                  (item, index) => (
                    <Draggable key={item.sku} draggableId={`${item.sku}`} index={index}>
                      {(provided: DraggableProvided) => (
                        <TableRow
                          key={item.sku}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <TableCell className="RecommendationList-IconCell">
                            <SwapVert />
                          </TableCell>

                          <TableCell className="RecommendationList-ImgCell">
                            <img
                              alt="Recommendation"
                              className="RecommendationList-Image"
                              src={item.thumbnail}
                            />
                          </TableCell>

                          <TableCell className="RecommendationList-TitleCell">
                            {item.title}
                          </TableCell>

                          <TableCell className="RecommendationList-SkuCell">
                            {item.sku}
                          </TableCell>

                          <TableCell className="RecommendationList-ActionCell">
                            <Button
                              color="error"
                              size="small"
                              onClick={handleDeleteButtonClick(index)}
                            >
                              {text.delete}
                            </Button>
                          </TableCell>
                        </TableRow>
                      )}
                    </Draggable>
                  ),
                )}
                {provided.placeholder}
              </TableBody>
            )}
          </StrictModeDroppable>
        </DragDropContext>
      </Table>
    </TableContainer>
  );
};

export {
  RecommendationList,
};

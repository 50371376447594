import { NotFound } from 'react-admin';
import { ResourceName } from 'dd-cms-client/app/resources';
import { hasUserAndTenantPermission } from 'dd-cms-client/auth/utils/permission';
import { Tenant } from 'dd-cms-client/auth/utils/tenant';
import { UserRole } from 'dd-cms-client/auth/utils/userRole';
import { UserCreate } from './UserCreate';
import { UserEdit } from './UserEdit';
import { UserList } from './UserList';

const user = (userRole: UserRole, tenant: Tenant): Record<string, any> => {
  const hasPermissions = hasUserAndTenantPermission(ResourceName.USER, userRole, tenant);

  return {
    create: hasPermissions ? UserCreate : NotFound,
    edit: hasPermissions ? UserEdit : NotFound,
    list: hasPermissions ? UserList : NotFound,
  };
};

export {
  user,
};

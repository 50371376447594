import React, { ChangeEvent, ReactElement, useCallback, useMemo, useState } from 'react';
import { Button } from 'react-admin';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';
import { TextField } from '@mui/material';
import { disableSubmitForEnterKey } from 'dd-cms-client/common/utils/form';
import { Component, Props } from './';
import './SkuInput.scss';

const SkuInput: Component = ({
  addSku,
  isDisabled,
  isLoadingSkuItem,
}: Props): ReactElement => {
  const { t } = useTranslation();
  const [sku, setSku] = useState('');

  const text = useMemo(
    () => ({
      add: t('Add'),
      addSku: t('Add an SKU'),
    }),
    [t],
  );

  const handleSkuChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSku(event.target.value);
  };

  const handleAddSkuClick = useCallback(
    () => {
      addSku(sku);
      setSku('');
    },
    [addSku, sku],
  );

  return (
    <div className="SkuInput">
      <div className="SkuInput-Form">
        <TextField
          disabled={isDisabled}
          label={text.addSku}
          onChange={handleSkuChange}
          value={sku}
          onKeyPress={disableSubmitForEnterKey}
        />

        <Button
          disabled={isDisabled}
          label={text.add}
          onClick={handleAddSkuClick}
          variant="outlined"
        />
      </div>

      {isLoadingSkuItem && (
        <CircularProgress className="SkuInput-Loader"/>
      )}
    </div>
  );
};

export {
  SkuInput,
};

import React, { ReactElement, useCallback, useMemo } from 'react';
import {
  EditControllerResult,
  SaveButton,
  useCreate,
  useEditController,
  useNotify,
  useRefresh,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ResourceName } from 'dd-cms-client/app/resources';
import { CommentRecord } from 'dd-cms-client/comment/types';
import { showErrorNotification } from 'dd-cms-client/common/utils/error';
import { Component, Props } from './';

const SaveReply: Component = ({
  replyCommentId,
  setReply,
}: Props): ReactElement => {
  const { t } = useTranslation();
  const [create] = useCreate();
  const refresh = useRefresh();
  const notify = useNotify();
  const { reset } = useFormContext();
  const editControllerProps: EditControllerResult<CommentRecord> = useEditController();

  const text = useMemo(
    () => ({
      publish: t('Publish'),
    }),
    [t],
  );

  const handleSubmit = useCallback(
    async (values: any) => {
      try {
        await create(
          ResourceName.COMMENT,
          {
            data:
              {
                content: values.content,
                id: `reply/${editControllerProps.record?.id}`,
                replyCommentId: replyCommentId || editControllerProps.record?.id,
              },
          },
          { returnPromise: true },
        );
        notify('ra.notification.created', { messageArgs: { smart_count: 1 }, type: 'info' });
        setReply(null);
        reset();
      } catch (error: any) {
        showErrorNotification(error, notify);
      }

      refresh();
    },
    [refresh, create, editControllerProps.record?.id, replyCommentId, notify, setReply, reset],
  );

  return (
    <SaveButton
      label={text.publish}
      type="button"
      mutationOptions={{
        onSuccess: (values: any) => handleSubmit(values),
      }}
    />
  );
};

export {
  SaveReply,
};

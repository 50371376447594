import React, { ReactElement } from 'react';
import { LangChip } from 'dd-cms-client/common/components/LangChip';
import { Component, Props } from './types';
import './ActiveLanguages.scss';

const ActiveLanguages: Component = (
  {
    languages,
  }: Props,
): ReactElement => {
  return (
    <div className="ActiveLanguages">
      {
        Array.isArray(languages) && languages.map(
          language => (
            <LangChip
              country={language}
              key={language}
            />
          ),
        )
      }
    </div>
  );
};

export {
  ActiveLanguages,
};

import React, { ReactElement, useCallback, useMemo } from 'react';
import { Create, SaveButton, SimpleForm, useCreate, useNotify, useRefresh } from 'react-admin';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { ResourceName } from 'dd-cms-client/app/resources';
import { DataLoader } from 'dd-cms-client/common/components/DataLoader';
import { SchemaFields } from 'dd-cms-client/common/components/SchemaFields';
import { useForm } from 'dd-cms-client/common/hooks/useForm';
import { showErrorNotification } from 'dd-cms-client/common/utils/error';
import { SchemaType } from 'dd-cms-client/common/utils/schema';
import { Component, Props } from './';

const CreateGroup: Component = ({
  dealId,
  onDialogClose,
}: Props): ReactElement => {
  const { t } = useTranslation();
  const [create] = useCreate();
  const refresh = useRefresh();
  const notify = useNotify();

  const text = useMemo(
    () => ({
      cancel: t('Cancel'),
      create: t('Create'),
    }),
    [t],
  );

  const {
    data,
    dataLoaderProps,
  } = useForm(
    ResourceName.DEAL_VARIANT_GROUP,
    SchemaType.CREATE,
  );

  const handleSubmit = useCallback(
    async (values: any) => {
      try {
        await create(
          ResourceName.DEAL_VARIANT_GROUP,
          { data: { ...values.fields, id: dealId } },
          { returnPromise: true },
        );
        notify('ra.notification.created', { messageArgs: { smart_count: 1 }, type: 'info' });
      } catch (error: any) {
        showErrorNotification(error, notify);
      }

      onDialogClose();
      refresh();
    },
    [onDialogClose, refresh, create, dealId, notify],
  );

  return (
    <DataLoader {...dataLoaderProps}>
      <Create>
        <SimpleForm
          mode="onBlur"
          toolbar={false}
          onSubmit={handleSubmit}
        >
          <SchemaFields
            formType={SchemaFields.FormType.INSIDE_MODAL}
            resource={ResourceName.DEAL_VARIANT_GROUP}
            schema={data}
          />
          <div className="Dialog-ContentActions">
            <SaveButton />

            <Button
              color="primary"
              onClick={onDialogClose}
              variant="outlined"
            >
              {text.cancel}
            </Button>
          </div>
        </SimpleForm>
      </Create>
    </DataLoader>
  );
};

export {
  CreateGroup,
};

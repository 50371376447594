import { createGlobalState } from 'react-hooks-global-state';
import { MAIN_LANGUAGE } from 'dd-cms-client/i18n/utils/language';
import { LanguageCode } from 'dd-cms-client/i18n/utils/languageCode';

enum GlobalStateKey {
  IS_CONFIRMATION_WINDOW_OPEN = 'isConfirmationWindowOpen',
  IS_FORM_DIRTY = 'isFormDirty',
  IS_FORM_FIELD_UPLOADING = 'isFormFieldUploading',
  OPEN_FORM_VALUES = 'openFormValues',
  OPENED_LANGUAGE_TAB = 'openedLanguageTab',
  TOTAL_PENDING_COMMENTS = 'totalPendingComments',
}

const { useGlobalState, getGlobalState } = createGlobalState<{
  [GlobalStateKey.IS_CONFIRMATION_WINDOW_OPEN]: boolean;
  [GlobalStateKey.IS_FORM_DIRTY]: boolean;
  [GlobalStateKey.IS_FORM_FIELD_UPLOADING]: boolean;
  [GlobalStateKey.OPEN_FORM_VALUES]: Record<string, any>;
  [GlobalStateKey.OPENED_LANGUAGE_TAB]: LanguageCode;
  [GlobalStateKey.TOTAL_PENDING_COMMENTS]?: number;
}>(
  {
    [GlobalStateKey.IS_CONFIRMATION_WINDOW_OPEN]: false,
    [GlobalStateKey.IS_FORM_DIRTY]: false,
    [GlobalStateKey.IS_FORM_FIELD_UPLOADING]: false,
    [GlobalStateKey.OPEN_FORM_VALUES]: {},
    [GlobalStateKey.OPENED_LANGUAGE_TAB]: MAIN_LANGUAGE,
    [GlobalStateKey.TOTAL_PENDING_COMMENTS]: undefined,
  },
);

export {
  getGlobalState,
  GlobalStateKey,
  useGlobalState,
};

import { useMediaQuery } from '@mui/material';
import { DeviceDetection } from 'dd-cms-client/common/hooks/useDevice/types';
import { BREAKPOINTS } from 'dd-cms-client/common/utils/breakpoints';

const useDevice = (): DeviceDetection => {
  const isMobile = useMediaQuery(`only screen and (max-width: ${BREAKPOINTS.md - 1}px)`);
  const isTablet = useMediaQuery(`only screen and (min-width: ${BREAKPOINTS.md}px) and (max-width: ${BREAKPOINTS.lg}px)`);
  const isDesktop = useMediaQuery(`only screen and (min-width: ${BREAKPOINTS.lg}px)`);
  const isLDesktop = useMediaQuery(`only screen and (min-width: ${BREAKPOINTS.lg}px) and (max-width: ${BREAKPOINTS.xl}px)`);
  const isXLDesktop = useMediaQuery(`only screen and (min-width: ${BREAKPOINTS.xl}px) and (max-width: ${BREAKPOINTS.xxl}px)`);
  const isXXLDesktop = useMediaQuery(`only screen and (min-width: ${BREAKPOINTS.xxl}px)`);

  return {
    isDesktop,
    isLDesktop,
    isMobile,
    isTablet,
    isXLDesktop,
    isXXLDesktop,
  };
};

export {
  useDevice,
};

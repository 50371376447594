import { ResourceName } from 'dd-cms-client/app/resources';
import { ALL_PERMISSIONS, SpecificFunctionality, Tenant, TENANT_PERMISSIONS, TENANT_SPECIFIC_FUNCTIONALITY } from 'dd-cms-client/auth/utils/tenant';
import { USER_PERMISSIONS, UserRole } from 'dd-cms-client/auth/utils/userRole';

const hasUserAndTenantPermission = (
  resourceName: ResourceName,
  userRole: UserRole,
  tenant: Tenant,
) : boolean => (
  (
    TENANT_PERMISSIONS[tenant].includes(ALL_PERMISSIONS)
    || TENANT_PERMISSIONS[tenant].includes(resourceName)
  )
  && USER_PERMISSIONS[resourceName](userRole)
);

const hasNewsletter = (tenant: Tenant): boolean => (
  TENANT_SPECIFIC_FUNCTIONALITY[tenant].includes(SpecificFunctionality.NEWSLETTER)
);

export {
  hasNewsletter,
  hasUserAndTenantPermission,
};

import React, { ReactElement, useEffect, useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { Component, Props } from './types';

// support for react-beautiful-dnd in React 18: https://github.com/atlassian/react-beautiful-dnd/issues/2399
const StrictModeDroppable: Component = ({
  children,
  ...props
}: Props): ReactElement | null => {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  if (!enabled) {
    return null;
  }

  return (
    // @ts-ignore
    <Droppable {...props}>
      {children}
    </Droppable>
  );
};

export {
  StrictModeDroppable,
};

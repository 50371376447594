import queryString, { StringifyOptions } from 'query-string';
import { DataProvider, fetchUtils } from 'ra-core';
import { getTenant } from 'dd-cms-client/auth/utils/tenant';
import { tokenManager } from 'dd-cms-client/auth/utils/tokenManager';
import { getGlobalState, GlobalStateKey } from 'dd-cms-client/common/globalState';
import { replaceEmptyStringsWithNull } from 'dd-cms-client/common/utils/object';
import { Header } from 'dd-cms-client/common/utils/request';
import { getConfig } from 'dd-cms-client/config/utils/config';
import { MAIN_LANGUAGE } from 'dd-cms-client/i18n/utils/language';
import { ResourceName } from './resources';

const stringifyOptions: StringifyOptions = { arrayFormat: 'bracket' };

const API_ENDPOINTS: Record<string, Array<string>> = {
  [ResourceName.APP]: [getConfig('url.api.deal')],
  [ResourceName.CATEGORY]: [getConfig('url.api.deal')],
  [ResourceName.ALL_CATEGORIES]: [getConfig('url.api.deal')],
  [ResourceName.ALL_DEALS]: [getConfig('url.api.deal')],
  [ResourceName.BAN]: [getConfig('url.api.comment')],
  [ResourceName.COMMENT]: [getConfig('url.api.comment')],
  [ResourceName.DEAL]: [getConfig('url.api.deal')],
  [ResourceName.DEAL_ACCESSORY_PRODUCT]: [getConfig('url.api.deal')],
  [ResourceName.DEAL_APP]: [getConfig('url.api.deal')],
  [ResourceName.DEAL_DESCRIPTION]: [getConfig('url.api.deal')],
  [ResourceName.DEAL_DELIVERY]: [getConfig('url.api.deal')],
  [ResourceName.DEAL_DETAIL]: [getConfig('url.api.deal')],
  [ResourceName.DEAL_SPECIFICATION]: [getConfig('url.api.deal')],
  [ResourceName.DEAL_LINKS]: [getConfig('url.api.deal')],
  [ResourceName.DEAL_MEDIA]: [getConfig('url.api.deal')],
  [ResourceName.DEAL_RECOMMENDED_PRODUCT]: [getConfig('url.api.deal')],
  [ResourceName.DEAL_STICKER]: [getConfig('url.api.deal')],
  [ResourceName.DEAL_TAG]: [getConfig('url.api.deal')],
  [ResourceName.DEAL_VARIANT]: [getConfig('url.api.deal')],
  [ResourceName.DEAL_VARIANT_GROUP]: [getConfig('url.api.deal')],
  [ResourceName.NEWS]: [getConfig('url.api.static')],
  [ResourceName.THEME]: [getConfig('url.api.static')],
  [ResourceName.USER]: [getConfig('url.api.identity')],
  [ResourceName.USER_PROFILE]: [getConfig('url.api.identity')],
  [ResourceName.PROMOTION]: [getConfig('url.api.static')],
  [ResourceName.STICKER]: [getConfig('url.api.deal')],
  [ResourceName.STATIC_PAGE]: [getConfig('url.api.static')],
};

const fetchJson = async (url: string, options: any = {}): Promise<any> => {
  if (!options.headers) {
    options.headers = new Headers({
      [Header.ACCEPT]: 'application/json',
    });
  }

  const authorizationHeader = await tokenManager.getAuthorizationHeader();
  options.headers.set(Header.LANGUAGE, options.lang || MAIN_LANGUAGE);
  options.headers.set(Header.TENANT, options.tenant || getTenant());
  options.headers.set(Header.AUTHORIZATION, authorizationHeader);

  return fetchUtils.fetchJson(url, options);
};

// made on the basis of ra-data-json-server
const createDataProvider = (httpClient = fetchJson): DataProvider => ({
  create: async (resource, params): Promise<any> => (
    httpClient(`${API_ENDPOINTS[resource]}/${resource}${params.data.id ? `/${params.data.id}` : ''}`, {
      body: JSON.stringify(replaceEmptyStringsWithNull(params.data)),
      lang: getGlobalState(GlobalStateKey.OPENED_LANGUAGE_TAB),
      method: 'POST',
      tenant: params.meta?.tenant,
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    }))
  ),

  delete: async (resource, params) => (
    httpClient(`${API_ENDPOINTS[resource]}/${resource}/${params.id}`, {
      method: 'DELETE',
    }).then(({ json }) => ({ data: json }))
  ),

  // json-server doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
  deleteMany: async (resource, params) => (
    Promise.all(
      params.ids.map(id => httpClient(`${API_ENDPOINTS[resource]}/${resource}/${id}`, {
        method: 'DELETE',
      })),
    ).then(responses => ({ data: responses.map(({ json }) => json?.id) }))
  ),

  getList: async (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      ...fetchUtils.flattenObject(params.filter),
      _end: page * perPage,
      _order: order,
      _sort: field,
      _start: (page - 1) * perPage,
    };
    const url = `${API_ENDPOINTS[resource]}/${resource}?${queryString.stringify(query, stringifyOptions)}`;

    return httpClient(url).then(({ headers, json }) => {
      if (!headers.has('x-total-count')) {
        throw new Error(
          'The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?',
        );
      }
      return {
        data: json,
        total: parseInt(
          // @ts-ignore
          headers.get('x-total-count').split('/').pop(),
          10,
        ),
      };
    });
  },

  getMany: async (resource, params) => {
    const query = {
      id: params.ids,
    };
    const url = `${API_ENDPOINTS[resource]}/${resource}?${queryString.stringify(query, stringifyOptions)}`;
    return httpClient(url).then(({ json }) => ({ data: json }));
  },

  getManyReference: async (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      ...fetchUtils.flattenObject(params.filter),
      [params.target]: params.id,
      _end: page * perPage,
      _order: order,
      _sort: field,
      _start: (page - 1) * perPage,
    };
    const url = `${API_ENDPOINTS[resource]}/${resource}?${queryString.stringify(query, stringifyOptions)}`;

    return httpClient(url)
      .then(({ headers, json }) => {
        if (!headers.has('x-total-count')) {
          throw new Error(
            'The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?',
          );
        }
        return {
          data: json,
          total: parseInt(
            // @ts-ignore
            headers.get('x-total-count').split('/').pop(),
            10,
          ),
        };
      });
  },

  getOne: async (resource, params) => {
    const url = `${API_ENDPOINTS[resource]}/${resource}`;
    return (
      httpClient(resource === ResourceName.USER_PROFILE ? url : `${url}/${params.id}`, {
        lang: getGlobalState(GlobalStateKey.OPENED_LANGUAGE_TAB),
      }).then(({ json }) => ({
        data: json,
      }))
    );
  },

  update: async (resource, params) => {
    const url = `${API_ENDPOINTS[resource]}/${resource}`;
    return (
      httpClient(resource === ResourceName.USER_PROFILE ? url : `${url}/${params.id}`, {
        body: JSON.stringify(replaceEmptyStringsWithNull(params.data)),
        lang: getGlobalState(GlobalStateKey.OPENED_LANGUAGE_TAB),
        method: 'PUT',
      }).then(({ json }) => ({ data: json }))
    );
  },

  // json-server doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
  updateMany: async (resource, params) => (
    Promise.all(
      params.ids.map(id => httpClient(`${API_ENDPOINTS[resource]}/${resource}/${id}`, {
        body: JSON.stringify(replaceEmptyStringsWithNull(params.data)),
        method: 'PUT',
      })),
    ).then(responses => ({ data: responses.map(({ json }) => json.id) }))
  ),
});

export {
  API_ENDPOINTS,
  createDataProvider,
};

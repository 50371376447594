import React, { ReactElement } from 'react';
import { AppBar, Tab, Tabs as MaterialUiTabs } from '@mui/material';
import { Component, Props } from './types';
import './Tabs.scss';

const Tabs: Component = ({
  activeTab,
  onChange,
  tabLabels,
}: Props): ReactElement => (
  <AppBar
    color="default"
    position="static"
  >
    <MaterialUiTabs
      value={activeTab}
      onChange={onChange}
      className="Tabs"
    >
      {tabLabels.map(
        label => (
          <Tab
            label={label}
            key={label}
            value={label}
          />
        ),
      )}
    </MaterialUiTabs>
  </AppBar>
);

export {
  Tabs,
};
